import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { Button, DatePicker, Form, Input, InputNumber, Spin, notification } from 'antd';
import { _date_id, _edit_date, _edit_date_options } from "common/axios_services";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CategoryNav from 'examples/Navbars/CategoryNav';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

const Date_edit = () => {
    const [selectedDate, setSelectedDate] = useState([])
    const [editing, setEditing] = useState(false)

    const navigate = useNavigate()
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [dateForm] = Form.useForm();
    const [optionForm] = Form.useForm();
    const jwt = localStorage.konnect_admin_token;
    const params = useParams()

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const getDate = async () => {
        try {
            const selDate = await _date_id({ id: params.id })
            setSelectedDate(selDate.data.data)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        if (!jwt) {
            openNotificationWithIcon('error', 'Unauthorized')
            return navigate('/')
        }

        getDate()
    }, [params.id, jwt, navigate])


    const update_date_options = async (values) => {
        setEditing(true)
        try {
            const data = {
                date: values.date,
                reason: "",
                bnpl_limit: `${values.bnpl_limit}`,
                pay_now_limit: `${values.pay_now_limit}`,
                pay_on_delivery_limit: `${values.pay_on_delivery_limit}`,
            }
            const editDates = await _edit_date_options({ id: params.id, data })
            setEditing(false)
            openNotificationWithIcon('success', editDates.data.message)
        } catch (err) {
            setEditing(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const update_date = async (values) => {
        setEditing(true)
        try {
            const data = {
                date: values.date,
                reason: values.reason,
                bnpl_limit: `${values.bnpl_limit}`,
                pay_now_limit: `${values.pay_now_limit}`,
                pay_on_delivery_limit: `${values.pay_on_delivery_limit}`,
            }
            const editDates = await _edit_date({ id: params.id, data })
            setEditing(false)
            openNotificationWithIcon('success', editDates.data.message)
        } catch (err) {
            setEditing(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    return (
        <DashboardLayout>
            <CategoryNav title="Edit Delivery Date" breadroute="delivery_date" />
            <MDBox pt={6} pb={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Delivery Date
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3} px={2}>
                                {(selectedDate.date) ?
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Delivery Date
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {selectedDate && (
                                                    <Form form={dateForm} onFinish={update_date} initialValues={{
                                                        date: selectedDate?.date.split('T')[0],
                                                        bnpl_limit: selectedDate.bnpl_limit,
                                                        pay_now_limit: selectedDate.pay_now_limit,
                                                        pay_on_delivery_limit: selectedDate.pay_on_delivery_limit,
                                                    }} layout="vertical">
                                                        <Form.Item name="bnpl_limit" hidden initialValue={selectedDate.bnpl_limit} />
                                                        <Form.Item name="pay_now_limit" hidden initialValue={selectedDate.pay_now_limit} />
                                                        <Form.Item name="pay_on_delivery_limit" hidden initialValue={selectedDate.pay_on_delivery_limit} />
                                                        <Form.Item name="date" label="Date" >
                                                            <input id="date" style={{ width: "100%", padding: "0px 11px", height: "30px", borderRadius: "6px", border: "1px solid #000" }} type="date" defaultValue={selectedDate?.date.split('T')[0]} name="date" />
                                                        </Form.Item>
                                                        <Form.Item name="reason" label="Reason for the update" >
                                                            <Input.TextArea id="reason" rows={4} />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            {editing ?
                                                                <Button disabled loading type="primary">Submit</Button> :
                                                                <Button htmlType="submit" type="primary">Submit</Button>
                                                            }
                                                        </Form.Item>
                                                    </Form>
                                                )}
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Delivery Date
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <Spin indicator={antIcon} />
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Delivery Option
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3} px={2}>
                                {(selectedDate.date) ?
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Delivery Date
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {selectedDate && (
                                                    <Form form={optionForm} onFinish={update_date_options} initialValues={{
                                                        date: selectedDate?.date.split('T')[0],
                                                        bnpl_limit: selectedDate.bnpl_limit,
                                                        pay_now_limit: selectedDate.pay_now_limit,
                                                        pay_on_delivery_limit: selectedDate.pay_on_delivery_limit,
                                                    }} layout="vertical">
                                                        <Form.Item name="date" hidden initialValue={selectedDate?.date.split('T')[0]} />
                                                        <Form.Item name="bnpl_limit" label="BNPL Limit" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="BNPL limit" />
                                                        </Form.Item>
                                                        <Form.Item name="pay_now_limit" label="Pay now limit" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Pay now limit" />
                                                        </Form.Item>
                                                        <Form.Item name="pay_on_delivery_limit" label="Pay on delivery limit" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Pay on delivery limit" />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            {editing ?
                                                                <Button disabled loading type="primary">Submit</Button> :
                                                                <Button htmlType="submit" type="primary">Submit</Button>
                                                            }
                                                        </Form.Item>
                                                    </Form>
                                                )}
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Delivery Option
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <Spin indicator={antIcon} />
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default Date_edit