import { Button, Modal, notification } from "antd";
import { _attach_so, _get_attachable_so } from "common/axios_services";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setCount } from "splices/updateCountSplice";

const DetachSoComp = ({ info }) => {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [DettachSoModal, setDettachSoModal] = useState(false)
    const [DettachingSo, setDettachingSo] = useState(false)
    const updateCount = useSelector(state => state?.updateCount?.count)

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({ message: title, placement: 'bottomRight', description: message, });
    };


    const dettachSo = async () => {
        setDettachingSo(true)
        try {
            const attcah_so = await _attach_so({ ref_id: info?.id, user_id: params.id, status: false })
            setDettachingSo(false)
            setDettachSoModal(false)
            dispatch(setCount(updateCount + 1))
            openNotificationWithIcon('success', attcah_so?.data?.title, attcah_so?.data?.message)
        } catch (err) {
            setDettachingSo(false)
            if (err.response) {
                if (err?.response?.data?.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    return (
        <>
            <Button onClick={() => { setDettachSoModal(true) }}>Dettach SO</Button>
            <Modal centered={true} visible={DettachSoModal} onCancel={() => setDettachSoModal(false)} footer={null}>
                <h5>Dettach SO</h5>
                <p>Are you sure you want to remove <b>{info.fullname}</b> from your SO list.</p>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {DettachingSo ?
                        <Button type="primary" disabled loading>Detaching SO</Button> :
                        <Button type="primary" onClick={() => dettachSo()}>Detach So</Button>
                    }
                    {DettachingSo ?
                        <Button disabled>Cancel</Button> :
                        <Button onClick={() => setDettachSoModal(false)}>Cancel</Button>
                    }
                </div>
            </Modal>
        </>
    )
}

export default DetachSoComp