/* eslint-disable */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { notification, Radio, Spin, Table } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody } from "@coreui/react";
import { _get_general_settings, _get_profile } from "common/axios_services";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

function GeneralSettings() {
    const [services, setServices] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedValue, setSelectedValue] = useState('Shop');
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [settingsfetched, setSettingsFetched] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const jwt = localStorage.konnect_admin_token;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const shopColumns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Normal User',
            dataIndex: 'value',
        },
        {
            title: 'Food Vendor',
            dataIndex: 'app_value',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
        },
        {
            title: 'Account Type',
            dataIndex: 'account_type',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const sasoColumns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'T1 FV',
            dataIndex: 'value',
        },
        {
            title: 'T2 FV',
            dataIndex: 'app_value',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
        },
        {
            title: 'Account Type',
            dataIndex: 'account_type',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Value',
            dataIndex: 'value',
        },
        {
            title: 'App Value',
            dataIndex: 'app_value',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
        },
        {
            title: 'Account Type',
            dataIndex: 'account_type',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };
    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const getSettings = async () => {
        setFetchingData(true);
        try {
            const getSettings = await _get_general_settings()
            setSettingsFetched(getSettings?.data?.data);
            setSelectedValue('Shop')
            setFilteredData(getSettings?.data?.data.filter(item => item.service === "Shop"))
            const uniqueServices = [...new Set(getSettings?.data?.data.map(item => item.service))];
            setServices(uniqueServices);
            setFetchingData(false);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        getSettings()
    }, [])

    const onChange = e => {
        const { value } = e.target;
        setSelectedValue(value);
        if (!fetchingData) {
            if (value === 'all') {
                setFilteredData(settingsfetched);
            } else {
                const newData = settingsfetched.filter(item => item.service === value);
                setFilteredData(newData);
            }
        }
    };

    let data = filteredData.map((general_setting, index) => {
        return {
            sn: index + 1,
            title: general_setting?.title,
            value: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(general_setting?.value)} />,
            app_value: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(general_setting?.app_value)} />,
            unit: general_setting?.unit,
            account_type: general_setting?.account_type,
            actions: <div><Link to={`/general-settings/edit/${general_setting?.id}`}>Edit</Link></div>
        }
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">General Settings</MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <CCard>
                                            <div style={{ padding: "20px 20px 0px 0px", display: "flex" }}>
                                                <Radio.Group style={{ marginLeft: "auto" }} onChange={onChange} defaultValue="Shop">
                                                    {services.map(service => (
                                                        <Radio.Button key={service} value={service}>{service}</Radio.Button>
                                                    ))}
                                                </Radio.Group>
                                            </div>
                                            <CCardBody>
                                                {fetchingData ?
                                                    <div>
                                                        <Spin indicator={antIcon} />
                                                    </div> :
                                                    <>
                                                        <div className="table-responsive">
                                                            {selectedValue === "Shop" ?
                                                                <Table columns={shopColumns} dataSource={data} pagination={false} bordered footer={null} />
                                                                : selectedValue === "SOSA-SO-Referral" ?
                                                                    <Table columns={sasoColumns} dataSource={data} pagination={false} bordered footer={null} />
                                                                    : <Table columns={columns} dataSource={data} pagination={false} bordered footer={null} />
                                                            }
                                                            <div style={{ marginTop: "2%" }}></div>
                                                        </div>
                                                    </>
                                                }
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default GeneralSettings;
