/* eslint-disable */

import {CircularProgress} from "@mui/material";
import {notification} from "antd";
import {_get_profile} from "common/axios_services";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {fetchData, profileData} from "splices/profileSplice";
import InventoryDispute from "./Inventory/Dispute";
import InventoryPickup from "./Inventory/Pickup";
import InventoryRecieved from "./Inventory/Recieved";
import InventoryReport from "./Inventory/Report";
import InventoryReturned from "./Inventory/Returned";
import NoAccess from "./NotFound/NoAccess";
import AddAdmin from "./People/AddAdmin";
import AddDriver from "./People/AddDriver";
import AddMerchantUser from "./People/AddMerchantUser";
import ReferralDetails from "./People/ReferralDetails";
import Admin from "./People/admin";
import DriverDetails from "./People/driver-details";
import Drivers from "./People/drivers";
import Merchants from "./People/merchant";
import Referrals from "./People/referrals";
import SaDetails from "./People/sa-details";
import Sales from "./People/sales";
import SlDetails from "./People/sl-details";
import UserDetails from "./People/user-details";
import Users from "./People/users";
import Vehicles from "./People/vehicles";
import Vendors from "./People/vendors";
import KonnectReport from "./Reports/Konnect";
import LoyaltyReport from "./Reports/Loyalty";
import OrderReport from "./Reports/Order";
import BillsReport from "./Reports/Paybills";
import LoyaltyTransMonthly from "./Reports/Transactions/Loyalty";
import BillsTransMonthly from "./Reports/Transactions/Paybills";
import WalletTransMonthly from "./Reports/Transactions/Wallet";
import Wise9jaTransMonthly from "./Reports/Transactions/Wise9ja";
import WalletReport from "./Reports/Wallet";
import Wise9jaReport from "./Reports/Wise9ja";
import AddMerchant from "./Settings/AddMerchant";
import Date_edit from "./Settings/DateEdit";
import Date_settings from "./Settings/DeliveryDate";
import EditGeneralSettings from "./Settings/EditGeneralSettings";
import EditMerchant from "./Settings/EditMerchant";
import EditVanType from "./Settings/EditVanType";
import GeneralSettings from "./Settings/GeneralSettings";
import Merchant_settings from "./Settings/Merchant";
import Roles_settings from "./Settings/Roles";
import Rate_settings from "./Settings/Settings";
import VehicleSettings from "./Settings/Vehicle";
import Wise9ja_settings from "./Settings/Wise9ja";
import Wise9ja_edit from "./Settings/Wise9ja_edit";
import EditProducts from "./Shop/EditProducts";
import EditSubCategory from "./Shop/EditSubCategory";
import Products from "./Shop/Products";
import SubCategories from "./Shop/SubCategories";
import Categories from "./Shop/categories";
import EditCategory from "./Shop/edit-category";
import Account from "./Transactions/Accounts";
import EachOrder from "./Transactions/EachOrder";
import Order from "./Transactions/Orders";
import Payments from "./Transactions/Payments";
import UserPayments from "./Transactions/UserPayment";
import Wise9ja from "./Transactions/Wise9ja";
import Cms from "./Website/Cms";
import Cms_Edit from "./Website/EditCms";
import Dashboard from "./dashboard";
import Allocations from "./delivery/Allocations";
import Dispute from "./delivery/Dispute";
import DriverRequest from "./delivery/DriverRequest";
import Inbound from "./delivery/Inbound";
import Instore from "./delivery/Instore";
import Outbound from "./delivery/Outbound";
import ReturnItems from "./delivery/ReturnItems";
import Reviews from "./delivery/Reviews";
import Delivery_Fee from "./delivery/fee";
import Fee_edit from "./delivery/fee/Edit";
import CityInfo from "./locations/City";
import LocationInfo from "./locations/Location";
import StatesInfo from "./locations/State";
import Restaurant from "./Restaurant/Restaurant";
import TodaysSales from "./Restaurant/TodaysSales";
import SelectedRestaurantById from "./Restaurant/SelectedRestaurant";

const DashboardGuard = ({location}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [component, setComponent] = useState("")
    const jwt = localStorage.konnect_admin_token;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({message: '', placement: 'bottomRight', description: message, });
    };


    useEffect(() => {
        if (!jwt) {
            navigate('/')
            openNotificationWithIcon('error', 'Unauthorized')
            return setComponent(<div style={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress /></div>)
        }

        const guard = async () => {
            setComponent(<div style={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress /></div>)
            try {
                const user_profile = await _get_profile()
                if (user_profile.data.status == "success") {
                    dispatch(fetchData(true))
                    dispatch(profileData(user_profile.data.data))
                    if (location === "users") {
                        if (user_profile.data.data.role.role_access.includes("users")) {
                            setComponent(<Users />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "sales") {
                        if (user_profile.data.data.role.role_access.includes("sales")) {
                            setComponent(<Sales />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "restaurant") {
                        if (user_profile.data.data.role.role_access.includes("restaurant")) {
                            setComponent(<Restaurant />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "restaurant-details") {
                        if (user_profile.data.data.role.role_access.includes("restaurant-details")) {
                            setComponent(<SelectedRestaurantById />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "todays-sales") {
                        if (user_profile.data.data.role.role_access.includes("todays-sales")) {
                            setComponent(<TodaysSales />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "referral_details") {
                        if (user_profile.data.data.role.role_access.includes("referralDetails")) {
                            setComponent(<ReferralDetails />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "saDetails") {
                        if (user_profile.data.data.role.role_access.includes("saDetails")) {
                            setComponent(<SaDetails />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "slDetails") {
                        if (user_profile.data.data.role.role_access.includes("slDetails")) {
                            setComponent(<SlDetails />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "dashboard") {
                        if (user_profile.data.data.role.role_access.includes("dashboard")) {
                            setComponent(<Dashboard />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "van-types") {
                        if (user_profile.data.data.role.role_access.includes("van-type-settings")) {
                            setComponent(<VehicleSettings />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "drivers") {
                        if (user_profile.data.data.role.role_access.includes("driver")) {
                            setComponent(<Drivers />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "vendors") {
                        if (user_profile.data.data.role.role_access.includes("vendors")) {
                            setComponent(<Vendors />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "referrals") {
                        if (user_profile.data.data.role.role_access.includes("referrals")) {
                            setComponent(<Referrals />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    }
                    else if (location === "merchants") {
                        if (user_profile.data.data.role.role_access.includes("merchants")) {
                            setComponent(<Merchants />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "inbound") {
                        if (user_profile.data.data.role.role_access.includes("inbound")) {
                            setComponent(<Inbound />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "instore") {
                        if (user_profile.data.data.role.role_access.includes("instore")) {
                            setComponent(<Instore />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "outbound") {
                        if (user_profile.data.data.role.role_access.includes("outbound")) {
                            setComponent(<Outbound />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "driverRequest") {
                        if (user_profile.data.data.role.role_access.includes("driverRequest")) {
                            setComponent(<DriverRequest />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "allocations") {
                        if (user_profile.data.data.role.role_access.includes("allocations")) {
                            setComponent(<Allocations />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "return-items") {
                        if (user_profile.data.data.role.role_access.includes("return-items")) {
                            setComponent(<ReturnItems />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "reviews") {
                        if (user_profile.data.data.role.role_access.includes("reviews")) {
                            setComponent(<Reviews />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "dispute") {
                        if (user_profile.data.data.role.role_access.includes("dispute")) {
                            setComponent(<Dispute />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "create-merchant") {
                        if (user_profile.data.data.role.role_access.includes("addMerchant")) {
                            setComponent(<AddMerchant />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "create-merchant-user") {
                        if (user_profile.data.data.role.role_access.includes("addMerchantUser")) {
                            setComponent(<AddMerchantUser />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "edit-merchant") {
                        if (user_profile.data.data.role.role_access.includes("edit-merchant")) {
                            setComponent(<EditMerchant />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "create-admin") {
                        if (user_profile.data.data.role.role_access.includes("addAdmin")) {
                            setComponent(<AddAdmin />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "create-driver") {
                        if (user_profile.data.data.role.role_access.includes("addDriver")) {
                            setComponent(<AddDriver />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "user_details") {
                        if (user_profile.data.data.role.role_access.includes("userDetails")) {
                            setComponent(<UserDetails />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "driver_details") {
                        if (user_profile.data.data.role.role_access.includes("driverDetails")) {
                            setComponent(<DriverDetails />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "vehicles") {
                        if (user_profile.data.data.role.role_access.includes("vehicles")) {
                            setComponent(<Vehicles />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "general-settings") {
                        if (user_profile.data.data.role.role_access.includes("general-settings")) {
                            setComponent(<GeneralSettings />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "edit-general-settings") {
                        if (user_profile.data.data.role.role_access.includes("edit-general-settings")) {
                            setComponent(<EditGeneralSettings />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "edit-van-types") {
                        if (user_profile.data.data.role.role_access.includes("edit-van-types")) {
                            setComponent(<EditVanType />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "rate-settings") {
                        if (user_profile.data.data.role.role_access.includes("rate-settings")) {
                            setComponent(<Rate_settings />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "merchant-setting") {
                        if (user_profile.data.data.role.role_access.includes("merchant_settings")) {
                            setComponent(<Merchant_settings />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "roles") {
                        if (user_profile.data.data.role.role_access.includes("roles")) {
                            setComponent(<Roles_settings />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "admin") {
                        if (user_profile.data.data.role.role_access.includes("admins")) {
                            setComponent(<Admin />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "categories") {
                        if (user_profile.data.data.role.role_access.includes("categories")) {
                            setComponent(<Categories />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "sub_categories") {
                        if (user_profile.data.data.role.role_access.includes("sub-categories")) {
                            setComponent(<SubCategories />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "edit_category") {
                        if (user_profile.data.data.role.role_access.includes("editCategory")) {
                            setComponent(<EditCategory />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "edit_subcategory") {
                        if (user_profile.data.data.role.role_access.includes("editSubcategory")) {
                            setComponent(<EditSubCategory />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "edit_products") {
                        if (user_profile.data.data.role.role_access.includes("editProduct")) {
                            setComponent(<EditProducts />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "wise9ja") {
                        if (user_profile.data.data.role.role_access.includes("wise9ja")) {
                            setComponent(<Wise9ja_settings />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "delivery_date_settings") {
                        if (user_profile.data.data.role.role_access.includes("delivery_date")) {
                            setComponent(<Date_settings />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "products") {
                        if (user_profile.data.data.role.role_access.includes("products")) {
                            setComponent(<Products />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "wise9ja_edit") {
                        if (user_profile.data.data.role.role_access.includes("editWise9ja")) {
                            setComponent(<Wise9ja_edit />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "date_edit") {
                        if (user_profile.data.data.role.role_access.includes("editDeliveryDate")) {
                            setComponent(<Date_edit />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "fee_edit") {
                        if (user_profile.data.data.role.role_access.includes("editDeliveryFee")) {
                            setComponent(<Fee_edit />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "states") {
                        if (user_profile.data.data.role.role_access.includes("states")) {
                            setComponent(<StatesInfo />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "city") {
                        if (user_profile.data.data.role.role_access.includes("cities")) {
                            setComponent(<CityInfo />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "locations") {
                        if (user_profile.data.data.role.role_access.includes("locations")) {
                            setComponent(<LocationInfo />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "wise9ja-transaction") {
                        if (user_profile.data.data.role.role_access.includes("wise9ja-transaction")) {
                            setComponent(<Wise9ja />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "orders") {
                        if (user_profile.data.data.role.role_access.includes("orders")) {
                            setComponent(<Order />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "review-order") {
                        if (user_profile.data.data.role.role_access.includes("orderDetails")) {
                            setComponent(<EachOrder />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "accounts") {
                        if (user_profile.data.data.role.role_access.includes("accounts")) {
                            setComponent(<Account />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "payments") {
                        if (user_profile.data.data.role.role_access.includes("payments")) {
                            setComponent(<Payments />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "user-trans") {
                        if (user_profile.data.data.role.role_access.includes("accountDetails")) {
                            setComponent(<UserPayments />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "shop-reports") {
                        if (user_profile.data.data.role.role_access.includes("shop-reports")) {
                            setComponent(<OrderReport />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "wise9ja-reports") {
                        if (user_profile.data.data.role.role_access.includes("wise9ja-reports")) {
                            setComponent(<Wise9jaReport />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "wallet-reports") {
                        if (user_profile.data.data.role.role_access.includes("wallet-reports")) {
                            setComponent(<WalletReport />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "loyalty-reports") {
                        if (user_profile.data.data.role.role_access.includes("loyalty-reports")) {
                            setComponent(<LoyaltyReport />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "paybills-reports") {
                        if (user_profile.data.data.role.role_access.includes("bills-reports")) {
                            setComponent(<BillsReport />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "wise9ja-report-trans") {
                        if (user_profile.data.data.role.role_access.includes("wise9jaReportDetails")) {
                            setComponent(<Wise9jaTransMonthly />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "wallet-report-trans") {
                        if (user_profile.data.data.role.role_access.includes("walletReportDetails")) {
                            setComponent(<WalletTransMonthly />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "loyalty-report-trans") {
                        if (user_profile.data.data.role.role_access.includes("loyaltyReportDetails")) {
                            setComponent(<LoyaltyTransMonthly />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "paybills-report-trans") {
                        if (user_profile.data.data.role.role_access.includes("billsReportDetails")) {
                            setComponent(<BillsTransMonthly />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "delivery-fees-setting") {
                        if (user_profile.data.data.role.role_access.includes("delivery_fee")) {
                            setComponent(<Delivery_Fee />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "InventoryReport") {
                        if (user_profile.data.data.role.role_access.includes("inventoryReport")) {
                            setComponent(<InventoryReport />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "InventoryRecieved") {
                        if (user_profile.data.data.role.role_access.includes("inventoryRecieved")) {
                            setComponent(<InventoryRecieved />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "InventoryReturned") {
                        if (user_profile.data.data.role.role_access.includes("inventoryReturned")) {
                            setComponent(<InventoryReturned />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "InventoryDispute") {
                        if (user_profile.data.data.role.role_access.includes("inventoryDispute")) {
                            setComponent(<InventoryDispute />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "InventoryPickup") {
                        if (user_profile.data.data.role.role_access.includes("inventoryPickup")) {
                            setComponent(<InventoryPickup />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "konnect-reports") {
                        if (user_profile.data.data.role.role_access.includes("konnect-reports")) {
                            setComponent(<KonnectReport />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "cms") {
                        if (user_profile.data.data.role.role_access.includes("cms")) {
                            setComponent(<Cms />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else if (location === "edit_cms") {
                        if (user_profile.data.data.role.role_access.includes("editCms")) {
                            setComponent(<Cms_Edit />)
                        } else {
                            setComponent(<NoAccess />)
                        }
                    } else {
                        navigate('/')
                    }
                } else {
                    navigate('/')
                    openNotificationWithIcon('error', res.data.message)
                    localStorage.removeItem('konnect_admin_token')
                }
            } catch (err) {
                if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                        navigate('/')
                        localStorage.removeItem('konnect_admin_token')
                        openNotificationWithIcon('error', err.response.data.message)
                    } else {
                        openNotificationWithIcon('error', err.response.data.message)

                    }
                } else {
                    openNotificationWithIcon('error', err.message)
                }
            }
        }

        guard()
    }, [location])
    return (
        <>{component}</>
    )
}

export default DashboardGuard