/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Button, Col, Form, Input, InputNumber, Row, Select, Steps, Upload, notification } from "antd";
import { _add_admin, _add_merchants_user, _change_admin_status, _change_merchant_status, _get_admin, _get_admins, _get_merchant_user, _get_merchants_settings, _get_profile, _get_roles_byusertype, _update_merchant } from "common/axios_services";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function AddAdmin() {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [creatingUser, setCreatingUser] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [roles, setRoles] = useState([]);
    const [merchantsList, setMerchantsList] = useState([]);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    const fetchMerchantsRoles = async () => {
        try {
            const fetch_role = await _get_roles_byusertype({ type: "merchant" })
            setRoles(fetch_role.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const finishPersonal = async (values) => {
        const data = {
            role_id: '4',
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            isAccount: values.is_business,
            account_id: values.account_id || "",
            phone_number: `${values.phone_number}`,
            home_address: "",
            gender: ""

        }
        setCreatingUser(true);
        try {
            const create_user = await _add_admin(data)
            openNotificationWithIcon('success', create_user.data.message);
            setCreatingUser(false);
            navigate('/admins')
        } catch (err) {
            setCreatingUser(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const fetchMerchants = async () => {
        try {
            const fetch_merchant = await _get_merchants_settings({ page: "1", page_size: "10", search: "", category: "" })
            setMerchantsList(fetch_merchant.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        fetchMerchants()
        fetchMerchantsRoles()
    }, [])


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Add New Admin
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                </div>
                                <>
                                    <Form layout="vertical" form={form} onFinish={finishPersonal} initialValues={{
                                        first_name: "",
                                        last_name: "",
                                        email: "",
                                        phone_number: "",
                                        is_business: false,
                                        account_id: "",
                                    }}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={12}>
                                                <Form.Item name="first_name" label="First Name">
                                                    <Input placeholder="First Name" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item name="last_name" label="Last Name">
                                                    <Input placeholder="Last Name" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={12}>
                                                <Form.Item name="email" label="Email">
                                                    <Input placeholder="Email" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item name="phone_number" label="Phone Number">
                                                    <InputNumber style={{ width: "100%" }} placeholder="Phone Number" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={12}>
                                                <Form.Item name="is_business" label="Account">
                                                    <Select placeholder="Account" onChange={(values) => setIsBusiness(values)}>
                                                        <Select.Option value={false}>False</Select.Option>
                                                        <Select.Option value={true}>True</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            {isBusiness &&
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="account_id" label="Account ID">
                                                        <InputNumber style={{ width: "100%" }} placeholder="Account ID" />
                                                    </Form.Item>
                                                </Col>
                                            }
                                        </Row>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div></div>
                                            <Form.Item>
                                                {creatingUser ?
                                                    <Button type="primary" loading>Loading</Button>
                                                    :
                                                    <Button type="primary" htmlType="submit">Proceed</Button>
                                                }
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddAdmin;