/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Switch, Table, notification } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import { _add_merchants_settings, _edit_wise_status, _get_merchants_settings, _get_profile } from "common/axios_services";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';


function Merchant_settings() {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_merchant, setTotal_merchant] = useState(0);
    const [page_size, setPage_size] = useState(5);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [allProducts, setAllProducts] = useState('merchant');
    const [merchantsList, setMerchantsList] = useState([]);
    const [categoryErrorMessage, setCategoryErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedPlanID, setSelectedPlanID] = useState("");
    const [updateCount, setUpdateCount] = useState(0);
    const jwt = localStorage.konnect_admin_token;
    const { TextArea } = Input;
    const feature_id = uuidv4()
    const [deleteWise9ja, setDeleteWise9ja] = useState(false);
    const [addMerchant, setAddMerchant] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };
    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });

    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addMerchant")) {
                setAddMerchant(true)
            } else {
                setAddMerchant(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteWise9ja")) {
                setDeleteWise9ja(true)
            } else {
                setDeleteWise9ja(false)
            }
        } else {
            setDeleteWise9ja(false)
            setAddMerchant(false)
        }
    }

    const fetchMerchants = async () => {
        try {
            const fetch_merchant = await _get_merchants_settings({ page, page_size, search: "", category: "" })
            setFetchingData(false);
            setMerchantsList(fetch_merchant.data.data);
            setTotal_merchant(fetch_merchant.data.meta.total)
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        fetchUser()
        fetchMerchants()
    }, [page, page_size, updateCount])

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Category',
            dataIndex: 'category',
        },
        {
            title: 'Institution',
            dataIndex: 'institution',
        },
        {
            title: 'Display-Status',
            className: 'column-number',
            dataIndex: 'displayStatus',
            align: 'center',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const changeStatus = async ({ value, id }) => {
        try {
            const editStatus = await _edit_wise_status({ id, status: value })
            openNotificationWithIcon('success', editStatus.data.message)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    window.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    let data = merchantsList.map((merchant, index) => {
        return {
            key: index,
            id: merchant.id,
            code: merchant.merchant_code,
            name: merchant.name,
            category: merchant.category,
            institution: merchant.institution,
            displayStatus: <Form form={form} initialValues={{ status: merchant.status }}><Form.Item name="status" valuePropName="checked">{merchant.status ? <Button onClick={() => changeStatus({ value: false, id: merchant.id })}>Deactivate</Button> : <Button onClick={() => changeStatus({ value: true, id: merchant.id })}>Activate</Button>}</Form.Item></Form>,
            actions: <div><Link to={`/merchant/edit/${merchant.id}`}>Edit</Link></div>
        }
    });


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Merchant Settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {allProducts === 'merchant' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div></div>
                                                {addMerchant &&
                                                    <Button style={{ marginRight: '10px' }} onClick={() => navigate('/merchant/create')}>Add Merchant</Button>
                                                }
                                            </div>
                                            <CCardBody>
                                                {fetchingData ?
                                                    <div>
                                                        <Spin indicator={antIcon} />
                                                    </div> :
                                                    <>
                                                        <div className="table-responsive">
                                                            <Table columns={columns} dataSource={data} pagination={null} bordered footer={null} />
                                                            <div style={{ marginTop: "2%" }}></div>
                                                            <Pagination showSizeChanger current={page} total={total_merchant} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                                                        </div>
                                                    </>
                                                }
                                            </CCardBody>
                                        </React.Fragment>
                                    </Spin>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );
}

export default Merchant_settings;
