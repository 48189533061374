/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import {Button, Form, Input, Modal, Pagination, Radio, Select, Spin, Table, notification} from "antd";
import {_export_sales, _get_sales, _get_sales_matrix} from "common/axios_services";
import moment from "moment";
import {useEffect, useState} from "react";
import {NumericFormat} from "react-number-format";
import {Link, useNavigate} from "react-router-dom";

function Sales() {
    const [form] = Form.useForm();
    const [salesList, setSalesList] = useState([]);
    const [page, setPage] = useState(1);
    const [total_sales, setTotal_sales] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [arrayToDisplay, setArrayToDisplay] = useState([]);
    const [displaySwitch, setDisplaySwitch] = useState("view");
    const [query, setQuery] = useState("");
    const [filterOptions, setFilterOptions] = useState("sl");
    const [salesMatrix, setSalesMatrix] = useState(false);
    const [exportingData, setExportingData] = useState(false);
    const navigate = useNavigate()
    const [filterDate, setFilterDate] = useState("");
    const [filterToDate, setFilterToDate] = useState(moment().format('YYYY-MM-DD'));

    const openNotificationWithIcon = (type, message) => {
        notification[type]({message: '', placement: 'bottomRight', description: message, });
    };

    const getSales = async () => {
        try {
            const get_sales = await _get_sales({page, page_size, search: query, category: filterOptions, filterDate, filterToDate})
            setAllUsersFetched(true);
            setSalesList(get_sales.data.data);
            setTotal_sales(get_sales.data.meta.total);
            setArrayToDisplay(get_sales.data.data);
        } catch (err) {
            setAllUsersFetched(true);
            setSalesList([]);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getSalesMatrix = async () => {
        try {
            const get_sales_matrix = await _get_sales_matrix({filterDate, filterToDate})
            setSalesMatrix(get_sales_matrix.data.data)
        } catch (err) {
            setSalesMatrix(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    useEffect(() => {
        getSales()
        getSalesMatrix()
    }, [page, page_size, query, filterOptions, filterDate, filterToDate])

    const data = [];
    arrayToDisplay.forEach((sales, index) => {
        data.push({
            key: index,
            sn: index + 1,
            name: `${sales?.first_name + " " + sales?.last_name}`,
            phone: sales?.phone_number,
            sa: sales?.sa_no,
            so: sales?.so_no,
            order: sales?.so_order_no,
            orderN: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(sales?.so_order_amount)} />,
            incomeN: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(sales?.so_order_income)} />,
            action: <Link to={`/${filterOptions}/${sales?.user_id}/${sales?.first_name}`}>View</Link>
        })
    });

    const slColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Name',
            className: 'column-number',
            dataIndex: 'name',
        },
        {
            title: 'Phone',
            className: 'column-number',
            dataIndex: 'phone',
            align: 'center',
        },
        {
            title: 'FV',
            className: 'column-number',
            dataIndex: 'so',
            align: 'center',
        },
        {
            title: 'Order',
            className: 'column-number',
            dataIndex: 'order',
            align: 'center',
        },
        {
            title: 'Order NGN',
            className: 'column-number',
            dataIndex: 'orderN',
            align: 'center',
        },
        {
            title: 'Income NGN',
            className: 'column-number',
            dataIndex: 'incomeN',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center'
        }
    ];
    const saColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Name',
            className: 'column-number',
            dataIndex: 'name',
        },
        {
            title: 'Phone',
            className: 'column-number',
            dataIndex: 'phone',
            align: 'center',
        },
        {
            title: 'FV',
            className: 'column-number',
            dataIndex: 'so',
            align: 'center',
        },
        {
            title: 'Order',
            className: 'column-number',
            dataIndex: 'order',
            align: 'center',
        },
        {
            title: 'Order NGN',
            className: 'column-number',
            dataIndex: 'orderN',
            align: 'center',
        },
        {
            title: 'Income NGN',
            className: 'column-number',
            dataIndex: 'incomeN',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center'
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setSalesList([]);
    }

    function dataFilter({search}) {
        setQuery(search.toLowerCase())
        setAllUsersFetched(false)
    }

    const onChange = (e) => {
        setFilterOptions(e.target.value)
    }

    const exportSales = async () => {
        try {
            setExportingData(true)
            const export_sales = await _export_sales({search: query, category: filterOptions})
            window.open(export_sales?.data?.data?.url)
            setExportingData(false)
        } catch (err) {
            setExportingData(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const onChangeDate = (e) => setFilterDate(e.target.value);
    const onChangeToDate = (e) => setFilterToDate(e.target.value);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="primary"
                            icon="person_add"
                            title="SM"
                            count={salesMatrix?.total_sl ? salesMatrix?.total_sl : 0}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="dark"
                            icon="weekend"
                            title="Restaurant"
                            count={salesMatrix?.total_sa ? salesMatrix?.total_sa : 0}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            icon="leaderboard"
                            title="Sales (NGN)"
                            count={salesMatrix?.total_so ? <NumericFormat thousandSeparator={true} displayType={'text'} value={+salesMatrix?.total_so} /> : '0.00'}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="success"
                            icon="store"
                            title="Sales Income (NGN)"
                            count={salesMatrix?.so_order_amount ? <NumericFormat thousandSeparator={true} displayType={'text'} value={+salesMatrix?.so_order_amount} /> : '0.00'}
                        />
                    </MDBox>
                </Grid>
            </Grid>

            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white"> Sales</MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div style={{display: "flex"}}>
                                    {exportingData ?
                                        <Button loading style={{marginLeft: "auto"}}>Exporting Data</Button> :
                                        <Button type="primary" onClick={exportSales} style={{marginLeft: "auto"}}>Export</Button>
                                    }
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    {displaySwitch === "view" ?
                                        <div>
                                            <label>Filter Sales Data</label>
                                            <Form layout="vertical" form={form} initialValues={{
                                                search: ""
                                            }} onValuesChange={dataFilter} style={{maxWidth: 600, minWidth: 300}}
                                            >
                                                <Form.Item name="search">
                                                    <Input placeholder="search by ref code" />
                                                </Form.Item>
                                            </Form>
                                        </div> :
                                        <div></div>
                                    }
                                    <div style={{display: "flex", alignItems: "flex-end"}}>
                                        {/* <Radio.Group onChange={onChange} defaultValue="sl">
                                            <Radio.Button value="sl">SM</Radio.Button>
                                            <Radio.Button value="sa">FV</Radio.Button>
                                        </Radio.Group> */}
                                        <div className="grid-flex" style={{marginLeft: "10px", fontSize: "14px"}}>
                                            <p style={{marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)"}}>From</p>
                                            <input id="date" style={{width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)"}} type="date" defaultValue={filterDate} max={moment().format('YYYY-MM-DD')} name="date" onChange={onChangeDate} />
                                        </div>
                                        <div className="grid-flex" style={{marginLeft: "10px", fontSize: "14px"}}>
                                            <p style={{marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)"}}>To</p>
                                            <input id="date" style={{width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)"}} max={moment().format('YYYY-MM-DD')} type="date" defaultValue={filterToDate} name="date" onChange={onChangeToDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {allUsersFetched ?
                                        <>
                                            {filterOptions === "sl" &&
                                                <Table columns={slColumns} pagination={false} dataSource={data} bordered />
                                            }
                                            {filterOptions === "sa" &&
                                                <Table columns={saColumns} pagination={false} dataSource={data} bordered />
                                            }
                                            <div style={{marginTop: "2%"}}></div>
                                            <Pagination showSizeChanger current={page} total={total_sales} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                                            <div style={{marginTop: "2%"}}></div>
                                        </>
                                        :
                                        <div className="">
                                            <Spin />
                                        </div>
                                    }
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Sales;
