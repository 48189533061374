/* eslint-disable */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Button, Modal, Pagination, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { _get_konnect_report, _get_wallet_matrix, _konnect_trans_approval } from "common/axios_services";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { NumericFormat } from "react-number-format";

function KonnectReport() {
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [transDetails, setTransDetails] = useState(false);
    const [confirmingTransaction, setConfirmingTransaction] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [konnect, setKonnect] = useState([]);
    const [walletMatrix, setWalletMatrix] = useState(false);
    const [page, setPage] = useState(1);
    const [totalTrans, setTotalTrans] = useState(0);
    const [updateCount, setUpdateCount] = useState(1);
    const [page_size, setPage_size] = useState(10);

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title,
            placement: 'bottomRight',
            description: message,
        });
    };

    const buttonStyle = {
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px', // Rounded square background
        padding: 0, // Remove any extra padding
        border: 'none' // Remove border if any
    };

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    const getKonnectReport = async () => {
        try {
            const fetchAllKonnect = await _get_konnect_report({ page, page_size })
            setSpinnerLoading(false)
            setKonnect(fetchAllKonnect?.data?.data);
            setTotalTrans(fetchAllKonnect?.data?.meta?.total);
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                if (err.response === "Unauthorized") {
                    window.location.reload()
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }
    let data = konnect.map((trans, index) => {
        return {
            key: index,
            date: trans?.created_at.split('T')[0],
            username: `${trans?.user?.first_name}` + " " + `${trans?.user?.last_name}`,
            konnectBalance: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(trans?.available_bal)} />,
            ammountToTransfer: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(trans?.total_amount)} />,
            satus: <div style={{ textTransform: "capitalize" }}>{trans.status === "success" ? <Tag color="#87d068">{trans.status}</Tag> : trans.status === "canceled" || trans.status === "cancel" ? <Tag color="#cf1322">{trans.status}</Tag> : trans.status === "rejected" ? <Tag color="#FF5733">{trans.status}</Tag> : <Tag color="#ffa500">{trans.status}</Tag>}</div>,
            action: (trans.status !== "success" && trans.status !== "canceled" && trans.status !== "rejected") && (
                <div style={{ display: 'flex', gap: '10px' }}>
                    {/* Approve Button */}
                    <Button type="primary" onClick={() => { setConfirmModal(true); setTransDetails(trans) }} icon={<CheckOutlined style={{ fontSize: '16px', color: 'green' }} />} style={{ ...buttonStyle, backgroundColor: 'rgba(0, 128, 0, 0.3)' }} />

                    {/* Reject Button */}
                    <Button type="primary" onClick={() => { setRejectModal(true); setTransDetails(trans) }} icon={<CloseOutlined style={{ fontSize: '16px', color: 'red' }} />} style={{ ...buttonStyle, backgroundColor: 'rgba(255, 0, 0, 0.3)' }} />
                </div>
            ),
        }
    });
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Usename',
            dataIndex: 'username',
            align: 'center'
        },
        {
            title: 'Konnect Balance',
            dataIndex: 'konnectBalance',
            align: 'right'
        },
        {
            title: 'Amount To Transfer',
            dataIndex: 'ammountToTransfer',
            align: 'right'
        },
        {
            title: 'Status',
            dataIndex: 'satus',
            align: 'right'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center'
        },
    ];
    const getWalletMatrix = async () => {
        try {
            const wallet_matrix = await _get_wallet_matrix()
            setWalletMatrix(wallet_matrix.data.data)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_admin_token')
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    useEffect(() => {
        getKonnectReport()
        getWalletMatrix()
    }, [updateCount, page, page_size])

    const transactionApproval = async ({ id, status }) => {
        setConfirmingTransaction(true)
        try {
            let transaction_approval = await _konnect_trans_approval({ id, status });
            setConfirmModal(false)
            setRejectModal(false)
            setUpdateCount(updateCount + 1)
            openNotificationWithIcon('success', transaction_approval?.data?.title, transaction_approval?.data?.message)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        } finally {
            setConfirmingTransaction(false)
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={4}>
                {/* Metrix */}
                <Grid container spacing={3} pb={6}>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="dark"
                                icon="weekend"
                                title="Total Transactions"
                                count='0.00'
                            // count={walletMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}><NumericFormat thousandSeparator={true} displayType={'text'} value={Number(walletMatrix?.transactions)} /> </span> : "0"}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="leaderboard"
                                title="Total Credit"
                                count='0.00'
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="leaderboard"
                                title="Total Debit"
                                count='0.00'
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="leaderboard"
                                title="Total Balance"
                                count='0.00'
                            />
                        </MDBox>
                    </Grid>
                </Grid>
                {/* Body */}
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Konnect Reports
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <>
                                            <div className="table-responsive">
                                                <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                <Pagination showSizeChanger current={page} total={totalTrans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Transactions`} defaultPageSize={page_size} onChange={change_page} />
                                            </div>
                                        </>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                {/* Modals */}
                <Modal centered={true} title="" open={confirmModal} onCancel={() => { setConfirmModal(false); setTransDetails(false) }} footer={null}>
                    <div className="extra-info-modal">
                        <h2>Approve Konnect Transaction</h2>
                        <p>Are you sure you want to approve this transaction <b>NGN <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(transDetails?.total_amount)} /></b> made by <b>{transDetails?.user?.first_name} {transDetails?.user?.last_name}</b></p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Button onClick={() => { setConfirmModal(false); setTransDetails(false) }}>Close</Button>
                            {confirmingTransaction ?
                                <Button type='primary' disabled loading>Proceed</Button> :
                                <Button type='primary' onClick={() => transactionApproval({ id: transDetails?.id, status: "approve" })}>Proceed</Button>
                            }
                        </div>
                    </div>
                </Modal>
                <Modal centered={true} title="" open={rejectModal} onCancel={() => { setRejectModal(false); setTransDetails(false) }} footer={null}>
                    <div className="extra-info-modal">
                        <h2>Reject Konnect Transaction</h2>
                        <p>Are you sure you want to reject this transaction <b>NGN <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(transDetails?.total_amount)} /></b> made by <b>{transDetails?.user?.first_name} {transDetails?.user?.last_name}</b></p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Button onClick={() => { setRejectModal(false); setTransDetails(false) }}>Close</Button>
                            {confirmingTransaction ?
                                <Button type='primary' disabled loading>Proceed</Button> :
                                <Button type='primary' onClick={() => transactionApproval({ id: transDetails?.id, status: "reject" })}>Proceed</Button>
                            }
                        </div>
                    </div>
                </Modal>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default KonnectReport;