/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Spin, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { _get_bills_report } from "common/axios_services";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

function BillsReport() {
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [loyalty, setLoyalty] = useState([]);
    const jwt = localStorage.konnect_admin_token;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };


    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const getBillsReport = async () => {
        try {
            const fetchAllBills = await _get_bills_report()
            setSpinnerLoading(false)
            setLoyalty(fetchAllBills.data.data);
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                if (err.response === "Unauthorized") {
                    window.location.reload()
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }
    useEffect(() => {
        getBillsReport()
    }, [])

    let data = loyalty.map((trans, index) => {
        return {
            key: index,
            month: trans.month,
            bills: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(trans.total_bills)} />,
            sales: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans.sales) * 1)} />,
            purchase: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans.purchase) * 1)} />,
            bal: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans.balance) * 1)} />,
        }
    });
    const columns = [
        {
            title: 'Month',
            dataIndex: 'month',
        },
        {
            title: 'Quantity',
            dataIndex: 'bills',
            align: 'center'
        },
        {
            title: 'Sales (NGN)',
            dataIndex: 'sales',
            align: 'right'
        },
        {
            title: 'Purchase (NGN)',
            dataIndex: 'purchase',
            align: 'right'
        },
        {
            title: 'Balance (NGN)',
            dataIndex: 'bal',
            align: 'right'
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        // },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Bills Reports
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <>
                                            <div className="table-responsive">
                                                <Table columns={columns} pagination={false} dataSource={data} bordered />
                                            </div>
                                        </>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default BillsReport;