import { Button, Modal, Pagination, Spin, Switch, Table, notification } from "antd";
import { _attach_sa, _get_attachable_sa } from "common/axios_services";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setCount } from "splices/updateCountSplice";

const AttachSaComp = () => {
    const navigate = useNavigate()
    const [attachSaModal, setAttachSaModal] = useState(false);
    const [page, setPage] = useState(1);
    const [total_sa, setTotal_sa] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [arrayToDisplay, setArrayToDisplay] = useState([]);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [attachingSa, setAttachingSa] = useState(false);
    const [query, setQuery] = useState("");
    const params = useParams()
    const dispatch = useDispatch()
    const updateCount = useSelector(state => state?.updateCount?.count)

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({ message: title, placement: 'bottomRight', description: message, });
    };

    const getAvailableSa = async () => {
        setAllUsersFetched(false);
        setArrayToDisplay([]);
        try {
            const get_sales = await _get_attachable_sa({ page, page_size, search: query })
            setAllUsersFetched(true);
            setTotal_sa(get_sales.data.meta.total);
            setArrayToDisplay(get_sales.data.data);
        } catch (err) {
            setAllUsersFetched(true);
            setArrayToDisplay([]);
            if (err.response) {
                if (err?.response?.data?.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const attachSa = async ({ ref_id, status }) => {
        setAttachingSa(true)
        try {
            const attcah_sa = await _attach_sa({ ref_id, user_id: params.id, status })
            setAttachingSa(false)
            openNotificationWithIcon('success', attcah_sa?.data?.title, attcah_sa?.data?.message)
            dispatch(setCount(updateCount + 1))
        } catch (err) {
            setAttachingSa(false)
            if (err.response) {
                if (err?.response?.data?.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    let sa_list = arrayToDisplay?.map((sa) => {
        return {
            radio: sa?.sl_user_id === params?.id ?
                attachingSa ?
                    <Switch defaultChecked={false} loading /> :
                    <Switch defaultChecked={true} onChange={() => attachSa({ ref_id: sa?.id, status: false })} /> :
                attachingSa ?
                    <Switch defaultChecked={true} loading /> :
                    <Switch onChange={() => attachSa({ ref_id: sa?.id, status: true })} />,
            name: sa?.fullname,
            email: sa?.email,
            address: sa?.address,
        }
    });

    const saList = [
        {
            title: '',
            dataIndex: 'radio',
        },
        {
            title: 'Username',
            className: 'column-number',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: 'Email',
            className: 'column-number',
            dataIndex: 'email',
            align: 'center',
        },
        {
            title: 'Address',
            className: 'column-number',
            dataIndex: 'address',
            align: 'center',
        },
    ];

    useEffect(() => {
        getAvailableSa()
    }, [page, page_size, query, updateCount])

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setArrayToDisplay([]);
    }
    return (
        <>
            <Button type="primary" onClick={() => { setAttachSaModal(true); getAvailableSa() }}>Attach SA</Button>
            <Modal centered={true} visible={attachSaModal} onCancel={() => setAttachSaModal(false)} width={720} footer={null}>
                <h5 style={{ marginBottom: "2rem" }}>Attach SA</h5>
                <div className="table-responsive">
                    {allUsersFetched ?
                        <>
                            <Table columns={saList} pagination={false} dataSource={sa_list} bordered />
                            <div style={{ marginTop: "2%" }}></div>
                            <Pagination showSizeChanger current={page} total={total_sa} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                            <div style={{ marginTop: "2%" }}></div>
                        </>
                        :
                        <div className="">
                            <Spin />
                        </div>
                    }
                </div>
            </Modal>
        </>
    )
}

export default AttachSaComp