/* eslint-disable */

import CryptoJS from "crypto-js";

const Jwt_decrypt = ({ token }) => {
    const secretPass = process.env.REACT_APP_JWT_SECRET;
    const bytes = CryptoJS.AES.decrypt(token, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return (data)
}

export default Jwt_decrypt