/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    features: []
};

export const featureSplice = createSlice({
    name: "features",
    initialState,
    reducers: {
        setFeatures: (state, action) => { state.features = action.payload },
    },
});

export const { setFeatures } = featureSplice.actions;

export default featureSplice.reducer;