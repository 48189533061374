import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import moment from 'moment';

import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
// import { DeleteOutlined } from "@mui/icons-material";
import { Button, DatePicker, Form, Input, InputNumber, Select, Spin, notification } from 'antd';
import { _edit_date, _edit_fee, _fee_id } from "common/axios_services";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CategoryNav from 'examples/Navbars/CategoryNav';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";

const Fee_edit = () => {
    const [selectedFee, setSelectedFee] = useState([])
    const [editing, setEditing] = useState(false)

    const navigate = useNavigate()
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [form] = Form.useForm();
    const jwt = localStorage.konnect_admin_token;
    const params = useParams()

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const getFee = async () => {
        try {
            const selDate = await _fee_id({ id: params.id })
            setSelectedFee(selDate.data.data)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        if (!jwt) {
            openNotificationWithIcon('error', 'Unauthorized')
            return navigate('/')
        }

        getFee()
    }, [params.id, jwt, navigate])


    const update_date = async (values) => {
        setEditing(true)
        try {
            const data = {
                title: values.title,
                min_cap: `${values.min_cap}`,
                max_cap: `${values.max_cap}`,
                value: `${values.value}`,
                unit: values.unit,
                description: values.description,
                is_free: true
            }
            setEditing(false)
            const editFees = await _edit_fee({ id: params.id, data })
            openNotificationWithIcon('success', editFees.data.message)
            navigate('/delivery_fee')
        } catch (err) {
            setEditing(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }


    return (
        <DashboardLayout>
            <CategoryNav title="Edit wise9ja plan" breadroute="wise9ja" />
            <MDBox pt={6} pb={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Delivery Fee
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3} px={2}>
                                {(selectedFee.id) ?
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Delivery Fee
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {selectedFee && (
                                                    <Form form={form} onFinish={update_date} initialValues={{
                                                        title: selectedFee.title,
                                                        min_cap: selectedFee.min_cap,
                                                        max_cap: selectedFee.max_cap,
                                                        value: selectedFee.value.toFixed(2),
                                                        unit: selectedFee.unit,
                                                        description: selectedFee.description,
                                                    }} layout="vertical">
                                                        <Form.Item name="title" label="Title" >
                                                            <Input style={{ width: "100%" }} placeholder="Title" />
                                                        </Form.Item>
                                                        <Form.Item name="description" label="Description" >
                                                            <Input style={{ width: "100%" }} placeholder="Description" />
                                                        </Form.Item>
                                                        <Form.Item name="min_cap" label="Min Capacity" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Min Capacity" />
                                                        </Form.Item>
                                                        <Form.Item name="max_cap" label="Max Capacity" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Max Capacity" />
                                                        </Form.Item>
                                                        <Form.Item name="unit" label="Unit" >
                                                            <Select placeholder="Unit">
                                                                <Select.Option value="percentage">Percentage</Select.Option>
                                                                <Select.Option value="flat">Flat</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="value" label="Value" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Value" />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            {editing ?
                                                                <Button disabled loading type="primary">Submit</Button> :
                                                                <Button htmlType="submit" type="primary">Submit</Button>
                                                            }
                                                        </Form.Item>
                                                    </Form>
                                                )}
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Delivery Fee
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <Spin indicator={antIcon} />
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>

                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default Fee_edit