/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import { notification } from "antd";
import { _get_admin, _get_admin_matrix, _get_inbound_matrix, _get_outbound_matrix, _get_profile, _get_shop_matrix, _get_wallet_matrix, _get_wise9ja_matrix } from "common/axios_services";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import DashboardNavbar from "examples/Navbars/DashboardNav";
import Projects from "layouts/dashboard/components/Projects";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import reportsBarChartData from "./data/reportsBarChartData";
import reportsLineChartData from "./data/reportsLineChartData";
import { _get_users_metrix } from "common/axios_services";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [role, setRole] = useState("")
  const [inboundMatrix, setInboundMatrix] = useState(false)
  const [outboundMatrix, setOutboundMatrix] = useState(false)
  const [walletMatrix, setWalletMatrix] = useState(false)
  const [wise9jaMatrix, setWise9jaMatrix] = useState(false)
  const [adminMatrix, setAdminMatrix] = useState(false)
  const [shopMatrix, setShopMatrix] = useState(false)

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: '',
      placement: 'bottomRight',
      description:
        message,
    });
  };

  const getAdminMatrix = async () => {
    try {
      const admin_matrix = await _get_users_metrix({ filterDate: "", filterToDate: "" })
      setAdminMatrix(admin_matrix.data.data)
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          navigate('/')
          localStorage.removeItem('konnect_admin_token')
          openNotificationWithIcon('error', err.response.data.message)
        } else {
          openNotificationWithIcon('error', err.response.data.message)

        }
      } else {
        openNotificationWithIcon('error', err.message)
      }
    }
  }
  const getShopMatrix = async () => {
    try {
      const shop_matrix = await _get_shop_matrix()
      setShopMatrix(shop_matrix.data.data.summery)
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          navigate('/')
          localStorage.removeItem('konnect_admin_token')
          openNotificationWithIcon('error', err.response.data.message)
        } else {
          openNotificationWithIcon('error', err.response.data.message)

        }
      } else {
        openNotificationWithIcon('error', err.message)
      }
    }
  }

  const getProfile = async () => {
    try {
      const user_profile = await _get_profile()
      if (user_profile.data.data.role.id === "6") {
        setRole("merchant")
      } else if (user_profile.data.data.role.id === "4") {
        setRole("admin")
        getAdminMatrix()
        getShopMatrix()
      } else if (user_profile.data.data.role.id === "7") {
        setRole("staff_merchant")
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          navigate('/')
          localStorage.removeItem('konnect_admin_token')
          openNotificationWithIcon('error', err.response.data.message)
        } else {
          openNotificationWithIcon('error', err.response.data.message)

        }
      } else {
        openNotificationWithIcon('error', err.message)
      }
    }
  }

  const fetchInboundMatrix = async () => {
    try {
      const matrix = await _get_inbound_matrix({ date: "" })
      setInboundMatrix(matrix.data.data)
    } catch (err) {
      setInboundMatrix(false)
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem('konnect_token')
          navigate(`/authentication/signin`)
        }
        openNotificationWithIcon('error', err.response.data.message)
      } else {
        openNotificationWithIcon('error', err.message)
      }
    }
  }

  const fetchOutboundMatrix = async () => {
    try {
      const matrices = await _get_outbound_matrix({ date: "", lga: "", driver: "" })
      setOutboundMatrix(matrices.data.data)
    } catch (err) {
      setOutboundMatrix(false)
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem('konnect_token')
          navigate(`/authentication/signin`)
        }
        openNotificationWithIcon('error', err.response.data.message)
      } else {
        openNotificationWithIcon('error', err.message)
      }
    }
  }
  const getWalletMatrix = async () => {
    try {
      const wallet_matrix = await _get_wallet_matrix()
      setWalletMatrix(wallet_matrix.data.data)
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem('konnect_admin_token')
          navigate('/')
          openNotificationWithIcon('error', err.response.data.message)
        } else {
          openNotificationWithIcon('error', err.response.data.message)
        }
      } else {
        openNotificationWithIcon('error', err.message)
      }
    }
  }
  const getWise9jaMatrix = async () => {
    try {
      const wise9ja_matrix = await _get_wise9ja_matrix()
      setWise9jaMatrix(wise9ja_matrix.data.data)
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem('konnect_admin_token')
          navigate('/')
          openNotificationWithIcon('error', err.response.data.message)
        } else {
          openNotificationWithIcon('error', err.response.data.message)
        }
      } else {
        openNotificationWithIcon('error', err.message)
      }
    }
  }

  useEffect(() => {
    getProfile()
    fetchInboundMatrix()
    fetchOutboundMatrix()
    getWalletMatrix()
    getWise9jaMatrix()
  }, [])

  return (
    <DashboardLayout>
      {role === "admin" &&
        <>
          <DashboardNavbar role="Admin Dashboard" />
          <MDBox py={3}>
            {/* 4 cat */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="weekend"
                    title="Verified users"
                    count={adminMatrix?.total_email_verified ? adminMatrix?.total_email_verified : 0}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Tier 1 users"
                    count={adminMatrix?.tier_one_users ? adminMatrix?.tier_one_users : 0}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="store"
                    title="Tier 2 users"
                    count={adminMatrix?.tier_two_users ? adminMatrix?.tier_two_users : 0}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="person_add"
                    title="Food vendors"
                    count={adminMatrix?.total_food_vendor ? adminMatrix?.total_food_vendor : 0}
                  />
                </MDBox>
              </Grid>
            </Grid>
            {/* cards */}
            <MDBox mt={4.5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <ReportsBarChart
                      color="info"
                      title="Wallet balance"
                      description={walletMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}>{walletMatrix?.total_users} <span style={{ fontSize: "12px", fontWeight: "normal" }}>Count</span> </span> : "Count"}
                      date={walletMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}> <span style={{ fontWeight: "normal", fontSize: "12px" }}>NGN</span>  <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(walletMatrix?.total_balance) * 1)} /> </span> : "Naira"}
                      chart={reportsBarChartData}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <ReportsLineChart
                      color="success"
                      title="Transaction"
                      description={wise9jaMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}>{wise9jaMatrix?.total_users} <span style={{ fontSize: "12px", fontWeight: "normal" }}>Count</span> </span> : "Count"}
                      date={wise9jaMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}> <span style={{ fontWeight: "normal", fontSize: "12px" }}> NGN</span> <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(wise9jaMatrix?.cash_to_earned) * 1)} /> </span> : "Naira"}
                      chart={sales}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <ReportsLineChart
                      color="dark"
                      title="Gross Income"
                      description={wise9jaMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}>{wise9jaMatrix?.total_users} <span style={{ fontSize: "12px", fontWeight: "normal" }}>Count</span> </span> : "Count"}
                      date={wise9jaMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}> <span style={{ fontWeight: "normal", fontSize: "12px" }}> NGN</span> <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(wise9jaMatrix?.total_balance) * 1)} /></span> : "Naira"}
                      chart={tasks}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            {/* Table */}
            <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Projects />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </>
      }
      {role === "merchant" &&
        <>
          <DashboardNavbar role="Merchant Dashboard" />
          {outboundMatrix && inboundMatrix ?
            <MDBox py={3}>
              {/* 4 cat */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="dark"
                      icon="weekend"
                      title="Open Inbound"
                      count={Number(inboundMatrix.total_qty_open)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Confirmed Inbound"
                      count={Number(inboundMatrix.qty_supplied)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="store"
                      title="Total Item Outbound"
                      count={Number(outboundMatrix.total_items)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="person_add"
                      title="Total Quantity Outbound"
                      count={Number(outboundMatrix.delivery_qty)}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              {/* cards */}
              <MDBox mt={4.5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChart
                        color="info"
                        title="Wise9ja"
                        description="In"
                        date="Out"
                        chart={reportsBarChartData}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="success"
                        title="Business Index"
                        description={
                          <>
                            <strong>in</strong>
                          </>
                        }
                        date="Out"
                        chart={sales}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="dark"
                        title="Account Balance"
                        description="In"
                        date="Out"
                        chart={tasks}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {/* Table */}
              {/* <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Projects />
                </Grid>
              </Grid>
            </MDBox> */}
            </MDBox>
            :
            <MDBox py={3}>
              {/* 4 cat */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="dark"
                      icon="weekend"
                      title="Open Inbound"
                      count={0}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Confirmed Inbound"
                      count="0"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="store"
                      title="Total Item Outbound"
                      count="0"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="person_add"
                      title="Total Quantity Outbound"
                      count="0"
                    />
                  </MDBox>
                </Grid>
              </Grid>
              {/* cards */}
              <MDBox mt={4.5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChart
                        color="info"
                        title="Wise9ja"
                        description="In"
                        date="Out"
                        chart={reportsBarChartData}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="success"
                        title="Business Index"
                        description={
                          <>
                            <strong>in</strong>
                          </>
                        }
                        date="Out"
                        chart={sales}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="dark"
                        title="Account Balance"
                        description="In"
                        date="Out"
                        chart={tasks}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {/* Table */}
              {/* <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Projects />
                </Grid>
              </Grid>
            </MDBox> */}
            </MDBox>
          }
        </>
      }
      {role === "staff_merchant" &&
        <>
          <DashboardNavbar role="Staff Merchant Dashboard" />
          {outboundMatrix && inboundMatrix ?
            <MDBox py={3}>
              {/* 4 cat */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="dark"
                      icon="weekend"
                      title="Open Inbound"
                      count={Number(inboundMatrix.total_qty_open)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Confirmed Inbound"
                      count={Number(inboundMatrix.qty_supplied)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="store"
                      title="Total Item Outbound"
                      count={Number(outboundMatrix.total_items)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="person_add"
                      title="Total Quantity Outbound"
                      count={Number(outboundMatrix.delivery_qty)}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mt={4.5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChart
                        color="info"
                        title="Wise9ja"
                        description="In"
                        date="Out"
                        chart={reportsBarChartData}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="success"
                        title="Business Index"
                        description={
                          <>
                            <strong>in</strong>
                          </>
                        }
                        date="Out"
                        chart={sales}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="dark"
                        title="Account Balance"
                        description="In"
                        date="Out"
                        chart={tasks}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            :
            <MDBox py={3}>
              {/* 4 cat */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="dark"
                      icon="weekend"
                      title="Open Inbound"
                      count={0}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Confirmed Inbound"
                      count="0"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="store"
                      title="Total Item Outbound"
                      count="0"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="person_add"
                      title="Total Quantity Outbound"
                      count="0"
                    />
                  </MDBox>
                </Grid>
              </Grid>
              {/* cards */}
              <MDBox mt={4.5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChart
                        color="info"
                        title="Wise9ja"
                        description="In"
                        date="Out"
                        chart={reportsBarChartData}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="success"
                        title="Business Index"
                        description={
                          <>
                            <strong>in</strong>
                          </>
                        }
                        date="Out"
                        chart={sales}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="dark"
                        title="Account Balance"
                        description="In"
                        date="Out"
                        chart={tasks}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {/* Table */}
              {/* <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Projects />
                </Grid>
              </Grid>
            </MDBox> */}
            </MDBox>
          }
        </>
      }
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
