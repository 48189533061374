/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Pagination, Select, Spin, Table, Tag, notification } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody } from "@coreui/react";
import { _get_available_drivers, _get_dateby_id, _get_dates, _get_delivery_drivers, _get_outbound_pickup_by_driver, _get_pickup } from "common/axios_services";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CategoryNav from 'examples/Navbars/CategoryNav';
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactMoment from 'react-moment';
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";

function InventoryPickup() {
    const params = useParams()
    const navigate = useNavigate()
    const [page, setPage] = useState(1);
    const [page_size, setPage_size] = useState(10);
    const [updateCount, setUpdateCount] = useState(10);
    const [total_items, setTotal_items] = useState(0);
    const [pickupfetched, setPickupFetched] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const [fetchingDate, setFetchingDate] = useState(true);
    const [deliveryDates, setDeliveryDates] = useState([]);
    const [filterToDate, setFilterToDate] = useState(moment().format('YYYY-MM-DD'));
    const [formattedDate, setFormattedDate] = useState(false);
    const [deliveryDateDetails, setDeliveryDateDetails] = useState(false);

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const getPickup = async () => {
        setFetchingData(true);
        try {
            const getPickup = await _get_outbound_pickup_by_driver({ page, page_size, delivery_date_id: `${(params.dateid && params.dateid !== "date") ? params.dateid : ""}`, driver_id: `${(params.driver && params.driver !== "driver") ? params.driver : ""}`, search: "" })
            setPickupFetched(getPickup?.data?.data);
            setTotal_items(getPickup?.data?.meta?.total);
            setFetchingData(false);
        } catch (err) {
            setPickupFetched([]);
            setFetchingData(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const getDrivers = async () => {
        try {
            const driver = await _get_delivery_drivers({ search: "", delivery_date_id: "", from: "", to: "" })
            if (driver.data.data === null) {
                setDrivers([])
            } else {
                const defaultOption = { value: 'driver', key: 'driver', id: "driver", label: 'All Drivers' };
                const optionsWithDefault = [defaultOption, ...driver.data.data];
                setDrivers(optionsWithDefault)
            }
        } catch (err) {
            setDrivers([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const selectDriver = ({ id }) => {
        if (params.dateid && params.dateid !== "date") {
            navigate(`/inventory-pickup/${id}/${params.dateid}`)
        } else {
            navigate(`/inventory-pickup/${id}/date`)
        }
        setUpdateCount(updateCount + 1)
    }

    const fetchDeliveryDates = async () => {
        setFetchingDate(true)
        try {
            const delivery_dates = await _get_dates({ page: 1, page_size: 10 })
            setFetchingDate(false)
            const defaultOption = { value: 'date', key: 'date', id: "date", label: 'None' };
            const optionsWithDefault = [defaultOption, ...delivery_dates.data.data];
            setDeliveryDates(optionsWithDefault)
        } catch (err) {
            setFetchingDate(false)
            setDeliveryDates([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getDate = async ({ id }) => {
        try {
            const delivery_date = await _get_dateby_id({ id })
            setDeliveryDateDetails(delivery_date?.data?.data)
            const time = moment(delivery_date?.data?.data?.date);
            setFormattedDate(time.format('MMMM Do YYYY'))
        } catch (err) {
            setDeliveryDateDetails(false)
            setFormattedDate(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const selectDeliveryDate = ({ id }) => {
        setUpdateCount(updateCount + 1)
        if (params.driver && params.driver !== "driver") {
            navigate(`/inventory-pickup/${params.driver}/${id}`)
        } else {
            navigate(`/inventory-pickup/driver/${id}`)
        }
    }

    useEffect(() => {
        getPickup()
        getDrivers()
        fetchDeliveryDates()
        if (params.dateid && params.dateid !== "date") {
            getDate({ id: params.dateid })
        } else {
            setDeliveryDateDetails(false)
        }
    }, [page, page_size, filterToDate, updateCount])

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Item',
            dataIndex: 'item',
        },
        {
            title: 'Buyer(s)',
            dataIndex: 'buyer',
        },
        {
            title: 'Pick-Up',
            dataIndex: 'pickUp',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        }
    ];

    let data = pickupfetched.map((items, index) => {
        return {
            sn: index + 1,
            item: items?.item_name,
            buyer: <NumericFormat thousandSeparator={true} displayType={'text'} value={+items?.total_buyer} />,
            pickup: <NumericFormat thousandSeparator={true} displayType={'text'} value={+items?.total_items} />,
            status: <div style={{ textTransform: "capitalize" }}>{items?.order_delivery_status === "delivered" ? <Tag color="#87d068">{items?.order_delivery_status}</Tag> : items?.order_delivery_status === "allocated" ? <Tag color="#0000FF">{items?.order_delivery_status}</Tag> : <Tag color="#ffa500">{items?.order_delivery_status}</Tag>}</div>,
            // actions: <div><Link to={`/cms/edit/${cms?.name}`}>Edit</Link></div>
        }
    });

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setPickupFetched([]);
        setFetchingData(true);
    }

    const onChangeToDate = (e) => setFilterToDate(e.target.value);

    const avail_drivers = [];

    drivers.forEach((driver) => {
        avail_drivers.push({
            key: driver.id,
            value: driver.id,
            label: <div>{driver.label ? driver.label : driver.driver_name}</div>,
        })
    });

    const items = [];
    deliveryDates.forEach((date) => {
        items.push({
            key: date.id,
            value: date.id,
            label: <div>{date.label ? date.label : <ReactMoment format="dddd, MMMM Do YYYY">{date.date}</ReactMoment>}</div>,
        })
    });

    return (
        <DashboardLayout>
            <>
                {deliveryDateDetails && formattedDate ?
                    <CategoryNav title={formattedDate} breadroute="inventory-pickup" />
                    :
                    <CategoryNav title="" breadroute="inventory-pickup" />
                }
            </>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white"> Pickup</MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <React.Fragment>
                                    <CCard>
                                        <div className="grid-flex" style={{ marginTop: "5px", background: "#f0f2f5", padding: "1rem", display: "flex", alignItems: "center" }}>
                                            <div className="grid-flex" style={{ marginLeft: "20px" }}>
                                                <p style={{ marginBottom: "0px" }}>Driver:</p>
                                                {params.driver && params.driver !== "driver" && params.driver !== "#" ?
                                                    <Select placeholder="Select Driver" defaultValue={params.driver} style={{ width: 220, }} options={avail_drivers} onChange={(values, selectedOption) => { selectDriver({ id: selectedOption.key }); }} /> :
                                                    <Select placeholder="Select Driver" style={{ width: 220, }} options={avail_drivers} onChange={(values, selectedOption) => { selectDriver({ id: selectedOption.key }); }} />
                                                }
                                            </div>
                                            {!fetchingDate &&
                                                <div className="grid-flex" style={{ marginLeft: "20px" }}>
                                                    <p style={{ marginBottom: "0px" }}>Delivery Date:</p>
                                                    {params.dateid && params.dateid !== "date" ?
                                                        <Select placeholder="Select Date" defaultValue={params.dateid} style={{ width: 220, }} options={items} onChange={(values, selectedOption) => { selectDeliveryDate({ id: selectedOption.key }); }} /> :
                                                        <Select placeholder="Select Date" style={{ width: 220, }} options={items} onChange={(values, selectedOption) => { selectDeliveryDate({ id: selectedOption.key }); }} />
                                                    }
                                                </div>
                                            }
                                            {/* <div className="grid-flex" style={{ marginLeft: "20px" }}>
                                                <p style={{ marginBottom: "0px" }}>Date</p>
                                                <input id="date" style={{ width: "100%", padding: "0px 11px", height: "30px", borderRadius: "6px", border: "1px solid #000" }} type="date" defaultValue={filterToDate} name="date" onChange={onChangeToDate} max={moment().format('YYYY-MM-DD')} />
                                            </div> */}
                                        </div>
                                        <CCardBody>
                                            {fetchingData ?
                                                <div>
                                                    <Spin indicator={antIcon} />
                                                </div> :
                                                <>
                                                    <div className="table-responsive">
                                                        <Table columns={columns} dataSource={data} pagination={false} bordered footer={null} />
                                                        <Pagination showSizeChanger current={page} total={total_items} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultPageSize={page_size} onChange={change_page} />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                    </div>
                                                </>
                                            }
                                        </CCardBody>
                                    </CCard>
                                </React.Fragment>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default InventoryPickup;
