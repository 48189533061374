/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, Modal, Pagination, Select, Spin, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { _add_location, _delete_location, _edit_location_status, _get_all_cities, _get_locations } from "common/axios_services";
import { _get_profile } from "common/axios_services";

function LocationInfo() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_categories, setTotal_categories] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [updateState, setUpdateState] = useState(0);
    const [allProducts, setAllProducts] = useState('location');
    const [allLocations, setAllLocations] = useState([]);
    const [fetchAllCities, setFetchCities] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addingLocation, setAddingLocation] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [error_msg, setError_msg] = useState("")
    const [updateCount, setUpdateCount] = useState(1)
    const [addLocations, setAddLocations] = useState(false)
    const [deleteLocations, setDeleteLocations] = useState(false)

    // Functions to handle
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    const fetchLocations = async () => {
        try {
            const uploadedLocations = await _get_locations({ page, page_size })
            console.log("all location", uploadedLocations)
            setFetchingData(false);
            setAllLocations(uploadedLocations.data.data)
            setTotal_categories(uploadedLocations.data.meta.total)
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const fetchAddedCities = async () => {
        try {
            const uploadedStates = await _get_all_cities()
            setFetchingData(false);
            setFetchCities(uploadedStates.data.data)
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addLocations")) {
                setAddLocations(true)
            } else {
                setAddLocations(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteLocations")) {
                setDeleteLocations(true)
            } else {
                setDeleteLocations(false)
            }
        } else {
            setDeleteLocations(false)
            setAddLocations(false)
        }
    }

    useEffect(() => {
        fetchLocations();
        fetchAddedCities();
        fetchUser()
    }, [page, page_size, allProducts, updateState, updateCount])

    const addLocation = async (values) => {
        setAddingLocation(true)
        try {
            const data = {
                city_id: values.city.key,
                location_name: values.location_name,
                location_no: values.location_no,
                Address: values.address,
                lga: values.lga,
                street: values.street,
                longitude: "1",
                latitude: "1",
            }
            const postLocation = await _add_location(data)
            setAddingLocation(false)
            openNotificationWithIcon('success', postLocation.data.message)
            setAllProducts('location')
        } catch (err) {
            setAddingLocation(false)
            if (err.response) {
                setError_msg(err.response.data.message)
            } else {
                setError_msg(err.message)
            }
        }
    };

    const deleteCategory = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    }

    const finaliseStateRemoval = async () => {
        setSpinnerLoading(true)
        try {
            const deleteLocaion = await _delete_location({ id: selectedLocationId })
            openNotificationWithIcon('success', deleteLocaion.data.message);
            setUpdateState(updateState + 1)
            setSpinnerLoading(false)
            setIsModalVisible(false)
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const changeStatus = async ({ value, id }) => {
        try {
            const editStatus = await _edit_location_status({ id, status: value })
            openNotificationWithIcon('success', editStatus.data.message)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    let data = allLocations.map((location, index) => {
        return {
            key: index,
            id: index + 1,
            name: location.location_name,
            no: location.location_no,
            lga: location.lga,
            street: location.street,
            address: location.Address,
            dis_status: <Form form={form} initialValues={{ display_status: location.status }}><Form.Item name="display_status" valuePropName="checked">{location.status ? <Button onClick={() => changeStatus({ value: false, id: location.id })}>Deactivate</Button> : <Button onClick={() => changeStatus({ value: true, id: location.id })}>Activate</Button>}</Form.Item></Form>,
            actions: <div>{deleteLocations && <form className="inline-form"
                onSubmit={e => { deleteCategory(e); setSelectedLocationId(location.id) }}>
                <input type="hidden" value={location.id} name="categoryId" />
                <Button type="link" htmlType="submit" className="inline-button">Delete</Button></form>}</div>
        }
    });

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'id',
            className: 'column-number',
            align: 'center',
        },
        {
            title: 'Location Name',
            dataIndex: 'name',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Location No',
            className: 'column-number',
            dataIndex: 'no',
            align: 'center',
        },
        {
            title: 'LGA',
            className: 'column-number',
            dataIndex: 'lga',
            align: 'center',
        },
        {
            title: 'Street',
            className: 'column-number',
            dataIndex: 'street',
            align: 'center',
        },
        {
            title: 'Address',
            className: 'column-number',
            dataIndex: 'address',
            align: 'center',
        },
        {
            title: 'Display Status',
            className: 'column-number',
            dataIndex: 'dis_status',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Delivery settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {allProducts === 'location' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div className="grid-flex">
                                                        List of Locations
                                                        <div>
                                                            {addLocations &&
                                                                <Button type="primary" onClick={() => setAllProducts('add-location')}>Add Locations</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {
                                                        fetchingData ?
                                                            <div>
                                                                <Spin indicator={antIcon} />
                                                            </div> :
                                                            <>
                                                                <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_categories} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} location(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                            </>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                        <Modal title="Delete Location" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                            <p>Are you sure you want to delete this Location?</p>
                                            <div>
                                                <button onClick={() => setIsModalVisible(false)} style={{ border: '1px solid #F0F0F0', borderRadius: 4, padding: '10px 25px', background: 'transparent', marginRight: 10, fontSize: 12, fontWeight: '600' }}
                                                >Cancel</button>
                                                <button onClick={() => finaliseStateRemoval()} style={{ border: '1px solid #FF4A00', borderRadius: 4, padding: '10px 25px', background: '#FF4A00', fontSize: 12, fontWeight: '600', color: '#fff' }}
                                                >Delete</button>
                                            </div>
                                        </Modal>
                                    </Spin>
                                }
                                {allProducts === 'add-location' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Add Locations
                                                    <div>
                                                        <Button type="primary" onClick={() => setAllProducts('location')}>View Locations</Button>
                                                    </div>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {error_msg && (<p style={{ color: "red" }}>{error_msg}</p>)}
                                                <Form form={form} onFinish={addLocation} layout="vertical">
                                                    <Form.Item name="city" label="City">
                                                        {fetchAllCities ?
                                                            <Select placeholder="Select City" labelInValue>
                                                                {fetchAllCities.map((city) => (
                                                                    <Select.Option key={city.id} title={city.country_code} value={city.state_code}>{city.city_name}</Select.Option>
                                                                ))}
                                                            </Select> :
                                                            <Select placeholder="Select State"></Select>
                                                        }
                                                    </Form.Item>
                                                    <Form.Item name="location_name" label="Location Name" >
                                                        <Input placeholder="Location Name" />
                                                    </Form.Item>
                                                    <Form.Item name="location_no" label="Location Number" >
                                                        <Input placeholder="Location Number" />
                                                    </Form.Item>
                                                    <Form.Item name="address" label="Address" >
                                                        <Input placeholder="Address" />
                                                    </Form.Item>
                                                    <Form.Item name="lga" label="LGA" >
                                                        <Input placeholder="LGA" />
                                                    </Form.Item>
                                                    <Form.Item name="street" label="Street" >
                                                        <Input placeholder="Street" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {addingLocation ?
                                                            <Button disabled loading type="primary">Submitting....</Button>
                                                            : <Button htmlType="submit" type="primary">Submit</Button>
                                                        }
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default LocationInfo;