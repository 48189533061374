/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBod, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Table, notification } from 'antd';
import { City, Country, State } from 'country-state-city';
import React, { useEffect, useState } from 'react';

import { _add_van_type, _delete_state, _delete_van_type, _get_profile, _get_van_types, } from "common/axios_services";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

function VehicleSettings() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_categories, setTotal_categories] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [updateState, setUpdateState] = useState(0);
    const [currentPage, setCurrentPage] = useState('vans');
    const [allVanTypes, setAllVanTypes] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addingVantype, setAddingVantype] = useState(false);
    const [selectedVan, setSelectedVan] = useState("");
    const [error_msg, setError_msg] = useState("")
    const [updateCount, setUpdateCount] = useState(1)
    const [addVanTypes, setAddVanTypes] = useState(false)
    const [deleteStates, setDeleteStates] = useState(false)
    const [editVanType, setEditVanType] = useState(false)
    const [search, setSearch] = useState("");

    // Functions to handle
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };
    const onSearch = (value, _e, info) => setSearch(value)
    const fetchVanTypes = async () => {
        try {
            const vanTypes = await _get_van_types({ page, page_size, to: "", from: "", search })
            setFetchingData(false);
            setAllVanTypes(vanTypes?.data?.data)
            setTotal_categories(vanTypes?.data?.meta?.total)
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addVanTypes")) {
                setAddVanTypes(true)
            } else {
                setAddVanTypes(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteVanTypes")) {
                setDeleteStates(true)
            } else {
                setDeleteStates(false)
            }
            if (user_profile.data.data.role.role_access.includes("edit-van-types")) {
                setEditVanType(true)
            } else {
                setEditVanType(false)
            }
        } else {
            setDeleteStates(false)
            setEditVanType(false)
            setAddVanTypes(false)
        }
    }
    useEffect(() => {
        fetchUser()
    }, [])
    useEffect(() => {
        fetchVanTypes()
    }, [page, page_size, currentPage, updateState, updateCount, search])

    const addVanType = async (values) => {
        setAddingVantype(true)
        try {
            const data = {
                name: values?.name,
                capacity: `${values?.capacity}`,
                unit: values?.unit,
                description: values?.description,
            }
            const add_van = await _add_van_type(data)
            setAddingVantype(false)
            openNotificationWithIcon('success', add_van?.data?.message)
            setCurrentPage('vans')
        } catch (err) {
            setAddingVantype(false)
            if (err.response) {
                setError_msg(err.response.data.message)
            } else {
                setError_msg(err.message)
            }
        }
    };

    const deleteCategory = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    }

    const finaliseVanRemoval = async () => {
        setSpinnerLoading(true)
        try {
            const delete_van = await _delete_van_type({ id: selectedVan?.id })
            openNotificationWithIcon('success', delete_van?.data?.message);
            setUpdateState(updateState + 1)
            setSpinnerLoading(false)
            setIsModalVisible(false)
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    let data = allVanTypes.map((van, index) => {
        return {
            key: index + 1,
            name: van?.name,
            unit: van?.unit,
            capacity: <NumericFormat thousandSeparator={true} displayType={'text'} value={van?.capacity} />,
            actions: <div>{editVanType && <Link to={`/van-types/edit/${van?.id}`}>Edit / </Link>} {deleteStates && <form className="inline-form"
                onSubmit={e => { deleteCategory(e); setSelectedVan(van) }}>
                <input type="hidden" value={van?.id} name="categoryId" />
                <Button type="link" htmlType="submit" className="inline-button">Delete</Button></form>}</div>
        }
    });
    const columns = [
        {
            title: 'S/N',
            dataIndex: 'key',
            className: 'column-number',
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Capacity',
            className: 'column-number',
            dataIndex: 'capacity',
            align: 'center',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            align: 'center',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Vehicle settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {currentPage === 'vans' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div>
                                                        <p>List of Van Types</p>
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <div className="grid-flex" style={{ marginLeft: "20px" }}>
                                                                <p style={{ marginBottom: "0px" }}>Search</p>
                                                                <Input.Search placeholder="Search" onSearch={onSearch} allowClear style={{ width: 270 }} />
                                                            </div>
                                                            <div>
                                                                {addVanTypes &&
                                                                    <Button type="primary" onClick={() => setCurrentPage('add-van')}>Add Van Types</Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {
                                                        fetchingData ?
                                                            <div>
                                                                <Spin indicator={antIcon} />
                                                            </div> :
                                                            <>
                                                                <Table columns={columns} dataSource={data} pagination={false} bordered />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_categories} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} van(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                            </>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                        <Modal title="Delete Van Type" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                            <p>Are you sure you want to delete this Van type <b>{selectedVan?.name}</b>?</p>
                                            <div>
                                                <button onClick={() => setIsModalVisible(false)} style={{ border: '1px solid #F0F0F0', borderRadius: 4, padding: '10px 25px', background: 'transparent', marginRight: 10, fontSize: 12, fontWeight: '600' }}
                                                >Cancel</button>
                                                <button onClick={() => finaliseVanRemoval()} style={{ border: '1px solid #FF4A00', borderRadius: 4, padding: '10px 25px', background: '#FF4A00', fontSize: 12, fontWeight: '600', color: '#fff' }}
                                                >Delete</button>
                                            </div>
                                        </Modal>
                                    </Spin>
                                }
                                {currentPage === 'add-van' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Add Van Type
                                                    <div>
                                                        <Button type="primary" onClick={() => setCurrentPage('states')}>View Van Types</Button>
                                                    </div>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {error_msg && (<p style={{ color: "red" }}>{error_msg}</p>)}
                                                <Form form={form} onFinish={addVanType} layout="vertical">
                                                    <Form.Item name="name" label="Name" >
                                                        <Input placeholder="Name" />
                                                    </Form.Item>
                                                    <Form.Item name="capacity" label="Capacity" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Capacity" />
                                                    </Form.Item>
                                                    <Form.Item name="unit" label="Unit" >
                                                        <Select placeholder="Select unit">
                                                            <Select.Option value="kg">KG</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="description" label="Description" >
                                                        <Input.TextArea rows={4} style={{ width: "100%" }} placeholder="Description" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {addingVantype ?
                                                            <Button disabled loading type="primary">Submitting....</Button>
                                                            : <Button htmlType="submit" type="primary">Submit</Button>
                                                        }
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default VehicleSettings;
