/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Pagination, Spin, Table } from "antd";
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Vendors() {
    const [userBox, setUserBox] = useState([]);
    const [page, setPage] = useState(1);
    const [total_users, setTotal_users] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [arrayToDisplay, setArrayToDisplay] = useState([]);


    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });

    useEffect(() => {
        axiosCall(`/vendor-profile?page=${page}&per_page=${page_size}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => {
                setAllUsersFetched(true);
                setUserBox(res.data.data);
                setTotal_users(res.data.meta.total);
                setArrayToDisplay(res.data.data);
            })
            .catch(err => {
                setAllUsersFetched(true);
                setUserBox([]);
            })
    }, [page, page_size])

    const data = [];
    arrayToDisplay.forEach((user, index) => {
        data.push({
            key: index,
            sn: index + 1,
            dateCreated: user.created_at.split('T')[0],
            refCode: user.user.ref_code,
            firstName: user.user.first_name,
            lastName: user.user.last_name,
            email: user.user.email,
            phoneNumber: user.user.phone_number,
            action: <Link to={`/people/users/${user.id}`}>View</Link>
        })
    });

    const columns = [
        {
            title: 'SN',
            dataIndex: 'sn',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Signup date',
            dataIndex: 'dateCreated',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Ref Code',
            dataIndex: 'refCode',
            render: text => <a>{text}</a>,
        },
        {
            title: 'First name',
            className: 'column-number',
            dataIndex: 'firstName',
            align: 'center',
            render: text => <a>{text}</a>
        },
        {
            title: 'Last name',
            className: 'column-number',
            dataIndex: 'lastName',
            align: 'center',
        },
        {
            title: 'Email',
            className: 'column-number',
            dataIndex: 'email',
            align: 'center',
        },
        {
            title: 'Phone number',
            className: 'column-number',
            dataIndex: 'phoneNumber',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center'
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setUserBox([]);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Vendors
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div className="table-responsive">
                                    {allUsersFetched ?
                                        <>
                                            <Table columns={columns} pagination={false} dataSource={data} bordered />
                                            <div style={{ marginTop: "2%" }}></div>
                                            <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} vendors`} defaultPageSize={page_size} onChange={change_page} />
                                            <div style={{ marginTop: "2%" }}></div>
                                        </>
                                        :
                                        <div className="">
                                            <Spin />
                                        </div>
                                    }
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Vendors;
