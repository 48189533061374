/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Button, Form, Input, Modal, Pagination, Select, Spin, Table, notification } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody } from "@coreui/react";
import { _create_inventory_received, _edit_inventory_received, _get_all_products, _get_inventory_recieved } from "common/axios_services";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

function InventoryRecieved() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_reports, setTotal_reports] = useState(0);
    const [createItem, setCreateItem] = useState(false);
    const [search, setSearch] = useState("");
    const [filterDate, setFilterDate] = useState("");
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [reportfetched, setReportFetched] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [allProducts, setAllProducts] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [filterToDate, setFilterToDate] = useState(moment().format('YYYY-MM-DD'));
    const [editRecieved, setEditRecieved] = useState(false);
    const [editInfo, setEditInfo] = useState(false);
    const [creatingInventoryReceived, setCreatingInventoryReceived] = useState(false);
    const jwt = localStorage.konnect_admin_token;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
        },
        {
            title: 'Supplier Name',
            dataIndex: 'supplier_name',
        },
        {
            title: 'GRN Num',
            dataIndex: 'grn_no',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };
    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }
    const onSearch = (value, _e, info) => setSearch(value)
    const onChangeDate = (e) => setFilterDate(e.target.value);
    const onChangeToDate = (e) => setFilterToDate(e.target.value);
    const getReceived = async () => {
        setFetchingData(true);
        try {
            const getRecives = await _get_inventory_recieved({ page, search, page_size, filterDate, filterToDate, item_id: "", status: "" })
            setReportFetched(getRecives.data.data);
            setFetchingData(false);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }
    const getAllProducts = async () => {
        setAllProducts(false);
        try {
            const getProducts = await _get_all_products({ page: '1', per_page: '10', search: "" })
            setAllProducts(getProducts.data.data);
        } catch (err) {
            setAllProducts(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }
    const createInventoryItem = async (values) => {
        setCreatingInventoryReceived(true)
        try {
            const data = {
                supplier_name: values?.supplier_name,
                grn_no: values?.grn_no,
                item_id: values?.item_name?.key,
                qty: values?.qty,
                comment: values?.comment
            }
            const create_inventory_recieved = await _create_inventory_received({ data })
            openNotificationWithIcon('success', create_inventory_recieved.data.message)
            setCreatingInventoryReceived(false)
            setUpdateCount(updateCount + 1)
            form.resetFields()
            setCreateItem(false)
        } catch (err) {
            setCreatingInventoryReceived(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const editInventoryItem = async (values) => {
        setCreatingInventoryReceived(true)
        try {
            const data = {
                supplier_name: values?.supplier_name,
                grn_no: values?.grn_no,
                item_id: values?.item_name?.key,
                qty: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(values?.qty)} />,
                comment: values?.comment
            }
            const edit_inventory_recieved = await _edit_inventory_received({ id: editInfo?.id, data })
            openNotificationWithIcon('success', edit_inventory_recieved.data.message)
            setCreatingInventoryReceived(false)
            setUpdateCount(updateCount + 1)
            form.resetFields()
            setEditRecieved(false)
        } catch (err) {
            setCreatingInventoryReceived(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    useEffect(() => {
        getReceived()
    }, [page, search, page_size, filterDate, updateCount])

    let data = reportfetched.map((report, index) => {
        return {
            sn: index + 1,
            item: report?.item_name,
            qty: report?.qty,
            supplier_name: report?.supplier_name,
            grn_no: report?.grn_no,
            actions: <div><Button type="link" onClick={() => { setEditRecieved(true); setEditInfo(report); getAllProducts() }}>Edit</Button></div>
        }
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">Received</MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <CCard>
                                            <div className="grid-flex" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1rem 1rem 0.5rem 1rem" }}>
                                                <h6 style={{ marginBottom: "0px" }}></h6>
                                                <Button onClick={() => { setCreateItem(true); getAllProducts() }}>Add Recived</Button>
                                            </div>
                                            <div className="grid-flex" style={{ marginTop: "5px", background: "#f0f2f5", padding: "1rem", display: "flex", alignItems: "center" }}>
                                                <div className="grid-flex">
                                                    <p style={{ marginBottom: "0px" }}>Date</p>
                                                    <input id="date" style={{ width: "100%", padding: "0px 11px", height: "30px", borderRadius: "6px", border: "1px solid #000" }} type="date" defaultValue={filterDate} name="date" onChange={onChangeDate} max={moment().format('YYYY-MM-DD')} />
                                                </div>
                                                <div className="grid-flex" style={{ marginLeft: "20px" }}>
                                                    <p style={{ marginBottom: "0px" }}>To</p>
                                                    <input id="date" style={{ width: "100%", padding: "0px 11px", height: "30px", borderRadius: "6px", border: "1px solid #000" }} type="date" defaultValue={filterToDate} name="date" onChange={onChangeToDate} max={moment().format('YYYY-MM-DD')} />
                                                </div>
                                                <div className="grid-flex" style={{ marginLeft: "20px" }}>
                                                    <p style={{ marginBottom: "0px" }}>Search</p>
                                                    <Input.Search placeholder="Search" onSearch={onSearch} allowClear style={{ width: 270 }} />
                                                </div>
                                            </div>
                                            <CCardBody>
                                                {fetchingData ?
                                                    <div>
                                                        <Spin indicator={antIcon} />
                                                    </div> :
                                                    <>
                                                        <div className="table-responsive">
                                                            <Table columns={columns} dataSource={data} pagination={false} bordered footer={null} />
                                                            <div style={{ marginTop: "2%" }}></div>
                                                            <Pagination showSizeChanger current={page} total={total_reports} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Reports`} defaultPageSize={page_size} onChange={change_page} />
                                                        </div>
                                                    </>
                                                }
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Modal centered={true} title="" visible={createItem} footer={null} onCancel={() => { setCreateItem(false); form.resetFields() }} className="pb-5 pt-5">
                <h5>Create Item</h5>
                <Form autoComplete="off" form={form} layout="vertical" onFinish={createInventoryItem}>
                    <Form.Item label="Item Name" name="item_name">
                        {allProducts ?
                            <Select placeholder="Select Item" labelInValue>
                                {allProducts.map((product) => (
                                    <Select.Option key={product.id} value={product.id}>{product.product_name}</Select.Option>
                                ))}
                            </Select> :
                            <Select placeholder="Select Item"></Select>
                        }
                    </Form.Item>
                    <Form.Item label="Supplier Name" name="supplier_name">
                        <Input placeholder="Supplier Name" />
                    </Form.Item>
                    <Form.Item label="GRN no" name="grn_no">
                        <Input placeholder="GRN no" />
                    </Form.Item>
                    <Form.Item label="Quantity" name="qty">
                        <Input placeholder="Quantity" />
                    </Form.Item>
                    <Form.Item label="Comment" name="comment">
                        <Input placeholder="Comment" />
                    </Form.Item>
                    {creatingInventoryReceived ?
                        <Button loading style={{ marginLeft: "auto" }}>Creating</Button> :
                        <Button htmlType='submit' style={{ marginLeft: "auto" }} type="primary">Create</Button>
                    }
                </Form>
            </Modal>
            <Modal centered={true} title="" visible={editRecieved} footer={null} onCancel={() => { setEditRecieved(false); form.resetFields() }} className="pb-5 pt-5">
                <h5>Edit {editInfo?.item_name}</h5>
                {allProducts ?
                    <Form autoComplete="off" form={form} layout="vertical" onFinish={editInventoryItem} initialValues={{
                        supplier_name: editInfo?.supplier_name,
                        grn_no: editInfo?.grn_no,
                        item_id: editInfo?.item_id,
                        qty: editInfo?.qty,
                        comment: editInfo?.comment
                    }}>
                        <Form.Item label="Item Name" name="item_name">
                            {allProducts ?
                                <Select placeholder="Select Item" labelInValue defaultValue={editInfo?.item_id} disabled>
                                    {allProducts.map((product) => (
                                        <Select.Option key={product.id} value={product.id}>{product.product_name}</Select.Option>
                                    ))}
                                </Select> :
                                <Select placeholder="Select Item"></Select>
                            }
                        </Form.Item>
                        <Form.Item label="Supplier Name" name="supplier_name">
                            <Input placeholder="Supplier Name" />
                        </Form.Item>
                        <Form.Item label="GRN no" name="grn_no">
                            <Input placeholder="GRN no" />
                        </Form.Item>
                        <Form.Item label="Quantity" name="qty">
                            <Input placeholder="Quantity" />
                        </Form.Item>
                        <Form.Item label="Comment" name="comment">
                            <Input placeholder="Comment" />
                        </Form.Item>
                        {creatingInventoryReceived ?
                            <Button loading style={{ marginLeft: "auto" }}>Updating</Button> :
                            <Button htmlType='submit' style={{ marginLeft: "auto" }} type="primary">Update</Button>
                        }
                    </Form> :
                    <Spin />
                }
            </Modal>
            <Footer />
        </DashboardLayout>
    );
}

export default InventoryRecieved;
