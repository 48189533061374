/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import NotificationItem from "examples/Items/NotificationItem";
import Breadcrumbs from "./Breadcrumbs";

// Custom styles for UserNavbar
import {
    navbar,
    navbarContainer,
    navbarIconButton,
    navbarMobileMenu,
    navbarRow,
} from "./styles";

// Material Dashboard 2 React context
import { Grid } from "@mui/material";
import { Button } from "antd";
import { _get_profile } from "common/axios_services";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import {
    setMiniSidenav,
    setTransparentNavbar,
    useMaterialUIController,
} from "context";

function UserNavbar({ absolute, light, isMini, title, breadroute }) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    // const route = useLocation().pathname.split("/").slice(1);

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /** 
         The event listener that's calling the handleTransparentNavbar function when 
         scrolling the window.
        */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    const [profileInfo, setProfileInfo] = useState(false)
    const getProfile = async () => {
        try {
            const get_profile = await _get_profile()
            setProfileInfo(get_profile.data.data)
        } catch (err) {
            setProfileInfo(false)
        }
    }

    useEffect(() => {
        getProfile()
    }, [])

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >
            {profileInfo &&
                <div style={{ width: 250 }}>
                    <div style={{ display: "flex", gap: "16px", padding: "1rem" }}>
                        <Grid item>
                            <MDAvatar src={profileInfo.image} alt="Profile Image" size="md" shadow="sm" />
                        </Grid>
                        <Grid item>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium" style={{ textTransform: "capitalize" }}>
                                    {profileInfo.first_name} {profileInfo.last_name}
                                </MDTypography>
                                <MDTypography variant="button" color="text" fontWeight="regular">
                                    {profileInfo.role.role_name}
                                </MDTypography>
                            </MDBox>
                        </Grid>
                    </div>
                    <MDBox p={2} mt="auto">
                        <Button style={{ fontWeight: "medium", fontSize: "18px", background: "#eee", height: "3rem", textAlign: "left", width: "100%" }} type="submit" onClick={() => navigate('/authentication/sign-in?logout=true')}>Logout</Button>
                    </MDBox>
                </div>
            }
        </Menu>
    );

    // Styles for the navbar icons
    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                    <Breadcrumbs icon="home" title={title} route={breadroute} light={light} />
                </MDBox>
                {isMini ? null : (
                    <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <MDBox pr={1}></MDBox>
                        <MDBox color={light ? "white" : "inherit"}>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon sx={iconsStyle} fontSize="medium">
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenMenu}
                            >
                                <Grid item>
                                    <MDAvatar src={profileInfo.image} alt="Profile Image" size="sm" shadow="sm" />
                                </Grid>
                            </IconButton>
                            {renderMenu()}
                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of UserNavbar
UserNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the UserNavbar
UserNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default UserNavbar;