/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Form, Input, Pagination, Spin, Table } from "antd";
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import { useEffect, useState } from "react";

function Vehicles() {
    const [form] = Form.useForm();
    const [vehicleBox, setVehicleBox] = useState([]);
    const [page, setPage] = useState(1);
    const [total_vehicles, setTotal_vehicles] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allvehiclesFetched, setAllVehiclesFetched] = useState(false);
    const [arrayToDisplay, setArrayToDisplay] = useState([]);


    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });

    useEffect(() => {
        axiosCall(`/vehicles?page=${page}&per_page=${page_size}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => {
                setAllVehiclesFetched(true);
                setVehicleBox(res.data.data);
                setTotal_vehicles(res.data.meta.total);
                setArrayToDisplay(res.data.data);
            })
            .catch(err => {
                setAllVehiclesFetched(true);
                setVehicleBox([]);
            })
    }, [page, page_size])

    const data = [];
    arrayToDisplay.forEach((vehicle, index) => {
        data.push({
            key: index,
            sn: index + 1,
            dateCreated: vehicle.created_at.split('T')[0],
            vehicle_brand: vehicle.brand,
            vehicle_type: vehicle.type,
            vehicle_number: vehicle.number,
            vehicle_owner: vehicle.owner,
            vehicle_color: vehicle.color,
            vehicle_weight: vehicle.weight,
        })
    });

    const columns = [
        {
            title: 'SN',
            dataIndex: 'sn',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Signup date',
            dataIndex: 'dateCreated',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Vehicle Brand',
            dataIndex: 'vehicle_brand',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Vehicle Type',
            className: 'column-number',
            dataIndex: 'vehicle_type',
            align: 'center',
            render: text => <a>{text}</a>
        },
        {
            title: 'Vehicle Number',
            className: 'column-number',
            dataIndex: 'vehicle_number',
            align: 'center',
        },
        {
            title: 'Vehicle Owner',
            className: 'column-number',
            dataIndex: 'vehicle_owner',
            align: 'center',
        },
        {
            title: 'Vehicle Color',
            className: 'column-number',
            dataIndex: 'vehicle_color',
            align: 'center',
        },
        {
            title: 'Vehicle Weight',
            className: 'column-number',
            dataIndex: 'vehicle_weight',
            align: 'center'
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllVehiclesFetched(false);
        setVehicleBox([]);
    }


    function dataFilter({ search }) {
        let userValue = vehicleBox.filter(value => {
            if (value.owner.toLowerCase().includes(search.toLowerCase())) {
                return value;
            }
        })
        setArrayToDisplay(userValue);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    vehicles
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div className="table-responsive">
                                    {allvehiclesFetched ?
                                        <>
                                            <div style={{ marginBottom: '5%' }}>
                                                <label>Filter Users data</label>
                                                <Form layout="vertical" form={form} initialValues={{
                                                    search: ""
                                                }} onValuesChange={dataFilter} style={{ maxWidth: 600 }}
                                                >
                                                    <Form.Item name="search" label="Search">
                                                        <Input placeholder="search by vehicle owner" />
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                            <Table columns={columns} pagination={false} dataSource={data} bordered />
                                            <div style={{ marginTop: "2%" }}></div>
                                            <Pagination showSizeChanger current={page} total={total_vehicles} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} vehicles`} defaultPageSize={page_size} onChange={change_page} />
                                            <div style={{ marginTop: "2%" }}></div>
                                        </>
                                        :
                                        <div className="">
                                            <Spin />
                                        </div>
                                    }
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Vehicles;
