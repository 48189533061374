/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CategoryNav from 'examples/Navbars/CategoryNav';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import { Visibility } from "@mui/icons-material";
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Table, Tag, notification } from "antd";
import { _change_inbound_instore_status, _get_all_inbound, _get_available_drivers, _get_dateby_id, _get_dates, _get_inbound_details, _get_inboundby_dateid, _get_orderby_dateid, _get_orders, _get_outbound_details, _get_outbound_items, _get_outbound_matrix, _get_outbound_percel, _get_profile, _update_inbound_data } from "common/axios_services";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import ReactMoment from 'react-moment';
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";

function Outbound() {
    const navigate = useNavigate()
    const params = useParams()
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_items, setTotal_items] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [fetchingDate, setFetchingDate] = useState(false);
    const [deliveryDates, setDeliveryDates] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    const [inboundInfo, setInboundInfo] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [editInbound, setEditInbound] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [fetchingDrivers, setFetchingDrivers] = useState(false);
    const [deliveryDateDetails, setDeliveryDateDetails] = useState(false);
    const [fetchingParcel, setFetchingParcel] = useState(false);
    const [parcels, setParcels] = useState([]);
    const [matrix, setMatrix] = useState(false);
    const [outboundInfo, setOutboundInfo] = useState(false);
    const [orderReview, setOrderReview] = useState(false);
    const [formattedDate, setFormattedDate] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };
    const getDrivers = async () => {
        setFetchingDrivers(true)
        try {
            const driver = await _get_available_drivers({ page: 1, page_size: 10 })
            setFetchingDrivers(false)
            if (driver.data.data === null) {
                setDrivers([])
            } else {
                const defaultOption = { value: 'driver', key: 'driver', id: "driver", label: 'None' };
                const optionsWithDefault = [defaultOption, ...driver.data.data];
                setDrivers(optionsWithDefault)
            }
        } catch (err) {
            setFetchingDrivers(false)
            setDrivers([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchDeliveryDates = async () => {
        setFetchingDate(true)
        try {
            const delivery_dates = await _get_dates({ page: 1, page_size: 10 })
            setFetchingDate(false)
            const defaultOption = { value: 'date', key: 'date', id: "date", label: 'None' };
            const optionsWithDefault = [defaultOption, ...delivery_dates.data.data];
            setDeliveryDates(optionsWithDefault)
        } catch (err) {
            setFetchingDate(false)
            setDeliveryDates([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getDate = async ({ id }) => {
        try {
            const delivery_date = await _get_dateby_id({ id })
            setDeliveryDateDetails(delivery_date.data.data)
            const time = moment(delivery_date.data.data.date);
            setFormattedDate(time.format('MMMM Do YYYY'))
        } catch (err) {
            setDeliveryDateDetails(false)
            setFormattedDate(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getOutboundDetails = async ({ id }) => {
        try {
            const outboundInfo = await _get_outbound_details({ id })
            setDetailsModal(true)
            setOutboundInfo(outboundInfo.data.data)
        } catch (err) {
            setDetailsModal(false)
            setOutboundInfo(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchOutboundMatrix = async ({ date, driver }) => {
        try {
            const matrices = await _get_outbound_matrix({ date, driver })
            setMatrix(matrices.data.data)
        } catch (err) {
            setMatrix(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchAllParcel = async ({ date, driver }) => {
        setFetchingParcel(true)
        try {
            const parcel = await _get_outbound_percel({ date, driver, page, page_size })
            setFetchingParcel(false)
            setParcels(parcel.data.data)
            setTotal_items(parcel?.data?.meta?.total);
        } catch (err) {
            setTotal_items(0);
            setFetchingParcel(false)
            setParcels([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    useEffect(() => {
        fetchDeliveryDates()
        getDrivers()
        if (params.dateid && params.dateid !== "date") {
            if (params.driver && params.driver !== "driver") {
                fetchAllParcel({ date: params.dateid, driver: params.driver })
                fetchOutboundMatrix({ date: params.dateid, driver: params.driver })
                getDate({ id: params.dateid })
            } else {
                fetchAllParcel({ date: params.dateid, driver: "" })
                fetchOutboundMatrix({ date: params.dateid, driver: "" })
                getDate({ id: params.dateid })
            }
        } else if (params.driver && params.driver !== "driver") {
            fetchAllParcel({ date: "", driver: params.driver })
            fetchOutboundMatrix({ date: "", driver: params.driver })
            setDeliveryDateDetails(false)
        } else {
            fetchAllParcel({ date: "", driver: "" })
            fetchOutboundMatrix({ date: "", driver: "" })
            setDeliveryDateDetails(false)
        }
    }, [page, page_size, updateCount])

    const selectDeliveryDate = ({ id }) => {
        setUpdateCount(updateCount + 1)
        if (params.driver && params.driver !== "driver") {
            navigate(`/outbound/${id}/${params.driver}`)
        } else {
            navigate(`/outbound/${id}/driver`)
        }
    }
    const selectDriver = ({ id }) => {
        if (params.dateid && params.dateid !== "date") {
            navigate(`/outbound/${params.dateid}/${id}`)
        } else {
            navigate(`/outbound/date/${id}`)
        }
        setUpdateCount(updateCount + 1)
    }
    const editInboundInfo = async (values) => {
        try {
            const data = {
                status: "inprogress",
                qty_supplied: `${values.qty_supplied}`,
                comment: `${values.comment}`
            }
            const editInboundData = await _update_inbound_data({ id: inboundInfo.id, data })
            openNotificationWithIcon('success', editInboundData.data.message)
            setEditInbound(false)
            setUpdateCount(updateCount + 1)
            form.resetFields()
        } catch (err) {
            setEditInbound(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const items = [];
    deliveryDates.forEach((date) => {
        items.push({
            key: date.id,
            value: date.id,
            label: <div>{date.label ? date.label : <ReactMoment format="dddd, MMMM Do YYYY">{date.date}</ReactMoment>}</div>,
        })
    });
    const avail_drivers = [];
    drivers.forEach((driver) => {
        avail_drivers.push({
            key: driver.id,
            value: driver.id,
            label: <div>{driver.label ? driver.label : driver.username}</div>,
        })
    });
    const parcelsData = parcels.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            buyer_name: order?.buyer_name,
            item: <NumericFormat thousandSeparator={true} displayType={'text'} value={+order?.total_items} />,
            units: <NumericFormat thousandSeparator={true} displayType={'text'} value={+order?.total_qty} />,
            status: <div style={{ textTransform: "capitalize" }}>{order?.delivery_status === "delivered" ? <Tag color="#87d068">{order?.delivery_status}</Tag> : order?.delivery_status === "allocated" ? <Tag color="#0000FF">{order?.delivery_status}</Tag> : <Tag color="#ffa500">{order?.delivery_status}</Tag>}</div>,
            kg: <NumericFormat thousandSeparator={true} displayType={'text'} value={order?.total_weight} />,
            // action: <>
            //     <span style={{ marginRight: "5px", marginBottom: "5px" }}>
            //         <Visibility style={{ cursor: "pointer" }} onClick={() => { getOutboundDetails({ id: order?.id }) }} />
            //     </span>
            // </>,
        }
    });
    const buyersColumns = [
        {
            title: 'SN',
            className: 'column-number',
            dataIndex: 'sn',
            align: 'left',
        },
        {
            title: 'Name Of Buyer',
            className: 'column-number',
            dataIndex: 'buyer_name',
            align: 'center',
        },
        {
            title: 'Item',
            className: 'column-number',
            dataIndex: 'item',
            align: 'center',
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'units',
            align: 'center',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        },
        // {
        //     title: 'Action',
        //     className: 'column-number',
        //     dataIndex: 'action',
        //     align: 'center',
        // },
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setUserBox([]);
    }

    return (
        <DashboardLayout>
            <>
                {deliveryDateDetails && formattedDate ?
                    <CategoryNav title={formattedDate} breadroute="outbound" />
                    :
                    <CategoryNav title="" breadroute="outbound" />
                }
            </>
            {fetchingDate || fetchingDrivers ?
                <></>
                :
                <div style={{ display: "flex", justifyContent: "right", gap: "20px", flexWrap: "wrap", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <span>Driver:</span>
                        {params.driver && params.driver !== "#" && params.driver !== "#" ?
                            <Select placeholder="Select Driver" defaultValue={params.driver} style={{ width: 220, }} options={avail_drivers} onChange={(values, selectedOption) => { selectDriver({ id: selectedOption.key }); }} /> :
                            <Select placeholder="Select Driver" style={{ width: 220, }} options={avail_drivers} onChange={(values, selectedOption) => { selectDriver({ id: selectedOption.key }); }} />
                        }
                    </div>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <span>Delivery Date:</span>
                        {params.dateid && params.dateid !== "date" ?
                            <Select placeholder="Select Date" defaultValue={params.dateid} style={{ width: 220, }} options={items} onChange={(values, selectedOption) => { selectDeliveryDate({ id: selectedOption.key }); }} /> :
                            <Select placeholder="Select Date" style={{ width: 220, }} options={items} onChange={(values, selectedOption) => { selectDeliveryDate({ id: selectedOption.key }); }} />
                        }
                    </div>
                    <Button>Export</Button>
                </div>
            }
            {matrix ?
                <MDBox py={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard color="dark" icon="weekend" title="Delivered" count={Number(matrix.total_items)} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard icon="leaderboard" title="Pending" count={Number(matrix.total_qty)} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard color="success" icon="store" title="Return" count={Number(matrix.total_weight)} />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox> :
                <MDBox py={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard color="dark" icon="weekend" title="Delivered" count={0} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard icon="leaderboard" title="Pending" count={0} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard color="success" icon="store" title="Return" count={0} />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Outbound
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <Spin tip="Loading..." spinning={fetchingParcel}>
                                    <div className="table-responsive">
                                        {!fetchingParcel &&
                                            <>
                                                <Table columns={buyersColumns} pagination={false} dataSource={parcelsData} bordered />
                                                <div style={{ marginTop: "2%" }}></div>
                                                <Pagination showSizeChanger current={page} total={total_items} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} item(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                <div style={{ marginTop: "2%" }}></div>
                                            </>
                                        }
                                    </div>
                                    <div style={{ marginTop: "5%" }}></div>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Modal centered={true} title="" visible={detailsModal} footer={null} onCancel={() => { setDetailsModal(false); setOutboundInfo(false) }} className="pb-5 pt-5">
                {outboundInfo ?
                    <>
                        <div style={{ display: "flex", flexDirection: "column", }}>
                            <h5 style={{ marginTop: "30px", marginBottom: "5px", textTransform: "capitalize" }}>{outboundInfo.username}</h5>
                            <p>{outboundInfo.order_no}</p>
                            {outboundInfo.items.map((item, index) => (
                                <div key={index} style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px" }}>
                                    <img src={item.product_image} style={{ width: "100px", heigh: "100px" }} />
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Product name</span>
                                        <span>{item.product_name}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Product weight (kg)</span>
                                        <span>{item.total_weight}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Quantity Ordered</span>
                                        <span>{item.quantity}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Quantity Delivered</span>
                                        <span>{item.delivery_qty}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Delivery Status</span>
                                        <span><div style={{ textTransform: "capitalize" }}>{item.delivery_status === "delivered" ? <Tag color="#87d068">{item.delivery_status}</Tag> : item.delivery_status === "allocated" ? <Tag color="#0000FF">{item.delivery_status}</Tag> : <Tag color="#ffa500">{item.delivery_status}</Tag>}</div>,</span>
                                    </div>
                                    <hr style={{ margin: "0.5rem" }} />
                                </div>
                            ))}
                            <span>
                                <Button onClick={() => { setDetailsModal(false); setInboundInfo(false) }}>Close</Button>
                            </span>
                        </div>
                    </>
                    :
                    <Spin></Spin>
                }
            </Modal>
            <Modal centered={true} title="" visible={orderReview} footer={null} onCancel={() => { setOrderReview(false) }} className="pb-5 pt-5">
                {orderReview ?
                    <>
                        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <h5 style={{ marginTop: "30px" }}>Item Info</h5>
                            <img src={orderReview.product_image} style={{ width: "100px", heigh: "100px" }} />
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Product name</span>
                                <span>{orderReview.product_name}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Product weight (kg)</span>
                                <span>{orderReview.total_weight}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Ordered Quantity</span>
                                <span>{orderReview.quantity}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Delivered Quantity</span>
                                <span>{orderReview.delivery_qty}</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Delivery Status</span>
                                <span><div style={{ textTransform: "capitalize" }}>{orderReview.delivery_status === "delivered" ? <Tag color="#87d068">{orderReview.delivery_status}</Tag> : orderReview.delivery_status === "allocated" ? <Tag color="#0000FF">{orderReview.delivery_status}</Tag> : <Tag color="#ffa500">{orderReview.delivery_status}</Tag>}</div>,</span>
                            </div>
                            <span>
                                <Button onClick={() => { setOrderReview(false) }}>Close</Button>
                            </span>
                        </div>
                    </>
                    :
                    <Spin></Spin>
                }
            </Modal>
            <Modal centered={true} title="" visible={editInbound} footer={null} onCancel={() => { setEditInbound(false) }} className="pb-5 pt-5">
                {inboundInfo ?
                    <>
                        <h5>Edit</h5>
                        <Form autoComplete="off" form={form} layout="vertical"
                            initialValues={{
                                id: `${inboundInfo.id}`,
                                product_name: `${inboundInfo.product_name}`,
                                quantity_supplied: "",
                                comment: ""
                            }}
                            onFinish={editInboundInfo}>
                            <Form.Item label="Product Name" name="product_name">
                                <Input disabled placeholder="Product Name" />
                            </Form.Item>
                            <Form.Item label="Quantity Supplied" name="qty_supplied">
                                <InputNumber placeholder="Quantity Supplied" style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item label="Comment" name="comment">
                                <Input placeholder="Comment" />
                            </Form.Item>
                            <Button htmlType='submit'>Update</Button>
                        </Form>
                    </> :
                    <div>
                        <Spin />
                    </div>
                }
            </Modal>
            <Footer />
        </DashboardLayout >
    );
}

export default Outbound;