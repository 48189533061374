/* eslint-disable */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBod, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Switch, Table, Upload, message, notification } from 'antd';
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import CategoryNav from 'examples/Navbars/CategoryNav';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const EditProducts = () => {
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState(null);
    const [imageToSend, setImageToSend] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ProductsBox, setProductsBox] = useState({});
    const [subcat, setSubcat] = useState([]);
    const [categoryErrorMessage, setCategoryErrorMessage] = useState('');
    const navigate = useNavigate()
    const jwt = localStorage.konnect_admin_token;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const { TextArea } = Input;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ message: '', placement: 'bottomRight', description: message, });
    };

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });
    let prod_id = window.location.href.split('/')[window.location.href.split('/').length - 1];
    useEffect(() => {
        axiosCall(`/products/${prod_id}`, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            setProductsBox(res.data.data)
            setImageUrl(res.data.data.product_image)
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })
        axiosCall(`/sub-category?page=${1}&per_page=${100}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                let subcat = [];
                res.data.data.forEach(freshData => {
                    let storeData = {
                        subcat: freshData,
                        subCategories: 0
                    }
                    subcat.push(storeData);
                })
                setSubcat(subcat);
            })
            .catch(err => {
                openNotificationWithIcon('error', err.response.data.message);
            })
    }, [])

    const { Option } = Select;
    let sub_category = subcat.map((sub, index) => {
        return {
            key: index,
            id: sub.subcat.id,
            name: sub.subcat.subcat_name
        }
    })

    const updateProduct = (values) => {
        const data = {
            subcat_id: `${values.subcat_id}`,
            product_image: `${values.product_image}`,
            product_name: `${values.product_name}`,
            product_code: `${values.product_code}`,
            display_status: values.display_status,
            available_status: values.available_status,
            discount_rate: `${values.discount_rate}`,
            description: `${values.description}`,
            market_price: `${values.market_price}`,
            old_price: `${values.old_price}`,
            konnect_price: `${values.konnect_price}`,
            sellers_price: `${values.sellers_price}`,
            weight: `${values.weight}`,
        }
        axiosCall.patch(`/products/${prod_id}`, data, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            navigate('/products')
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }
    const handleChange = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            setImageToSend(reader.result);
            form.setFieldsValue({ product_image: reader.result });
        });
    }
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <DashboardLayout>
            <CategoryNav title="Edit Product" breadroute="products" />
            <MDBox pt={6} pb={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Product
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3} px={2}>
                                {(ProductsBox.product_name) ?
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Product
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {
                                                    categoryErrorMessage.length ? <p className="error-message">{categoryErrorMessage}</p> : ''
                                                }
                                                <Form form={form} onFinish={updateProduct} initialValues={{
                                                    subcat_id: ProductsBox.subcat_id,
                                                    product_image: ProductsBox.product_image,
                                                    product_name: ProductsBox.product_name,
                                                    product_code: ProductsBox.product_code,
                                                    display_status: ProductsBox.display_status,
                                                    available_status: ProductsBox.available_status,
                                                    discount_rate: ProductsBox.discount_rate,
                                                    description: ProductsBox.description ? ProductsBox.description : '',
                                                    market_price: ProductsBox.market_price,
                                                    old_price: ProductsBox.old_price,
                                                    konnect_price: ProductsBox.konnect_price,
                                                    sellers_price: ProductsBox.sellers_price,
                                                    weight: `${ProductsBox.weight}`,
                                                }} layout="vertical">
                                                    <Form.Item name="product_image" label="Product Image" >
                                                        <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={handleChange} >
                                                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: "120px", height: "120px" }} className="adminCourseUpload" /> : uploadButton}
                                                        </Upload>
                                                    </Form.Item>
                                                    <Form.Item name="subcat_id" label="Choose Sub-Category" >
                                                        <Select>
                                                            {sub_category.map((title, index) => (
                                                                <Select.Option key={index} value={title.id}>{title.name}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="product_name" label="Product Name" >
                                                        <Input placeholder="Product Name" />
                                                    </Form.Item>
                                                    <Form.Item name="product_code" label="Product Code" >
                                                        <Input placeholder="Product Code" />
                                                    </Form.Item>
                                                    <Form.Item name="market_price" label="Market Price" >
                                                        <Input placeholder="Market Price" />
                                                    </Form.Item>
                                                    <Form.Item name="konnect_price" label="Konnect Price" >
                                                        <Input placeholder="Konnect Price" />
                                                    </Form.Item>
                                                    <Form.Item name="old_price" label="old Price" >
                                                        <Input placeholder="Old Price" />
                                                    </Form.Item>
                                                    <Form.Item name="sellers_price" label="Sellers Price" >
                                                        <Input placeholder="Sellers Price" />
                                                    </Form.Item>
                                                    <Form.Item name="weight" label="Product Weight" >
                                                        <Input placeholder="Product Weight" />
                                                    </Form.Item>
                                                    <Form.Item name="discount_rate" label="Discount Rate ( % )" >
                                                        <Input placeholder="Discount Rate" />
                                                    </Form.Item>
                                                    <Form.Item name="display_status" label="Display Status" >
                                                        <Select>
                                                            <Select.Option value={true}>Display</Select.Option>
                                                            <Select.Option value={false}>Hide</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="available_status" label="Available Status" >
                                                        <Select>
                                                            <Select.Option value={true}>Available</Select.Option>
                                                            <Select.Option value={false}>Not Available</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="description" label="Product Description" >
                                                        <TextArea rows={4} placeholder="Product Description" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button htmlType="submit" type="primary">Submit</Button>
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Product
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <Spin indicator={antIcon} />
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout >
    )
}

export default EditProducts;