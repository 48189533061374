/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Button, Form, Input, InputNumber, notification, Select } from "antd";
import { _add_merchants_settings, _edit_wise_status, _get_merchants_settings, _get_profile, _get_roles_byusertype } from "common/axios_services";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function AddMerchant() {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [categoryErrorMessage, setCategoryErrorMessage] = useState('');

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    const add_Merchants = async (values) => {
        const data = {
            category: values.category,
            name: values.name,
            institution: values.institution,
            description: values.description,
            users_no: `${values.users_no}`,
            logo: "",
        }
        try {
            const add_new_merchant = await _add_merchants_settings(data)
            openNotificationWithIcon('success', add_new_merchant.data.message)
            navigate("/merchant")
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    window.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Create New Merchant
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {/* {displaySwitch === "view" ?
                                        <div>
                                            <label>Filter Users data</label>
                                            <Form layout="vertical" form={form} initialValues={{
                                                search: ""
                                            }} onValuesChange={dataFilter} style={{ maxWidth: 600, minWidth: 400 }}
                                            >
                                                <Form.Item name="search">
                                                    <Input placeholder="search by first name" />
                                                </Form.Item>
                                            </Form>
                                        </div> :
                                        <div></div>
                                    } */}
                                    {/* {displaySwitch === "view" ?
                                        <Button onClick={() => { setDisplaySwitch("create") }}>Create User</Button>
                                        :
                                        <Button onClick={() => { setDisplaySwitch("view") }}>View Users</Button>
                                    } */}
                                </div>
                                {categoryErrorMessage ? <p className="error-message">{categoryErrorMessage}</p> : ''}
                                <Form form={form} onFinish={add_Merchants} initialValues={{
                                    category: "",
                                    name: "",
                                    institution: "",
                                    description: "",
                                    users_no: 0,
                                    logo: "",
                                }} layout="vertical">
                                    <Form.Item name="category" label="Category" >
                                        <Select placeholder="Category">
                                            <Select.Option value="general">General</Select.Option>
                                            <Select.Option value="logistics">Logistics</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="name" label="Name" >
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                    <Form.Item name="institution" label="Institution" >
                                        <Input style={{ width: "100%" }} placeholder="Institution" />
                                    </Form.Item>
                                    <Form.Item name="description" label="Description" >
                                        <Input style={{ width: "100%" }} placeholder="Description" />
                                    </Form.Item>
                                    <Form.Item name="users_no" label="Users Number" >
                                        <InputNumber style={{ width: "100%" }} placeholder="Users Number" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                    </Form.Item>
                                </Form>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddMerchant;