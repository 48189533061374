/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CategoryNav from 'examples/Navbars/CategoryNav';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Pagination, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { _get_all_payments, _get_user_payments } from "common/axios_services";
import { NumericFormat } from "react-number-format";
import { Link, useParams } from "react-router-dom";

function UserPayments() {
    const params = useParams()
    const [page, setPage] = useState(1);
    const [total_wallettrans, setTotal_wallettrans] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [allPayments, setAllPayments] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);

    const jwt = localStorage.konnect_admin_token;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };


    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const getPayments = async () => {
        try {
            const fetchUserPayments = await _get_user_payments({ page, page_size, user_id: params.id })
            setSpinnerLoading(false)
            setAllPayments(fetchUserPayments.data.data);
            setTotal_wallettrans(fetchUserPayments.data.meta.total)
        } catch (err) {
            setSpinnerLoading(false)
            setFetchingData(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    window.location.reload()
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }
    useEffect(() => {
        getPayments()
    }, [page, page_size])

    // Functions to handle
    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    let user_data = allPayments.map((trans, index) => {
        return {
            key: index,
            name: <span className="d-flex flex-column"><span style={{ textTransform:"capitalize" }}>{trans.user.first_name + ' ' + trans.user.last_name}</span><p style={{ color: "#6e7a75" }}>{trans.user.email}</p></span>,
            date: trans.created_at.split('T')[0],
            transRef: trans.referense_no,
            accType: trans.account_type,
            service: trans.service,
            amount: trans.amount,
            paymentmethod: trans.payment_method,
            paymenttype: <div style={{ textTransform: "capitalize" }}>{trans.payment_type === "credit" ? <Tag color="green">{trans.payment_type}</Tag> : <Tag color="red">{trans.payment_type}</Tag>}</div>,
            status: <div style={{ textTransform: "capitalize" }}>{trans.status === "success" ? <Tag color="#87d068">{trans.status}</Tag> : trans.status === "cancel" ? <Tag color="#cf1322">{trans.status}</Tag> : <Tag color="#ffa500">{trans.status}</Tag>}</div>,
        }
    });
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Trans Ref',
            dataIndex: 'transRef',
        },
        {
            title: 'Account type',
            dataIndex: 'accType',
        },
        {
            title: 'Service',
            className: 'column-number',
            dataIndex: 'service',
            align: 'center',
        },
        {
            title: 'Amount (NGN)',
            className: 'column-number',
            dataIndex: 'amount',
            align: 'center',
        },
        {
            title: 'Payment Method',
            className: 'column-number',
            dataIndex: 'paymentmethod',
            align: 'center',
        },
        {
            title: 'Payment type',
            className: 'column-number',
            dataIndex: 'paymenttype',
            align: 'center',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        }
    ];

    return (
        <DashboardLayout>
            <CategoryNav title="User" breadroute="payments" />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Payments
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <>
                                            <div className="table-responsive">
                                                <Table columns={columns} pagination={false} dataSource={user_data} bordered />
                                                <div style={{ marginTop: "2%" }}></div>
                                                <Pagination showSizeChanger current={page} total={total_wallettrans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} transactions`} defaultPageSize={page_size} onChange={change_page} />
                                            </div>
                                        </>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default UserPayments;