/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fetch: false,
    dataFetched: false
};

export const profileSlice = createSlice({
    name: "profileInfo",
    initialState,
    reducers: {
        fetchData: (state, action) => { state.fetch = action.payload },
        profileData: (state, action) => { state.dataFetched = action.payload },
    },
});

export const { fetchData, profileData } = profileSlice.actions;

export default profileSlice.reducer;