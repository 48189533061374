/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Pagination, Row, Select, Spin, Steps, Table, Tag, Upload, notification } from "antd";
import { _add_merchants_user, _approve_merchant, _change_merchant_status, _get_merchant_user, _get_merchants_settings, _get_merchants_users, _get_profile, _get_roles_byusertype, _update_merchant } from "common/axios_services";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function AddMerchantUser() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [page_size, setPage_size] = useState(10);
    const [creatingUser, setCreatingUser] = useState(false);
    const [step, setStep] = useState(0);
    const [personalInfo, setPersonalInfo] = useState({});
    const [businessInfo, setBusinessInfo] = useState({});
    const [updateCount, setUpdateCount] = useState(0);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [idImageUrl, setIdImageUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [merchantsList, setMerchantsList] = useState([]);
    const navigate = useNavigate()

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    const fetchMerchants = async () => {
        try {
            const fetch_merchant = await _get_merchants_settings({ page: "1", page_size: "10", search: "", category: "" })
            setMerchantsList(fetch_merchant.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const fetchMerchantsRoles = async () => {
        try {
            const fetch_role = await _get_roles_byusertype({ type: "merchant" })
            setRoles(fetch_role.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }

    const handleChange = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setIdImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            form.setFieldsValue({ id_card_image: reader.result });
        });
    }

    const profileChange = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setProfileImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            form.setFieldsValue({ image: reader.result });
        });
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const finishPersonal = (values) => {
        setStep(1)
        setPersonalInfo(values)
    }
    const finishBusiness = (values) => {
        setStep(2)
        setBusinessInfo(values)
    }

    const finishSecurity = async (values) => {
        const data = {
            role_id: businessInfo.role_id,
            merchant_id: businessInfo.merchant_id,
            id_card_no: `${businessInfo.id_card_no}`,
            id_card_image: businessInfo.id_card_image,
            about: businessInfo.about,
            possition: businessInfo.possition,
            location: businessInfo.location,
            first_name: personalInfo.first_name,
            last_name: personalInfo.last_name,
            email: personalInfo.email,
            image: personalInfo.image,
            phone_number: `${personalInfo.phone_number}`,
            gender: personalInfo.gender,
            password: values.password,
            home_address: personalInfo.home_address,
            state_of_residence: personalInfo.state_of_residence,
            country: personalInfo.country,
            lga: personalInfo.lga,
            nin: `${personalInfo.nin}`
        }
        setCreatingUser(true);
        try {
            const create_user = await _add_merchants_user(data)
            navigate('/merchants')
            openNotificationWithIcon('success', create_user.data.message);
            setUpdateCount(updateCount + 1)
            setCreatingUser(false);
        } catch (err) {
            setCreatingUser(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        fetchMerchants()
        fetchMerchantsRoles()
    }, [page, page_size])


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Create New Merchant User
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {/* {displaySwitch === "view" ?
                                        <div>
                                            <label>Filter Users data</label>
                                            <Form layout="vertical" form={form} initialValues={{
                                                search: ""
                                            }} onValuesChange={dataFilter} style={{ maxWidth: 600, minWidth: 400 }}
                                            >
                                                <Form.Item name="search">
                                                    <Input placeholder="search by first name" />
                                                </Form.Item>
                                            </Form>
                                        </div> :
                                        <div></div>
                                    } */}
                                    {/* {displaySwitch === "view" ?
                                        <Button onClick={() => { setDisplaySwitch("create") }}>Create User</Button>
                                        :
                                        <Button onClick={() => { setDisplaySwitch("view") }}>View Users</Button>
                                    } */}
                                </div>
                                <>
                                    <Steps direction="horizontal" className='my-2' size="small" current={step} items={[
                                        {
                                            title: 'Personal',
                                        },
                                        {
                                            title: 'Business',
                                        },
                                        {
                                            title: 'Security',
                                        }
                                    ]} />
                                    {step === 0 &&
                                        <Form layout="vertical" form={form} onFinish={finishPersonal} initialValues={{
                                            first_name: "",
                                            last_name: "",
                                            email: "",
                                            image: "",
                                            phone_number: "",
                                            gender: "",
                                            home_address: "",
                                            state_of_residence: "",
                                            country: "",
                                            lga: "",
                                            nin: ""
                                        }}>
                                            <Form.Item name="image" label="Profile Image" >
                                                <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={profileChange} >
                                                    {profileImageUrl ? <img src={profileImageUrl} alt="avatar" style={{ width: "130px" }} /> : uploadButton}
                                                </Upload>
                                            </Form.Item>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="first_name" label="First Name">
                                                        <Input placeholder="First Name" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="last_name" label="Last Name">
                                                        <Input placeholder="Last Name" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="email" label="Email">
                                                        <Input placeholder="Email" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="phone_number" label="Phone Number">
                                                        <InputNumber style={{ width: "100%" }} placeholder="Phone Number" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="gender" label="Gender">
                                                        <Select placeholder="Select Gender">
                                                            <Select.Option value="male">Male</Select.Option>
                                                            <Select.Option value="female">Female</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="home_address" label="Home Address">
                                                        <Input placeholder="Home Address" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="country" label="Country">
                                                        <Input placeholder="Country" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="state_of_residence" label="State Of Residence">
                                                        <Input placeholder="State Of Residence" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="lga" label="LGA">
                                                        <Input style={{ width: "100%" }} placeholder="LGA" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="nin" label="NIN">
                                                        <InputNumber style={{ width: "100%" }} placeholder="Nin" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div></div>
                                                <Form.Item>
                                                    {creatingUser ? (
                                                        <Button type="primary" loading>Loading</Button>
                                                    ) :
                                                        <Button type="primary" htmlType="submit">Proceed</Button>
                                                    }
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    }
                                    {step === 1 &&
                                        <Form layout="vertical" form={form} onFinish={finishBusiness} initialValues={{
                                            role_id: "",
                                            merchant_id: "",
                                            id_card_no: "",
                                            id_card_image: "",
                                            about: "",
                                            possition: "",
                                            location: "",
                                        }}>
                                            <Form.Item name="id_card_image" label="ID Card Image" >
                                                <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={handleChange} >
                                                    {idImageUrl ? <img src={idImageUrl} alt="avatar" style={{ width: "130px" }} /> : uploadButton}
                                                </Upload>
                                            </Form.Item>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="id_card_no" label="Id Card Number">
                                                        <InputNumber style={{ width: "100%" }} placeholder="Id Card Number" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="location" label="Office Address">
                                                        <Input placeholder="Office Address" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="merchant_id" label="Merchant">
                                                        <Select placeholder="Select Merchant">
                                                            {merchantsList.map((merchant, index) => (
                                                                <Select.Option key={index} value={merchant.id}>{merchant.name}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="role_id" label="Role">
                                                        <Select placeholder="Select Role">
                                                            {roles.map((role, index) => (
                                                                <Select.Option key={index} value={role.id}>{role.role_name}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="possition" label="Position">
                                                        <Input placeholder="Position" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item name="about" label="About">
                                                        <Input style={{ width: "100%" }} placeholder="About" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Button onClick={() => setStep(0)}>Back</Button>
                                                <Form.Item>
                                                    {creatingUser ? (
                                                        <Button type="primary" loading>Loading</Button>
                                                    ) :
                                                        <Button type="primary" htmlType="submit">Proceed</Button>
                                                    }
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    }
                                    {step === 2 &&
                                        <Form layout="vertical" form={form} onFinish={finishSecurity} initialValues={{
                                            password: "",
                                        }}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item label="Password" name="password">
                                                        <Input.Password style={{ padding: "10px 20px" }} placeholder="Password" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item label="Confirm Password" name="confirm_password">
                                                        <Input.Password style={{ padding: "10px 20px" }} placeholder="Confirm Password" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Button onClick={() => setStep(1)}>Back</Button>
                                                <Form.Item>
                                                    {creatingUser ?
                                                        <Button type="primary" loading>Loading</Button>
                                                        :
                                                        <Button type="primary" htmlType="submit">Proceed</Button>
                                                    }
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    }
                                </>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddMerchantUser;