/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { _get_daily_shop_matrix, _get_monthly_shop_matrix, _get_order_daily_report, _get_order_monthly_report, _get_order_yearly_report, _get_yearly_shop_matrix } from "common/axios_services";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { Link, useLocation, useNavigate } from "react-router-dom";

function OrderReport() {
    const location = useLocation();
    const navigate = useNavigate()
    const getQueryParam = (param) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
    };
    const month = getQueryParam('month');
    const date = getQueryParam('date');
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [order, setOrder] = useState([]);
    const [monthly, setMonthly] = useState([]);
    const [dailyOrders, setDailyOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [page_size, setPage_size] = useState(10);
    const [shopMatrix, setShopMatrix] = useState(false);
    const jwt = localStorage.konnect_admin_token;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };
    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }
    const getShopMatrix = async () => {
        try {
            if (!month && !date) {
                const shop_matrix = await _get_yearly_shop_matrix()
                setShopMatrix(shop_matrix.data.data)
            } else if (month && !date) {
                const shop_matrix = await _get_monthly_shop_matrix({ month })
                setShopMatrix(shop_matrix.data.data)
            } else if (month && date) {
                const shop_matrix = await _get_daily_shop_matrix({ date })
                setShopMatrix(shop_matrix.data.data)
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_admin_token')
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getOrderReport = async () => {
        setSpinnerLoading(true)
        setOrder([]);
        try {
            if (!month && !date) {
                const fetchAllShop = await _get_order_yearly_report()
                setOrder(fetchAllShop.data.data);
            } else if (month && !date) {
                const fetchAllShop = await _get_order_monthly_report({ month, page, page_size, paymentOpt: "", status: "", search: "" })
                setMonthly(fetchAllShop.data.data);
            } else if (month && date) {
                const fetchAllShop = await _get_order_daily_report({ date, page, page_size, paymentOpt: "", status: "", search: "" })
                setDailyOrders(fetchAllShop.data.data);
            }
            setSpinnerLoading(false)
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                if (err.response === "Unauthorized") {
                    window.location.reload()
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }
    useEffect(() => {
        getShopMatrix()
        getOrderReport()
    }, [month, date])

    let data = order.map((trans, index) => {
        return {
            key: index,
            month: trans?.month,
            date: (month && !date && trans?.date) && (moment(trans?.date).format('ddd, MMMM Do YYYY')),
            order: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.total_amount) * 1)} />,
            del: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.delivery_amount) * 1)} />,
            supply: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.supplier_price) * 1)} />,
            discount: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.discount) * 1)} />,
            balance: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.total_balance) * 1)} />,
            action: <Link to={`/shop-reports?month=${trans?.month}`}>View</Link>,
            monthAction: <Link to={`/shop-reports?month=${month}&date=${trans?.created_at}`}>View</Link>,
        }
    });
    let monthlyData = [];
    if (month && !date) {
        monthlyData = monthly.sort((a, b) => { return a.date.localeCompare(b.date) }).map((trans, index) => {
            return {
                key: index,
                month: trans?.month,
                date: (month && !date && trans?.date) && (moment(trans?.date).format('ddd, MMMM Do YYYY')),
                order: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.total_amount) * 1)} />,
                del: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.delivery_amount) * 1)} />,
                supply: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.supplier_price) * 1)} />,
                discount: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.discount) * 1)} />,
                balance: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.total_balance) * 1)} />,
                action: <Link to={`/shop-reports?month=${trans?.month}`}>View</Link>,
                monthAction: <Link to={`/shop-reports?month=${month}&date=${trans?.created_at}`}>View</Link>,
            }
        })
    }
    let dailyData = dailyOrders.map((trans, index) => {
        return {
            key: index,
            sn: index + 1,
            full_name: trans?.username,
            order: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.total_amount) * 1)} />,
            del: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.delivery_fee) * 1)} />,
            deduction: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.supplier_price + trans?.discount) * 1)} />,
            status: <div style={{ textTransform: "capitalize" }}>{trans?.delivery_status === "success" ? <Tag color="green">Delivered</Tag> : <Tag color="red">Not Delivered</Tag>}</div>,
            action: <Link to={`/review-order/${trans?.transaction_ref}`}>View</Link>,
        }
    });
    const columns = [
        {
            title: 'Month',
            dataIndex: 'month',
        },
        {
            title: 'Order (NGN)',
            dataIndex: 'order',
            align: 'center'
        },
        {
            title: 'Del Fee (NGN)',
            dataIndex: 'del',
            align: 'right'
        },
        {
            title: 'Supply (NGN)',
            dataIndex: 'supply',
            align: 'right'
        },
        {
            title: 'Discount (%)',
            dataIndex: 'discount',
            align: 'right'
        },
        {
            title: 'Balance (NGN)',
            dataIndex: 'balance',
            align: 'right'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center'
        },
    ];
    const monthlyColumns = [
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Order (NGN)',
            dataIndex: 'order',
            align: 'center'
        },
        {
            title: 'Del Fee (NGN)',
            dataIndex: 'del',
            align: 'right'
        },
        {
            title: 'Supply (NGN)',
            dataIndex: 'supply',
            align: 'right'
        },
        {
            title: 'Discount (%)',
            dataIndex: 'discount',
            align: 'right'
        },
        {
            title: 'Balance (NGN)',
            dataIndex: 'balance',
            align: 'right'
        },
        {
            title: 'Action',
            dataIndex: 'monthAction',
            align: 'center'
        },
    ];
    const dateColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Full Name',
            dataIndex: 'full_name',
            align: 'center'
        },
        {
            title: 'Order (NGN)',
            dataIndex: 'order',
            align: 'right'
        },
        {
            title: 'Del Fee (NGN)',
            dataIndex: 'del',
            align: 'right'
        },
        {
            title: 'Deduction (NGN)',
            dataIndex: 'deduction',
            align: 'right'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'right'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center'
        },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={3} pb={6}>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="dark"
                                icon="weekend"
                                title="Total Transactions"
                                count={shopMatrix ? <NumericFormat style={{ fontSize: "16px", fontWeight: "bold" }} thousandSeparator={true} displayType={'text'} value={Number(shopMatrix?.total_items)} /> : "0"}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="leaderboard"
                                title="Order Amount"
                                count={shopMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}> <span style={{ fontWeight: "normal", fontSize: "12px" }}>NGN</span>  <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(shopMatrix?.order_amount) * 1)} /> </span> : "0"}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="leaderboard"
                                title="Total Delivery"
                                count={shopMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}> <span style={{ fontWeight: "normal", fontSize: "12px" }}>NGN</span>  <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(shopMatrix?.delivery_amount) * 1)} /> </span> : "0"}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="leaderboard"
                                title="Total Amount"
                                count={shopMatrix ? <span style={{ fontSize: "16px", fontWeight: "bold" }}> <span style={{ fontWeight: "normal", fontSize: "12px" }}>NGN</span>  <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(shopMatrix?.total_amount) * 1)} /> </span> : "0"}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    {!month && !date ? "Shop Reports" : month && !date ? `${month} report` : month && date ? `${date} Reports` : "Shop Reports"}
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <>
                                            <div className="table-responsive">
                                                {!month && !date ?
                                                    <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                    : month && !date ?
                                                        <Table columns={monthlyColumns} pagination={false} dataSource={monthlyData} bordered />
                                                        : month && date ?
                                                            <Table columns={dateColumns} pagination={false} dataSource={dailyData} bordered />
                                                            :
                                                            <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                }
                                            </div>
                                        </>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default OrderReport;