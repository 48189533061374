/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { CCard, CCardBody } from "@coreui/react";
import { Button, Form, Input, Modal, Pagination, Select, Spin, Table, Tag, notification } from "antd";
import { _approve_delivery_driver, _get_drivers_request } from "common/axios_services";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function DriverRequest() {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [approveModal, setApproveModal] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(false);
    const [total_users, setTotal_users] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allDatasFetched, setAllDatasFetched] = useState(false);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [approvingDriver, setApprovingDriver] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);

    const onSearch = (value, _e, info) => setSearch(value)
    // const onChangeDate = (e) => setFilterDate(e.target.value);
    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const getDriversRequest = async () => {
        setAllDatasFetched(false);
        try {
            const get_drivers_request = await _get_drivers_request({ page, page_size, search, delivery_date_id: "", from: "", to: "" })
            setAllDatasFetched(true);
            setTotal_users(get_drivers_request?.data?.meta?.total);
            setDataToDisplay(get_drivers_request?.data?.data);
        } catch (err) {
            setDataToDisplay([]);
            setAllDatasFetched(true);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    useEffect(() => {
        getDriversRequest()
    }, [page, page_size, updateCount])

    const data = dataToDisplay?.map((driver, index) => {
        return {
            sn: index + 1,
            driver: driver?.driver_name,
            phone: driver?.driver_contact,
            vanType: driver?.van_type,
            capacity: driver?.van_capacity,
            plateNumber: driver?.plate_number,
            status: <div style={{ textTransform: "capitalize" }}>{driver?.status === "approved" ? <Tag color="#87d068">{driver?.status}</Tag> : <Tag color="#0000FF">{driver?.status}</Tag>}</div>,
            action: driver.status === "approved" ? <Button type="link" disabled>Approve</Button> : <Button type="link" onClick={() => activateApproveModal(driver)}>Approve</Button>,
        }
    });;


    const columns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Driver',
            className: 'column-number',
            dataIndex: 'driver',
            align: 'center',
        },
        {
            title: 'Phone Number',
            className: 'column-number',
            dataIndex: 'phone',
            align: 'center',
        },
        {
            title: 'Van Type',
            className: 'column-number',
            dataIndex: 'vanType',
            align: 'center',
        },
        {
            title: 'Capacity',
            className: 'column-number',
            dataIndex: 'capacity',
            align: 'center',
        },
        {
            title: 'Plate Number',
            className: 'column-number',
            dataIndex: 'plateNumber',
            align: 'center',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center'
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllDatasFetched(false);
        setDataToDisplay([]);
    }

    const activateApproveModal = (driver) => {
        setApproveModal(true)
        setSelectedDriver(driver)
    }

    const approveDriver = async () => {
        setApprovingDriver(true)
        try {
            const approve_driver = await _approve_delivery_driver({ id: selectedDriver?.id, status: "approve", comment: "Approved" })
            setApprovingDriver(false)
            setApproveModal(false)
            setUpdateCount(updateCount + 1)
            openNotificationWithIcon('success', approve_driver?.data?.title, approve_driver?.data?.message)
        } catch (err) {
            setApprovingDriver(false)
            if (err.response) {
                if (err?.response?.data?.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Driver Request
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <React.Fragment>
                                    <CCard>
                                        <div className="grid-flex" style={{ background: "#f0f2f5", padding: "1rem", display: "flex", alignItems: "center" }}>
                                            {/* <div className="grid-flex">
                                                <p style={{ marginBottom: "0px" }}>Date</p>
                                                <input id="date" style={{ width: "100%", padding: "0px 11px", height: "30px", borderRadius: "6px", border: "1px solid #000" }} type="date" defaultValue={filterDate} name="date" onChange={onChangeDate} />
                                            </div> */}
                                            <div className="grid-flex" style={{ marginLeft: "auto" }}>
                                                <Input.Search placeholder="Search" onSearch={onSearch} allowClear style={{ width: 270 }} />
                                            </div>
                                        </div>
                                        <CCardBody>
                                            {!allDatasFetched ?
                                                <div>
                                                    <Spin />
                                                </div> :
                                                <>
                                                    <div className="table-responsive">
                                                        <Table columns={columns} dataSource={data} pagination={false} bordered footer={null} />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                        <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Drivers`} defaultPageSize={page_size} onChange={change_page} />
                                                    </div>
                                                </>
                                            }
                                        </CCardBody>
                                        <Modal centered={true} visible={approveModal} onCancel={() => { setApproveModal(false); setSelectedDriver(false) }} footer={null}>
                                            <h5>Approve Driver</h5>
                                            <p>Are you sure you want to approve <b>{selectedDriver?.driver_name}</b>.</p>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                {approvingDriver ?
                                                    <Button type="primary" disabled loading>Approving Driver</Button> :
                                                    <Button type="primary" onClick={() => approveDriver()}>Approve Driver</Button>
                                                }
                                                {approvingDriver ?
                                                    <Button disabled>Cancel</Button> :
                                                    <Button onClick={() => { setApproveModal(false); setSelectedDriver(false) }}>Cancel</Button>
                                                }
                                            </div>
                                        </Modal>
                                    </CCard>
                                </React.Fragment>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default DriverRequest;