/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CategoryNav from 'examples/Navbars/CategoryNav';

import { Button, Form, Input, Pagination, Spin, Table, notification } from "antd";
import { _export_sales_sa, _get_sales_matrix, _get_sales_sa_details } from "common/axios_services";
import AttachSoComp from "components/SalesComponent/AttachSoComp";
import DetachSoComp from "components/SalesComponent/DetachSoCom";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function SaDetails() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_sales, setTotal_sales] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [arrayToDisplay, setArrayToDisplay] = useState([]);
    const [query, setQuery] = useState("");
    const [filterOptions, setFilterOptions] = useState("sl");
    const [salesMatrix, setSalesMatrix] = useState(false);
    const [exporting, setExporting] = useState(false);
    const navigate = useNavigate()
    const params = useParams()
    const updateCount = useSelector(state => state?.updateCount?.count)
    const [filterDate, setFilterDate] = useState("");
    const [filterToDate, setFilterToDate] = useState(moment().format('YYYY-MM-DD'));

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ message: '', placement: 'bottomRight', description: message, });
    };

    const getSales = async () => {
        setAllUsersFetched(false);
        try {
            const get_sales = await _get_sales_sa_details({ page, page_size, search: query, category: "sa", user_id: params.id, filterDate, filterToDate })
            setAllUsersFetched(true);
            setTotal_sales(get_sales.data.meta.total);
            setArrayToDisplay(get_sales?.data?.data);
        } catch (err) {
            setAllUsersFetched(true);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getSalesMatrix = async () => {
        try {
            const get_sales_matrix = await _get_sales_matrix({ filterDate, filterToDate })
            setSalesMatrix(get_sales_matrix.data.data)
        } catch (err) {
            setSalesMatrix(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    useEffect(() => {
        getSales()
        getSalesMatrix()
    }, [page, page_size, query, filterOptions, updateCount, filterDate, filterToDate])

    const data = [];
    arrayToDisplay.forEach((sales, index) => {
        data.push({
            key: index,
            sn: index + 1,
            name: `${sales?.fullname}`,
            phone: sales?.phone_number,
            address: sales?.new_user_address,
            so: sales?.so_no,
            order: sales?.so_order_no,
            orderN: sales?.so_order_amount,
            incomeN: sales?.so_order_income,
            remove: <DetachSoComp info={sales} />
        })
    });

    const soColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'SO Name',
            className: 'column-number',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: 'Phone',
            className: 'column-number',
            dataIndex: 'phone',
            align: 'center',
        },
        {
            title: 'Address',
            className: 'column-number',
            dataIndex: 'address',
            align: 'center',
        },
        {
            title: 'Order',
            className: 'column-number',
            dataIndex: 'order',
            align: 'center',
        },
        {
            title: 'Order NGN',
            className: 'column-number',
            dataIndex: 'orderN',
            align: 'center',
        },
        {
            title: 'Income NGN',
            className: 'column-number',
            dataIndex: 'incomeN',
            align: 'center',
        },
        {
            title: 'Remove',
            className: 'column-number',
            dataIndex: 'remove',
            align: 'center'
        }
    ];
    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setArrayToDisplay([])
    }
    function dataFilter({ search }) {
        setQuery(search.toLowerCase())
        setAllUsersFetched(false)
    }
    const exportData = async () => {
        setExporting(true)
        try {
            const export_sa = await _export_sales_sa({ id: params.id })
            window.open(export_sa?.data?.data?.url)
            setExporting(false)
        } catch (err) {
            setExporting(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const onChangeDate = (e) => setFilterDate(e.target.value);
    const onChangeToDate = (e) => setFilterToDate(e.target.value);

    return (
        <DashboardLayout>
            <CategoryNav title={`${decodeURIComponent(params?.name)}`} breadroute="sales" />
            {salesMatrix ?
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="leaderboard"
                                title="SO"
                                count={salesMatrix.total_so ? salesMatrix.total_so : 0}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="success"
                                icon="store"
                                title="Order"
                                count={salesMatrix.so_order_no ? salesMatrix.so_order_no : 0}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="success"
                                icon="store"
                                title="Order (NGN)"
                                count={salesMatrix.so_order_amount ? salesMatrix.so_order_amount : 0}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="primary"
                                icon="person_add"
                                title="My Income (NGN)"
                                count={salesMatrix.so_order_income ? salesMatrix.so_order_income : 0}
                            />
                        </MDBox>
                    </Grid>
                </Grid> :
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="leaderboard"
                                title="SO"
                                count="0"
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="success"
                                icon="store"
                                title="Order"
                                count="0"
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="success"
                                icon="store"
                                title="Order (NGN)"
                                count="0"
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="primary"
                                icon="person_add"
                                title="My Income (NGN)"
                                count="0"
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            }
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white"> Sales Assistant</MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <label>Filter Sales Data</label>
                                        <Form layout="vertical" form={form} initialValues={{ search: "" }} onValuesChange={dataFilter} style={{ maxWidth: 600, minWidth: 300 }} >
                                            <Form.Item name="search">
                                                <Input placeholder="search by ref code" />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                    <div style={{ display: "flex", }}>
                                        <div className="grid-flex" style={{ fontSize: "14px" }}>
                                            <p style={{ marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)" }}>From</p>
                                            <input id="date" style={{ width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)" }} type="date" defaultValue={filterDate} name="date" max={moment().format('YYYY-MM-DD')} onChange={onChangeDate} />
                                        </div>
                                        <div className="grid-flex" style={{ marginLeft: "10px", fontSize: "14px" }}>
                                            <p style={{ marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)" }}>To</p>
                                            <input id="date" style={{ width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)" }} max={moment().format('YYYY-MM-DD')} type="date" defaultValue={filterToDate} name="date" onChange={onChangeToDate} />
                                        </div>
                                        <span style={{ marginLeft: "10px", marginTop: "auto" }}>
                                            <AttachSoComp />
                                        </span>
                                        {exporting ?
                                            <Button loading style={{ marginLeft: "10px", marginTop: "auto" }}>Export</Button> :
                                            <Button onClick={exportData} style={{ marginLeft: "10px", marginTop: "auto" }}>Export</Button>
                                        }
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {allUsersFetched ?
                                        <>
                                            <Table columns={soColumns} pagination={false} dataSource={data} bordered />
                                            <div style={{ marginTop: "2%" }}></div>
                                            <Pagination showSizeChanger current={page} total={total_sales} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                                            <div style={{ marginTop: "2%" }}></div>
                                        </>
                                        :
                                        <div className="">
                                            <Spin />
                                        </div>
                                    }
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default SaDetails;