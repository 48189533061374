/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import { Done, Edit, Visibility } from "@mui/icons-material";
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Table, Tabs, notification } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { _change_inbound_instore_status, _get_all_inbound, _get_all_instore, _get_dates, _get_inbound_details, _get_inboundby_dateid, _get_instore_details, _get_instore_matrix, _get_orders, _get_profile, _update_inbound_data } from "common/axios_services";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import React, { useEffect, useState } from 'react';
import ReactMoment from 'react-moment';
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";

function Instore() {
    const navigate = useNavigate()
    const params = useParams()
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_items, setTotal_items] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [instoreData, setInstoreData] = useState([]);
    const [fetchingInbound, setFetchingInbound] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [fetchingInstoreInfo, setFetchingInstoreInfo] = useState(false);
    const [instoreInfo, setInstoreInfo] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [editInstore, setEditInstore] = useState(false);
    const [createInstore, setCreateInstore] = useState(false);
    const [allMatrix, setAllMatrix] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const getInstoreDetail = async ({ id }) => {
        setFetchingInstoreInfo(true)
        try {
            const instoreData = await _get_instore_details({ id })
            setDetailsModal(true)
            setInstoreInfo(instoreData.data.data)
            setFetchingInstoreInfo(false)
        } catch (err) {
            setDetailsModal(false)
            setInstoreInfo(false)
            setFetchingInstoreInfo(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchAllInstore = async () => {
        setFetchingInstoreInfo(true)
        try {
            const instore = await _get_all_instore({ page, page_size })
            setFetchingInstoreInfo(false)
            setInstoreData(instore.data.data)
            setTotal_items(instore.data.meta.total);
        } catch (err) {
            setTotal_items(0);
            setFetchingInstoreInfo(false)
            setInstoreData([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const editInstoreDetails = async ({ id }) => {
        setEditInstore(true)
        try {
            const inboundInfo = await _get_instore_details({ id })
            setInstoreInfo(inboundInfo.data.data)
        } catch (err) {
            setInstoreInfo(false)
            setEditInstore(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchInstoreMatrix = async () => {
        try {
            const matrix = await _get_instore_matrix()
            setAllMatrix(matrix.data.data)
        } catch (err) {
            setAllMatrix(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    useEffect(() => {
        fetchAllInstore()
        fetchInstoreMatrix()
    }, [page, page_size, updateCount])

    // const items = [];
    // deliveryDates.forEach((date) => {
    //     items.push({
    //         key: date.id,
    //         value: date.date,
    //         label: <ReactMoment format="dddd, MMMM Do YYYY">{date.date}</ReactMoment>,
    //     })
    // });
    const completedData = instoreData.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            itemName: order.product_name,
            totalWeight: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_weight)} />,
            totalInbound: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_inbound)} />,
            totalReturn: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_returned)} />,
            totalVariance: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_variance)} />,
            totalDamage: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_damaged)} />,
            totalQuantity: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_quantity)} />,
            totalAmount: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_amount_value)} />,
            action: <>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    <Edit style={{ cursor: "pointer" }} onClick={() => { editInstoreDetails({ id: order.id }) }} />
                </span>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    | <Visibility style={{ cursor: "pointer" }} onClick={() => { getInstoreDetail({ id: order.id }) }} />
                </span>
            </>,
        }
    });
    const completedColumns = [
        {
            title: 'SN',
            className: 'column-number',
            dataIndex: 'sn',
            align: 'left',
        },
        {
            title: 'Items',
            className: 'column-number',
            dataIndex: 'itemName',
            align: 'left',
        },
        {
            title: 'Weight(KG)',
            className: 'column-number',
            dataIndex: 'totalWeight',
            align: 'left',
        },
        {
            title: 'Inbound ',
            className: 'column-number',
            dataIndex: 'totalInbound',
            align: 'center',
        },
        {
            title: 'Damage',
            className: 'column-number',
            dataIndex: 'totalDamage',
            align: 'center',
        },
        {
            title: 'Variance',
            className: 'column-number',
            dataIndex: 'totalVariance',
            align: 'center',
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'totalQuantity',
            align: 'center',
        },
        {
            title: 'Amount(NGN)',
            className: 'column-number',
            dataIndex: 'totalAmount',
            align: 'center',
        },
        // {
        //     title: 'Status',
        //     className: 'column-number',
        //     dataIndex: 'status',
        //     align: 'center',
        // },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ];
    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setUserBox([]);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {allMatrix ?
                <MDBox py={3}>
                    {/* 4 cat */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="weekend"
                                    title="Total Inbound"
                                    count={Number(allMatrix.total_inbound)}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="leaderboard"
                                    title="Total Returned"
                                    count={Number(allMatrix.total_variance)}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="store"
                                    title="Total Damaged"
                                    count={Number(allMatrix.total_damaged)}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="primary"
                                    icon="person_add"
                                    title="Total Quantity"
                                    count={Number(allMatrix.total_quantity)}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox> :
                <MDBox py={3}>
                    {/* 4 cat */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="weekend"
                                    title="Total Inbound"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="leaderboard"
                                    title="Total Returned"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="store"
                                    title="Total Damaged"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="primary"
                                    icon="person_add"
                                    title="Total Quantity"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Store
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <div style={{ marginTop: "7px", marginBottom: "16px", }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div></div>
                                            <div >
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div></div>
                                                    <Button onClick={() => setCreateInstore(true)}>Create Product</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        {!fetchingInbound ?
                                            <>
                                                <Table columns={completedColumns} pagination={false} dataSource={completedData} bordered />
                                                <div style={{ marginTop: "2%" }}></div>
                                                <Pagination showSizeChanger current={page} total={total_items} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} item(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                <div style={{ marginTop: "2%" }}></div>
                                            </>
                                            :
                                            <div className="">
                                                <Spin />
                                            </div>
                                        }
                                    </div>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Modal centered={true} title="" visible={detailsModal} footer={null} onCancel={() => { setDetailsModal(false) }} className="pb-5 pt-5">
                {instoreInfo ?
                    <>
                        <img src={instoreInfo.product_image} style={{ width: "100px", height: "100px", }} />
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Product Name</p>
                            <p>{instoreInfo.product_name}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Total Weight</p>
                            <p>{instoreInfo.total_weight} Kg</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Total Inbound</p>
                            <p>{instoreInfo.total_inbound}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Total Returned</p>
                            <p>{instoreInfo.total_returned}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Total Variance</p>
                            <p>{instoreInfo.total_variance}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Total Damaged</p>
                            <p>{instoreInfo.total_damaged}</p>
                        </div>
                    </> :
                    <div>
                        <Spin />
                    </div>
                }
            </Modal>
            <Modal centered={true} title="" visible={editInstore} footer={null} onCancel={() => { setEditInstore(false) }} className="pb-5 pt-5">
                {instoreInfo ?
                    <>
                        <h5>Edit</h5>
                        <Form autoComplete="off" form={form} layout="vertical"
                            initialValues={{
                                id: `${instoreInfo.id}`,
                                product_name: `${instoreInfo.product_name}`,
                                // quantity_supplied: "",
                                // comment: ""
                            }}
                        // onFinish={editinstoreInfo}
                        >
                            <Form.Item label="Product Name" name="product_name">
                                <Input disabled placeholder="Product Name" />
                            </Form.Item>
                            <Button htmlType='submit'>Update</Button>
                        </Form>
                    </> :
                    <div>
                        <Spin />
                    </div>
                }
            </Modal>
            <Modal centered={true} title="" visible={createInstore} footer={null} onCancel={() => { setCreateInstore(false) }} className="pb-5 pt-5">
                <h5>Create Product</h5>
                <Form autoComplete="off" form={form} layout="vertical">
                    <Form.Item label="Location" name="location">
                        <Input placeholder="Location" />
                    </Form.Item>
                    <Form.Item label="In Threshold" name="in_threshold">
                        <Input placeholder="In Threshold" />
                    </Form.Item>
                    <Form.Item label="Out Threshold" name="out_threshold">
                        <Input placeholder="Out Threshold" />
                    </Form.Item>
                    <Form.Item label="Max Limit" name="max_limit">
                        <Input placeholder="Max Limit" />
                    </Form.Item>
                    <Form.Item label="Comment" name="comment">
                        <Input placeholder="Comment" />
                    </Form.Item>
                    <Button htmlType='submit'>Create</Button>
                </Form>
            </Modal>
            <Footer />
        </DashboardLayout>
    );
}

export default Instore;