/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Button, DatePicker, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Switch, Table, notification } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { _add_date, _delete_date, _get_dates, _get_profile } from "common/axios_services";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

function Date_settings() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_plans, setTotal_plans] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [allProducts, setAllProducts] = useState('view_dates');
    const [datesfetched, setDatesFetched] = useState([]);
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDateID, setSelectedDateID] = useState("");
    const [adding, setAdding] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const jwt = localStorage.konnect_admin_token;
    const [addDates, setAddDates] = useState(false);
    const [deleteDates, setDeleteDates] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const getDates = async () => {
        try {
            const addedDates = await _get_dates({ page, page_size })
            setDatesFetched(addedDates.data.data);
            setTotal_plans(addedDates.data.meta.total)
            setFetchingData(false);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addDates")) {
                setAddDates(true)
            } else {
                setAddDates(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteDates")) {
                setDeleteDates(true)
            } else {
                setDeleteDates(false)
            }
        } else {
            setDeleteDates(false)
            setAddDates(false)
        }
    }

    useEffect(() => {
        getDates()
        fetchUser()
    }, [page, page_size, updateCount])

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            render: text => <a>{text}</a>,
        },
        {
            title: 'BNPL limit',
            dataIndex: 'bnpl_limit',
            render: text => <a>{text}</a>,
        },
        {
            title: 'BNPL Spent',
            dataIndex: 'bnpl_spent',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Pay now limit',
            className: 'column-number',
            dataIndex: 'pay_now_limit',
            align: 'center',
        },
        {
            title: 'Pay now spent',
            className: 'column-number',
            dataIndex: 'pay_now_spent',
            align: 'center',
        },
        {
            title: 'Pay on delivery limit',
            className: 'column-number',
            dataIndex: 'pay_on_delivery_limit',
            align: 'center',
        },
        {
            title: 'Pay on delivery spent',
            className: 'column-number',
            dataIndex: 'pay_on_delivery_spent',
            align: 'center',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const deletePlan = () => {
        setIsModalVisible(true)
    }

    let data = datesfetched.map((date, index) => {
        const isFutureDate = moment(date?.date).isAfter(moment().startOf('day'));
        return {
            key: index,
            id: date.id,
            sn: index + 1,
            date: moment(date?.date).format('MMMM Do YYYY'),
            bnpl_limit: <NumericFormat thousandSeparator={true} displayType={'text'} value={date.bnpl_limit} />,
            bnpl_spent: <NumericFormat thousandSeparator={true} displayType={'text'} value={date.total_bnpl} />,
            pay_now_limit: <NumericFormat thousandSeparator={true} displayType={'text'} value={date.pay_now_limit} />,
            pay_now_spent: <NumericFormat thousandSeparator={true} displayType={'text'} value={date.total_pay_now} />,
            pay_on_delivery_limit: <NumericFormat thousandSeparator={true} displayType={'text'} value={date.pay_on_delivery_limit} />,
            pay_on_delivery_spent: <NumericFormat thousandSeparator={true} displayType={'text'} value={date.total_pay_on_delivery} />,
            actions: <div>
                {isFutureDate && (
                    <>
                        <Link to={`/date/edit/${date.id}`}>Edit</Link>{deleteDates && <> /
                            <Button onClick={() => { deletePlan(); setSelectedDateID(date.id) }} type="link" htmlType="submit" className="inline-button">Delete</Button></>}
                    </>
                )}
            </div>

        }
    });

    const add_date = async (values) => {
        setAdding(true)
        try {
            const data = {
                // type: values.type,
                date: values.date,
                kg_limit: `${values.kg_limit}`,
                bnpl_limit: `${values.bnpl_limit}`,
                pay_now_limit: `${values.pay_now_limit}`,
                pay_on_delivery_limit: `${values.pay_on_delivery_limit}`,
            }
            const addDates = await _add_date(data)
            setAdding(false)
            setUpdateCount(updateCount + 1)
            setAllProducts('view_dates')
            openNotificationWithIcon('success', addDates.data.message)
        } catch (err) {
            setAdding(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    };

    const finaliseDateDelete = async () => {
        try {
            const deleteDate = await _delete_date({ id: selectedDateID })
            openNotificationWithIcon('success', deleteDate.data.message)
            setUpdateCount(updateCount + 1)
            setIsModalVisible(false)
        } catch (err) {
            setIsModalVisible(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Delivery Date Settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {allProducts === 'view_dates' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div className="grid-flex">
                                                        Delivery Dates List
                                                        <div>
                                                            {addDates &&
                                                                <Button style={{ marginRight: '10px' }} type="primary" onClick={() => setAllProducts('add_date')}>Add Delivery Date</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {fetchingData ?
                                                        <div>
                                                            <Spin indicator={antIcon} />
                                                        </div> :
                                                        <>
                                                            <div className="table-responsive">
                                                                <Table columns={columns} dataSource={data} pagination={false} bordered footer={null} />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_plans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Dates`} defaultPageSize={page_size} onChange={change_page} />
                                                            </div>
                                                        </>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                        <Modal title="Delete Date" centered visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
                                            <p>Are you sure you want to delete this Delivery Date?</p>
                                            <div>
                                                <button onClick={() => setIsModalVisible(false)} style={{ border: '1px solid #F0F0F0', borderRadius: 4, padding: '10px 25px', background: 'transparent', marginRight: 10, fontSize: 12, fontWeight: '600' }}
                                                >Cancel</button>
                                                <button onClick={() => finaliseDateDelete()} style={{ border: '1px solid #FF4A00', borderRadius: 4, padding: '10px 25px', background: '#FF4A00', fontSize: 12, fontWeight: '600', color: '#fff' }}
                                                >Delete</button>
                                            </div>
                                        </Modal>
                                    </Spin>
                                }
                                {allProducts === 'add_date' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">Add Delivery Date
                                                    <div>
                                                        <Button type="primary" onClick={() => setAllProducts('view_dates')}>View Delivery Dates</Button>
                                                    </div>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {dateErrorMessage ? <p className="error-message">{dateErrorMessage}</p> : ''}
                                                <Form form={form} onFinish={add_date} initialValues={{
                                                    // type: "",
                                                    date: "",
                                                    kg_limit: "",
                                                    bnpl_limit: "",
                                                    pay_now_limit: "",
                                                    pay_on_delivery_limit: ""
                                                }} layout="vertical">
                                                    {/* <Form.Item name="type" label="Delivery Type" >
                                                        <Select placeholder="Delivery Type">
                                                            <Select.Option value="delivery">Home Delivery</Select.Option>
                                                            <Select.Option value="pickup">Pickup Delivery</Select.Option>
                                                        </Select>
                                                    </Form.Item> */}
                                                    <Form.Item name="date" label="Date" >
                                                        <DatePicker style={{ width: "100%" }} placeholder="Date" />
                                                    </Form.Item>
                                                    <Form.Item name="kg_limit" label="KG_limit ( kg )" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="KG limit" />
                                                    </Form.Item>
                                                    <Form.Item name="bnpl_limit" label="BNPL Limit" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="BNPL limit" />
                                                    </Form.Item>
                                                    <Form.Item name="pay_now_limit" label="Pay now limit" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Pay now limit" />
                                                    </Form.Item>
                                                    <Form.Item name="pay_on_delivery_limit" label="Pay on delivery limit" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Pay on delivery limit" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {adding ?
                                                            <Button disabled loading type="primary">Submitting..</Button> :
                                                            <Button htmlType="submit" type="primary">Submit</Button>
                                                        }
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Date_settings;
