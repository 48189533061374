import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
// import { DeleteOutlined } from "@mui/icons-material";
import { Button, Form, Input, InputNumber, Modal, Select, Spin, notification } from 'antd';
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CategoryNav from 'examples/Navbars/CategoryNav';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const Wise9ja_edit = () => {
    const [features, setFeatures] = useState([])
    const [plans, setPlans] = useState([])
    const [featureName, setFeatureName] = useState([])
    const [editModal, setEditModal] = useState(false)
    const [featureDetails, setFeatureDetails] = useState({})
    const [unit, setUnit] = useState('flat');

    const navigate = useNavigate()
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [form] = Form.useForm();
    const jwt = localStorage.konnect_admin_token;
    const { TextArea } = Input;
    const params = useParams()

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const handleUnitChange = (value) => {
        setUnit(value);
    };


    useEffect(() => {
        if (!jwt) {
            openNotificationWithIcon('error', 'Unauthorized')
            return navigate('/')
        }

        const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });
        axiosCall(`/wisenija_plan/${params.id}`, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            setPlans(res.data.data)
            setFeatures(res.data.data.features)
            console.log(res.data.data.features)
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })
    }, [params.id, jwt, navigate])

    useEffect(() => {
        form.setFields([
            {
                name: 'level_a_value',
                label: `Level a upfront instant commission (${unit === 'flat' ? 'NGN' : '%'})`,
            },
        ]);
        form.setFields([
            {
                name: 'level_b_value',
                label: `Level b upfront instant commission (${unit === 'flat' ? 'NGN' : '%'})`,
            },
        ]);
        form.setFields([
            {
                name: 'level_a_wallet_value',
                label: `Level a wallet bonus (${unit === 'flat' ? 'NGN' : '%'})`,
            },
        ]);
        form.setFields([
            {
                name: 'level_b_wallet_value',
                label: `Level b wallet bonus (${unit === 'flat' ? 'NGN' : '%'})`,
            },
        ]);
    }, [unit]);

    const update_plan = (values) => {
        if (!jwt) {
            openNotificationWithIcon('error', 'Unauthorized')
            return navigate('/')
        }

        const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });
        axiosCall.patch(`/wisenija_plan/${params.id}`, {
            plan_code: `${values.plan_code}`,
            plan_name: `${values.plan_name}`,
            display_status: values.display_status,
            period: `${values.period}`,
            durations: `${values.durations}`,
            min_amount: `${values.min_amount}`,
            max_amount: `${values.max_amount}`,
            description: `${values.description}`,
            level_a_value: `${values.level_a_value}`,
            level_b_value: `${values.level_b_value}`,
            unit: `${values.unit}`,
            min_cap_bonus: `${values.min_cap_bonus}`,
            max_cap_bonus: `${values.max_cap_bonus}`,
            min_wallet_cap: `${values.min_wallet_cap}`,
            max_wallet_cap: `${values.max_wallet_cap}`,
            level_a_wallet_value: `${values.level_a_wallet_value}`,
            level_b_wallet_value: `${values.level_b_wallet_value}`,
            features
        }, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            navigate('/wise9ja')
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })
    }

    const changeFeature = (e) => {
        const uniqueId = uuidv4();
        setFeatureName({ name: e.target.value, id: uniqueId })
    }

    const addFeature = () => {
        setFeatures([...features, featureName])
        setFeatureName("")
    }

    const updateFeature = (values) => {
        setFeatureDetails({})
        if (featureDetails.index !== -1) {
            const updatedFeature = { ...features[featureDetails.index], name: values.feature };
            const updatedFeatures = [...features];
            updatedFeatures[featureDetails.index] = updatedFeature;
            setFeatures(updatedFeatures); // assuming you have a state variable named "features" and a setter function named "setFeatures"
            openNotificationWithIcon('success', "Feature updated successfully, kindly click on the update button to update")
            setEditModal(false)
        } else {
            openNotificationWithIcon('error', "Wise9ja plan not editable, please try again")
            setEditModal(false)
        }
    }

    const EditFeature = ({ id, feature }) => {
        if (!jwt) {
            openNotificationWithIcon('error', 'Unauthorized')
            return navigate('/')
        }
        const index = features.findIndex(feature => feature.id === id);
        setFeatureDetails({
            index,
            id,
            feature
        })
        form.setFieldsValue({
            feature: feature,
        });
        setEditModal(true)
    }

    const deleteFeature = ({ id }) => {
        if (!jwt) {
            openNotificationWithIcon('error', 'Unauthorized')
            return navigate('/')
        }

        let allFeatures = features;
        let filterall = allFeatures.filter(feature => feature.id !== id)
        setFeatures(filterall)
    }

    return (
        <DashboardLayout>
            <CategoryNav title="Edit wise9ja plan" breadroute="wise9ja" />
            <MDBox pt={6} pb={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Wise9ja plan
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3} px={2}>
                                {(plans.plan_name) ?
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Wise9ja Plan
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {plans && (
                                                    <Form form={form} onFinish={update_plan}
                                                        initialValues={{
                                                            plan_code: plans.plan_code,
                                                            plan_name: plans.plan_name,
                                                            display_status: plans.display_status,
                                                            period: plans.period,
                                                            durations: plans.durations,
                                                            min_amount: plans.min_amount,
                                                            max_amount: plans.max_amount,
                                                            description: plans.description,
                                                            level_a_value: plans.level_a_value,
                                                            level_b_value: plans.level_b_value,
                                                            unit: plans.unit,
                                                            min_cap_bonus: plans.min_cap_bonus,
                                                            max_cap_bonus: plans.max_cap_bonus,
                                                            min_wallet_cap: plans.min_wallet_cap,
                                                            max_wallet_cap: plans.max_wallet_cap,
                                                            level_a_wallet_value: plans.level_a_wallet_value,
                                                            level_b_wallet_value: plans.level_b_wallet_value,
                                                        }} layout="vertical">
                                                        <Form.Item name="plan_code" label="Plan Code" >
                                                            <Input placeholder="Plan Code" />
                                                        </Form.Item>
                                                        <Form.Item name="plan_name" label="Plan Name" >
                                                            <Input placeholder="Plan Name" />
                                                        </Form.Item>
                                                        <Form.Item name="period" label="Period" >
                                                            <Select>
                                                                <Select.Option value="hours">Hours</Select.Option>
                                                                <Select.Option value="days">Days</Select.Option>
                                                                <Select.Option value="weeks">Weeks</Select.Option>
                                                                <Select.Option value="months">Months</Select.Option>
                                                                <Select.Option value="quaters">Quarters</Select.Option>
                                                                <Select.Option value="years">Years</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="durations" label="Durations ( Months )" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Durations" />
                                                        </Form.Item>
                                                        <Form.Item name="min_amount" label="Min amount to invest" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Min amount to invest" />
                                                        </Form.Item>
                                                        <Form.Item name="max_amount" label="Max amount to invest" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Max amount to invest" />
                                                        </Form.Item>
                                                        <Form.Item name="unit" label="Unit" >
                                                            <Select onChange={handleUnitChange}>
                                                                <Select.Option value="flat">Flat</Select.Option>
                                                                <Select.Option value="percentage">Percentage</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="level_a_value" label={form.getFieldValue("unit") === "flat" ? "Level a upfront instant commission (NGN)" : "Level a upfront instant commission (%)"} >
                                                            <InputNumber style={{ width: "100%" }} placeholder={form.getFieldValue("unit") === "flat" ? "Level a upfront instant commission (NGN)" : "Level a upfront instant commission (%)"} />
                                                        </Form.Item>
                                                        <Form.Item name="level_b_value" label={form.getFieldValue("unit") === "flat" ? "Level b upfront instant commission (NGN)" : "Level b upfront instant commission (%)"}>
                                                            <InputNumber style={{ width: "100%" }} placeholder={form.getFieldValue("unit") === "flat" ? "Level b upfront instant commission (NGN)" : "Level b upfront instant commission (%)"} />
                                                        </Form.Item>
                                                        <Form.Item name="min_cap_bonus" label="Min cap instant commission (NGN)" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Min cap instant commission (NGN)" />
                                                        </Form.Item>
                                                        <Form.Item name="max_cap_bonus" label="Max cap instant commission (NGN)" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Max cap instant commission (NGN)" />
                                                        </Form.Item>
                                                        <Form.Item name="level_a_wallet_value" label={form.getFieldValue("unit") === "flat" ? "Level a wallet bonus (NGN)" : "Level a wallet bonus (%)"} >
                                                            <InputNumber style={{ width: "100%" }} placeholder={form.getFieldValue("unit") === "flat" ? "level a wallet bonus (NGN)" : "level a wallet bonus (%)"} />
                                                        </Form.Item>
                                                        <Form.Item name="level_b_wallet_value" label={form.getFieldValue("unit") === "flat" ? "Level b wallet bonus (NGN)" : "Level b wallet bonus (%)"} >
                                                            <InputNumber style={{ width: "100%" }} placeholder={form.getFieldValue("unit") === "flat" ? "level b wallet bonus (NGN)" : "level b wallet bonus (%)"} />
                                                        </Form.Item>
                                                        <Form.Item name="min_wallet_cap" label="min cap wallet (NGN)" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="min cap wallet (NGN)" />
                                                        </Form.Item>
                                                        <Form.Item name="max_wallet_cap" label="max cap wallet (NGN)" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="max cap wallet (NGN)" />
                                                        </Form.Item>
                                                        <div style={{ marginBottom: "16px" }} >
                                                            <label>Features</label>
                                                            <div style={{ display: "flex", gap: "5px" }}>
                                                                <Input placeholder="Features" value={featureName.name} onChange={(e) => changeFeature(e)} />
                                                                {featureName && featureName.name ?
                                                                    <Button onClick={addFeature} type="primary">Add</Button>
                                                                    :
                                                                    <Button disabled>Add</Button>
                                                                }
                                                            </div>
                                                            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "10px" }}>
                                                                {features &&
                                                                    <>
                                                                        {features.map((feature, index) => (
                                                                            <span key={index} style={{ padding: "5px 10px", borderRadius: "5px", border: "1px solid gray" }}>
                                                                                <span>{feature.name}</span>
                                                                                <span style={{ marginLeft: "10px" }}></span>
                                                                                <EditOutlined onClick={() => EditFeature({ id: feature.id, feature: feature.name })} style={{ marginRight: "10px" }} />
                                                                                <DeleteOutlined onClick={() => deleteFeature({ id: feature.id })} />
                                                                            </span>
                                                                        ))}
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <Form.Item name="display_status" label="Display Status" >
                                                            <Select>
                                                                <Select.Option value={true}>Activate</Select.Option>
                                                                <Select.Option value={false}>De-activate</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="description" label="Category Description" >
                                                            <TextArea rows={4} placeholder="Category Description" />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button htmlType="submit" type="primary">Submit</Button>
                                                        </Form.Item>
                                                    </Form>
                                                )}
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Wisnija Plan
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <Spin indicator={antIcon} />
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                        <Modal centered={true} title="Edit Feature" onCancel={() => setEditModal(false)} open={editModal} footer={null}>
                            <Form
                                layout="vertical"
                                form={form}
                                initialValues={{
                                    feature: featureDetails.feature,
                                }}
                                onFinish={updateFeature}
                            >
                                <Form.Item name="feature" id="feature" label="Feature">
                                    <Input placeholder="feature" />
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary">Update</Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default Wise9ja_edit