/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Button, DatePicker, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Switch, Table, notification } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { _add_delivery_fee, _delete_delivery_fee, _get_delivery_fees, _get_profile } from "common/axios_services";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

function Delivery_Fee() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_plans, setTotal_plans] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [allProducts, setAllProducts] = useState('view_fees');
    const [feesfetched, setFeesFetched] = useState([]);
    const [deliveryFee, setDeliveryFee] = useState('');
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFeeID, setSelectedFeeID] = useState("");
    const [adding, setAdding] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const jwt = localStorage.konnect_admin_token;
    const [addFees, setAddFees] = useState(false);
    const [deleteFees, setDeleteFees] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const getFees = async () => {
        try {
            const addedFees = await _get_delivery_fees({ page, page_size })
            setFeesFetched(addedFees.data.data);
            setTotal_plans(addedFees.data.meta.total)
            setFetchingData(false);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const fetchUser = async () => {
        const user_profile = await _get_profile
            ()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addFees")) {
                setAddFees(true)
            } else {
                setAddFees(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteFees")) {
                setDeleteFees(true)
            } else {
                setDeleteFees(false)
            }
        } else {
            setDeleteFees(false)
            setAddFees(false)
        }
    }

    useEffect(() => {
        getFees()
        fetchUser()
    }, [page, page_size, updateCount])

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Min Capacity',
            dataIndex: 'min_cap',
            align: "right"
        },
        {
            title: 'Max Capacity',
            dataIndex: 'max_cap',
            align: "right"
        },
        {
            title: 'Value',
            dataIndex: 'value',
            align: "right"
        },
        {
            title: 'Unit',
            className: 'column-number',
            dataIndex: 'unit',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const deletePlan = () => {
        setIsModalVisible(true)
    }

    let data = feesfetched.map((fee, index) => {
        return {
            key: index,
            id: fee.id,
            sn: index + 1,
            title: fee.title,
            min_cap: <NumericFormat thousandSeparator={true} displayType={'text'} value={fee.min_cap} />,
            max_cap: <NumericFormat thousandSeparator={true} displayType={'text'} value={fee.max_cap} />,
            value: fee?.value.toFixed(2),
            unit: fee.unit,
            actions: <div><Link to={`/fee/edit/${fee.id}`}>Edit</Link> {deleteFees && <>/
                <Button onClick={() => { deletePlan(); setSelectedFeeID(fee.id) }} type="link" htmlType="submit" className="inline-button">Delete</Button> </>}</div>
        }
    });

    const add_fee = async (values) => {
        setAdding(true)
        try {
            const data = {
                title: values.title,
                min_cap: `${values.min_cap}`,
                max_cap: `${values.max_cap}`,
                value: `${values.value}`,
                unit: values.unit,
                description: values.description,
                is_free: true
            }
            const addFee = await _add_delivery_fee(data)
            setAdding(false)
            setUpdateCount(updateCount + 1)
            setAllProducts('view_fees')
            openNotificationWithIcon('success', addFee.data.message)
        } catch (err) {
            setAdding(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    };

    const finaliseFeeDelete = async () => {
        try {
            const deleteFee = await _delete_delivery_fee({ id: selectedFeeID })
            openNotificationWithIcon('success', deleteFee.data.message)
            setUpdateCount(updateCount + 1)
            setIsModalVisible(false)
        } catch (err) {
            setIsModalVisible(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Delivery Date Settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {allProducts === 'view_fees' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div className="grid-flex">
                                                        Delivery Fees List
                                                        <div>
                                                            {addFees &&
                                                                <Button style={{ marginRight: '10px' }} type="primary" onClick={() => setAllProducts('add_fee')}>Add Delivery Fee</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {fetchingData ?
                                                        <div>
                                                            <Spin indicator={antIcon} />
                                                        </div> :
                                                        <>
                                                            <div className="table-responsive">
                                                                <Table columns={columns} dataSource={data} pagination={false} bordered footer={null} />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_plans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Delivery-Fees`} defaultPageSize={page_size} onChange={change_page} />
                                                            </div>
                                                        </>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                        <Modal title="Delete Delivery-Fee" centered visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
                                            <p>Are you sure you want to delete this delivery fee category?</p>
                                            <div>
                                                <button onClick={() => setIsModalVisible(false)} style={{ border: '1px solid #F0F0F0', borderRadius: 4, padding: '10px 25px', background: 'transparent', marginRight: 10, fontSize: 12, fontWeight: '600' }}
                                                >Cancel</button>
                                                <button onClick={() => finaliseFeeDelete()} style={{ border: '1px solid #FF4A00', borderRadius: 4, padding: '10px 25px', background: '#FF4A00', fontSize: 12, fontWeight: '600', color: '#fff' }}
                                                >Delete</button>
                                            </div>
                                        </Modal>
                                    </Spin>
                                }
                                {allProducts === 'add_fee' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">Add Delivery Fees
                                                    <div>
                                                        <Button type="primary" onClick={() => setAllProducts('view_fees')}>View Delivery Fees</Button>
                                                    </div>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {deliveryFee ? <p className="error-message">{deliveryFee}</p> : ''}
                                                <Form form={form} onFinish={add_fee} initialValues={{
                                                    title: "",
                                                    min_cap: "",
                                                    max_cap: "",
                                                    value: "",
                                                    unit: "",
                                                    description: ""
                                                }} layout="vertical">
                                                    <Form.Item name="title" label="Title" >
                                                        <Input style={{ width: "100%" }} placeholder="Title" />
                                                    </Form.Item>
                                                    <Form.Item name="description" label="Description" >
                                                        <Input style={{ width: "100%" }} placeholder="Description" />
                                                    </Form.Item>
                                                    <Form.Item name="min_cap" label="Min Capacity" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Min Capacity" />
                                                    </Form.Item>
                                                    <Form.Item name="max_cap" label="Max Capacity" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Max Capacity" />
                                                    </Form.Item>
                                                    <Form.Item name="unit" label="Unit" >
                                                        <Select placeholder="Unit">
                                                            <Select.Option value="percentage">Percentage</Select.Option>
                                                            <Select.Option value="flat">Flat</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="value" label="Value" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Value" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {adding ?
                                                            <Button disabled loading type="primary">Submitting..</Button> :
                                                            <Button htmlType="submit" type="primary">Submit</Button>
                                                        }
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Delivery_Fee;
