/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Switch, Table, notification } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import { _edit_wise_status, _get_profile, _wise9ja_plan } from "common/axios_services";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';


function Wise9ja_settings() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [featureName, setFeatureName] = useState("");
    const [features, setFeatures] = useState([]);
    const [total_plans, setTotal_plans] = useState(0);
    const [page_size, setPage_size] = useState(5);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [allProducts, setAllProducts] = useState('wise_nija');
    const [wisePlans, setWisePlans] = useState([]);
    const [categoryErrorMessage, setCategoryErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedPlanID, setSelectedPlanID] = useState("");
    const [updateCount, setUpdateCount] = useState(0);
    const jwt = localStorage.konnect_admin_token;
    const { TextArea } = Input;
    const feature_id = uuidv4()
    const [deleteWise9ja, setDeleteWise9ja] = useState(false);
    const [addWise9ja, setAddWise9ja] = useState(false);
    const [featureDetails, setFeatureDetails] = useState({})
    const [editModal, setEditModal] = useState(false)
    const [unit, setUnit] = useState('percentage');

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };
    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });

    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addWise9ja")) {
                setAddWise9ja(true)
            } else {
                setAddWise9ja(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteWise9ja")) {
                setDeleteWise9ja(true)
            } else {
                setDeleteWise9ja(false)
            }
        } else {
            setDeleteWise9ja(false)
            setAddWise9ja(false)
        }
    }

    const wise9jaPlan = async () => {
        try {
            const plans = await _wise9ja_plan({ page, page_size })
            setFetchingData(false);
            let wise9ja_plan = [];
            plans.data.data.forEach(plan => {
                let storeData = {
                    wise_plan: plan,
                }
                wise9ja_plan.push(storeData);
            })
            setWisePlans(wise9ja_plan);
            setTotal_plans(plans.data.meta.total)
        } catch (err) {
            setFetchingData(false);
            openNotificationWithIcon('error', err.response.data.message);
        }
    }

    const handleUnitChange = (value) => {
        setUnit(value);
    };

    useEffect(() => {
        wise9jaPlan()
        fetchUser()
    }, [page, page_size, updateCount])

    useEffect(() => {
        form.setFields([
            {
                name: 'level_a_value',
                label: `Level a upfront instant commission (${unit === 'flat' ? 'NGN' : '%'})`,
            },
        ]);
        form.setFields([
            {
                name: 'level_b_value',
                label: `Level b upfront instant commission (${unit === 'flat' ? 'NGN' : '%'})`,
            },
        ]);
        form.setFields([
            {
                name: 'level_a_wallet_value',
                label: `Level a wallet bonus (${unit === 'flat' ? 'NGN' : '%'})`,
            },
        ]);
        form.setFields([
            {
                name: 'level_b_wallet_value',
                label: `Level b wallet bonus (${unit === 'flat' ? 'NGN' : '%'})`,
            },
        ]);
    }, [unit]);

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Period',
            dataIndex: 'period',
        },
        {
            title: 'Durations',
            dataIndex: 'durations',
        },
        {
            title: 'Min_amount',
            dataIndex: 'min_amount',
        },
        {
            title: 'Max_amount',
            dataIndex: 'max_amount',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        },
        {
            title: 'Display-Status',
            className: 'column-number',
            dataIndex: 'displayStatus',
            align: 'center',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const deletePlan = () => {
        setIsModalVisible(true)
    }

    const changeStatus = async ({ value, id }) => {
        try {
            const editStatus = await _edit_wise_status({ id, status: value })
            openNotificationWithIcon('success', editStatus.data.message)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    let data = wisePlans.map((wise, index) => {
        return {
            key: index,
            id: wise?.wise_plan?.id,
            name: wise?.wise_plan?.plan_name,
            code: wise?.wise_plan?.plan_code,
            // discountRate: wise?.wise_plan?.discount,
            period: <span style={{ textTransform: 'capitalize' }}>{wise?.wise_plan?.period}</span>,
            durations: wise?.wise_plan?.durations,
            min_amount: wise?.wise_plan?.min_amount,
            max_amount: wise?.wise_plan?.max_amount,
            status: <span style={{ textTransform: "capitalize" }}>{wise?.wise_plan?.status}</span>,
            displayStatus: <Form form={form} initialValues={{ display_status: wise?.wise_plan?.display_status }}><Form.Item name="display_status" valuePropName="checked">{wise?.wise_plan?.display_status ? <Button onClick={() => changeStatus({ value: false, id: wise?.wise_plan?.id })}>Deactivate</Button> : <Button onClick={() => changeStatus({ value: true, id: wise?.wise_plan?.id })}>Activate</Button>}</Form.Item></Form>,
            actions: <div><Link to={`/wisenija/edit/${wise?.wise_plan?.id}`}>Edit</Link>{deleteWise9ja && <> /
                <Button onClick={() => { deletePlan(); setSelectedPlanID(wise?.wise_plan?.id) }} type="link" htmlType="submit" className="inline-button">Delete</Button></>}</div>
        }
    });

    const updateFeature = (values) => {
        setFeatureDetails({})
        if (featureDetails.index !== -1) {
            const updatedFeature = { ...features[featureDetails.index], name: values.feature };
            const updatedFeatures = [...features];
            updatedFeatures[featureDetails.index] = updatedFeature;
            setFeatures(updatedFeatures); // assuming you have a state variable named "features" and a setter function named "setFeatures"
            openNotificationWithIcon('success', "Feature updated successfully, kindly click on the update button to update")
            setEditModal(false)
        } else {
            openNotificationWithIcon('error', "Wise9ja plan not editable, please try again")
            setEditModal(false)
        }
    }

    const deleteFeature = ({ id }) => {
        if (!jwt) {
            openNotificationWithIcon('error', 'Unauthorized')
            return navigate('/')
        }

        let allFeatures = features;
        let filterall = allFeatures.filter(feature => feature.id !== id)
        setFeatures(filterall)
    }
    const add_wise9ja = (values) => {
        axiosCall.post('/wisenija_plan', {
            features,
            plan_code: `${values.plan_code}`,
            plan_name: `${values.plan_name}`,
            display_status: values.display_status,
            durations: `${values.durations}`,
            min_amount: `${values.min_amount}`,
            max_amount: `${values.max_amount}`,
            description: `${values.description}`,
            level_a_value: `${values.level_a_value}`,
            level_b_value: `${values.level_b_value}`,
            unit: `${values.unit}`,
            period: `${values.period}`,
            min_cap_bonus: `${values.min_cap_bonus}`,
            max_cap_bonus: `${values.max_cap_bonus}`,
            min_wallet_cap: `${values.min_wallet_cap}`,
            max_wallet_cap: `${values.max_wallet_cap}`,
            level_a_wallet_value: `${values.level_a_wallet_value}`,
            level_b_wallet_value: `${values.level_b_wallet_value}`,
        }, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            window.location.reload()
        }).catch(err => {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        })
    };

    const addFeature = () => {
        setFeatures([...features, featureName])
        setFeatureName("")
    }

    const finalisePlanDelete = () => {
        axiosCall.delete(`/wisenija_plan/${selectedPlanID}`, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            window.location.reload()
        }).catch(err => {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        })
    }

    const EditFeature = ({ id, feature }) => {
        // if (!jwt) {
        //     openNotificationWithIcon('error', 'Unauthorized')
        //     return navigate('/')
        // }
        const index = features.findIndex(feature => feature.id === id);
        setFeatureDetails({
            index,
            id,
            feature
        })
        form.setFieldsValue({
            feature: feature,
        });
        setEditModal(true)
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Wise9ja Settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {allProducts === 'wise_nija' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p style={{ margin: '0px', fontSize: '1rem' }}>Wise9ja Plans</p>
                                                        {!addWise9ja &&
                                                            <Button type="primary" onClick={() => setAllProducts('add_wise')}>Add Wise9ja Plan</Button>
                                                        }
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {fetchingData ?
                                                        <div>
                                                            <Spin indicator={antIcon} />
                                                        </div> :
                                                        <>
                                                            <div className="table-responsive">
                                                                <Table columns={columns} dataSource={data} bordered footer={null} />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_plans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                                                            </div>
                                                        </>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                        <Modal title="Delete Plan" centered visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
                                            <p>Are you sure you want to delete this Wise9ja Period?</p>
                                            <div>
                                                <button onClick={() => setIsModalVisible(false)} style={{ border: '1px solid #F0F0F0', borderRadius: 4, padding: '10px 25px', background: 'transparent', marginRight: 10, fontSize: 12, fontWeight: '600' }}
                                                >Cancel</button>
                                                <button onClick={() => finalisePlanDelete()} style={{ border: '1px solid #FF4A00', borderRadius: 4, padding: '10px 25px', background: '#FF4A00', fontSize: 12, fontWeight: '600', color: '#fff' }}
                                                >Delete</button>
                                            </div>
                                        </Modal>
                                    </Spin>
                                }
                                {allProducts === 'add_wise' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <p style={{ margin: '0px', fontSize: '1rem' }}>Add Wise9ja Plan</p>
                                                    <Button type="primary" onClick={() => setAllProducts('wise_nija')}>View Wise9ja Plan</Button>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {
                                                    categoryErrorMessage ? <p className="error-message">{categoryErrorMessage}</p> : ''
                                                }
                                                <Form form={form} onFinish={add_wise9ja} initialValues={{
                                                    display_status: false,
                                                    durations: 0,
                                                    min_amount: 0,
                                                    max_amount: 0,
                                                    level_a_value: 0,
                                                    level_b_value: 0,
                                                    unit: "percentage",
                                                    period: "",
                                                    min_cap_bonus: 0,
                                                    max_cap_bonus: 0,
                                                    min_wallet_cap: 0,
                                                    max_wallet_cap: 0,
                                                    level_a_wallet_value: 0,
                                                    level_b_wallet_value: 0,
                                                }} layout="vertical">
                                                    <Form.Item name="plan_code" label="Plan Code" >
                                                        <Input placeholder="Plan Code" />
                                                    </Form.Item>
                                                    <Form.Item name="plan_name" label="Plan Name" >
                                                        <Input placeholder="Plan Name" />
                                                    </Form.Item>
                                                    <Form.Item name="period" label="Period" >
                                                        <Select>
                                                            <Select.Option value="hours">Hours</Select.Option>
                                                            <Select.Option value="days">Days</Select.Option>
                                                            <Select.Option value="weeks">Weeks</Select.Option>
                                                            <Select.Option value="months">Months</Select.Option>
                                                            <Select.Option value="quaters">Quarters</Select.Option>
                                                            <Select.Option value="years">Years</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="durations" label="Durations ( Months )" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Durations" />
                                                    </Form.Item>
                                                    <Form.Item name="min_amount" label="Min amount to invest" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Min amount to invest" />
                                                    </Form.Item>
                                                    <Form.Item name="max_amount" label="Max amount to invest" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Max amount to invest" />
                                                    </Form.Item>
                                                    <Form.Item name="unit" label="Unit" >
                                                        <Select onChange={handleUnitChange}>
                                                            <Select.Option value="flat">Flat</Select.Option>
                                                            <Select.Option value="percentage">Percentage</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="level_a_value" label={form.getFieldValue("unit") === "flat" ? "Level a upfront instant commission (NGN)" : "Level a upfront instant commission (%)"} >
                                                        <InputNumber style={{ width: "100%" }} placeholder={form.getFieldValue("unit") === "flat" ? "Level a upfront instant commission (NGN)" : "Level a upfront instant commission (%)"} />
                                                    </Form.Item>
                                                    <Form.Item name="level_b_value" label={form.getFieldValue("unit") === "flat" ? "Level b upfront instant commission (NGN)" : "Level b upfront instant commission (%)"}>
                                                        <InputNumber style={{ width: "100%" }} placeholder={form.getFieldValue("unit") === "flat" ? "Level b upfront instant commission (NGN)" : "Level b upfront instant commission (%)"} />
                                                    </Form.Item>
                                                    <Form.Item name="min_cap_bonus" label="Min cap instant commission (NGN)" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Min cap instant commission (NGN)" />
                                                    </Form.Item>
                                                    <Form.Item name="max_cap_bonus" label="Max cap instant commission (NGN)" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="Max cap instant commission (NGN)" />
                                                    </Form.Item>
                                                    <Form.Item name="level_a_wallet_value" label={form.getFieldValue("unit") === "flat" ? "Level a wallet bonus (NGN)" : "Level a wallet bonus (%)"} >
                                                        <InputNumber style={{ width: "100%" }} placeholder={form.getFieldValue("unit") === "flat" ? "level a wallet bonus (NGN)" : "level a wallet bonus (%)"} />
                                                    </Form.Item>
                                                    <Form.Item name="level_b_wallet_value" label={form.getFieldValue("unit") === "flat" ? "Level b wallet bonus (NGN)" : "Level b wallet bonus (%)"} >
                                                        <InputNumber style={{ width: "100%" }} placeholder={form.getFieldValue("unit") === "flat" ? "level b wallet bonus (NGN)" : "level b wallet bonus (%)"} />
                                                    </Form.Item>
                                                    <Form.Item name="min_wallet_cap" label="min cap wallet (NGN)" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="min cap wallet (NGN)" />
                                                    </Form.Item>
                                                    <Form.Item name="max_wallet_cap" label="max cap wallet (NGN)" >
                                                        <InputNumber style={{ width: "100%" }} placeholder="max cap wallet (NGN)" />
                                                    </Form.Item>
                                                    <div style={{ marginBottom: "16px" }} >
                                                        <label>Features</label>
                                                        <div style={{ display: "flex", gap: "5px" }}>
                                                            <Input placeholder="Features" onChange={(e) => setFeatureName({ id: feature_id, name: e.target.value })} />
                                                            {featureName && featureName.name ?
                                                                <Button onClick={addFeature} type="primary">Add</Button>
                                                                :
                                                                <Button disabled>Add</Button>
                                                            }
                                                        </div>
                                                        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                                                            {features &&
                                                                <>
                                                                    {features.map((feature) => (
                                                                        <span key={index} style={{ padding: "5px 10px", borderRadius: "5px", border: "1px solid gray" }}>
                                                                            <span>{feature.name}</span>
                                                                            <span style={{ marginLeft: "10px" }}></span>
                                                                            <EditOutlined onClick={() => EditFeature({ id: feature.id, feature: feature.name })} style={{ marginRight: "10px" }} />
                                                                            <DeleteOutlined onClick={() => deleteFeature({ id: feature.id })} />
                                                                        </span>
                                                                    ))}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <Form.Item name="display_status" label="Display Status" >
                                                        <Select>
                                                            <Select.Option value={true}>Activate</Select.Option>
                                                            <Select.Option value={false}>De-activate</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="description" label="Category Description" >
                                                        <TextArea rows={4} placeholder="Category Description" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button htmlType="submit" type="primary">Submit</Button>
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Modal centered={true} title="Edit Feature" onCancel={() => setEditModal(false)} open={editModal} footer={null}>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                        feature: featureDetails.feature,
                    }}
                    onFinish={updateFeature}
                >
                    <Form.Item name="feature" id="feature" label="Feature">
                        <Input placeholder="feature" />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">Update</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Footer />
        </DashboardLayout>
    );
}

export default Wise9ja_settings;
