/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Button, Form, Input, Modal, Pagination, Select, Spin, Table } from "antd";
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Reviews() {
    const [form] = Form.useForm();
    const [userBox, setUserBox] = useState([]);
    const [page, setPage] = useState(1);
    const [total_users, setTotal_users] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [arrayToDisplay, setArrayToDisplay] = useState([]);
    const [displaySwitch, setDisplaySwitch] = useState("view");
    const [creatingUser, setCreatingUser] = useState(false);

    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });

    useEffect(() => {
        axiosCall(`/admin/user?page=${page}&per_page=${page_size}&query=%20&from=1&to=%20`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => {
                setAllUsersFetched(true);
                setUserBox(res.data.data);
                setTotal_users(res.data.meta.total);
                setArrayToDisplay(res.data.data);
            })
            .catch(err => {
                setAllUsersFetched(true);
                setUserBox([]);

            })
    }, [page, page_size])

    const data = [];

    const columns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'First name',
            className: 'column-number',
            dataIndex: 'firstName',
            align: 'center',
        },
        {
            title: 'Last name',
            className: 'column-number',
            dataIndex: 'lastName',
            align: 'center',
        },
        {
            title: 'Email',
            className: 'column-number',
            dataIndex: 'email',
            align: 'center',
        },
        {
            title: 'Phone number',
            className: 'column-number',
            dataIndex: 'phoneNumber',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center'
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setUserBox([]);
    }

    function dataFilter({ search }) {
        let userValue = userBox.filter(value => {
            if (value.user.first_name.toLowerCase().includes(search.toLowerCase())) {
                return value;
            }
        })
        setArrayToDisplay(userValue);
    }

    const onFinish = (values) => {
        console.log(values)
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Reviews
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {/* {displaySwitch === "view" ?
                                        <div>
                                            <label>Filter Users data</label>
                                            <Form layout="vertical" form={form} initialValues={{
                                                search: ""
                                            }} onValuesChange={dataFilter} style={{ maxWidth: 600, minWidth: 400 }}
                                            >
                                                <Form.Item name="search">
                                                    <Input placeholder="search by first name" />
                                                </Form.Item>
                                            </Form>
                                        </div> :
                                        <div></div>
                                    } */}
                                    {/* {displaySwitch === "view" ?
                                        <Button onClick={() => { setDisplaySwitch("create") }}>Create User</Button>
                                        :
                                        <Button onClick={() => { setDisplaySwitch("view") }}>View Users</Button>
                                    } */}
                                </div>
                                {displaySwitch === "view" &&
                                    <div className="table-responsive">
                                        {allUsersFetched ?
                                            <>
                                                <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                <div style={{ marginTop: "2%" }}></div>
                                                {/* <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} /> */}
                                                {/* <div style={{ marginTop: "2%" }}></div> */}
                                            </>
                                            :
                                            <div className="">
                                                <Spin />
                                            </div>
                                        }
                                    </div>
                                }
                                {/* {displaySwitch === "create" &&
                                    <Form layout="vertical" form={form} onFinish={onFinish}>
                                        <Form.Item name="first_name" label="First Name">
                                            <Input placeholder="First Name" />
                                        </Form.Item>
                                        <Form.Item name="last_name" label="Last Name">
                                            <Input placeholder="Last Name" />
                                        </Form.Item>
                                        <Form.Item name="email" label="Email">
                                            <Input placeholder="Email" />
                                        </Form.Item>
                                        <Form.Item name="Role" label="Role">
                                            <Select placeholder="Select Role">
                                                <Select.Option value="user">User</Select.Option>
                                                <Select.Option value="merchant">Merchant</Select.Option>
                                                <Select.Option value="driver">Driver</Select.Option>
                                                <Select.Option value="vendor">Vendor</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="password" label="Password">
                                            <Input.Password placeholder="Password" />
                                        </Form.Item>
                                        <Form.Item name="confirm_password" label="Confirm Password">
                                            <Input.Password placeholder="Confirm Password" />
                                        </Form.Item>
                                        <Form.Item>
                                            {creatingUser ? (
                                                <Button type="primary" loading>Loading</Button>
                                            ) :
                                                <Button type="primary" htmlType="submit">Proceed</Button>
                                            }
                                        </Form.Item>
                                    </Form>
                                } */}
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Reviews;