/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import {Form, Input, Pagination, Spin, Table} from "antd";
import {_get_all_referrals, _get_referral_metrix} from "common/axios_services";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import moment from "moment";
import {useEffect, useState} from "react";
import {NumericFormat} from "react-number-format";
import {Link} from "react-router-dom";

function Referrals() {
    const [userBox, setUserBox] = useState([]);
    const [page, setPage] = useState(1);
    const [total_users, setTotal_users] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [allReferrals, setAllReferrals] = useState([]);
    const [filterDate, setFilterDate] = useState("");
    const [filterToDate, setFilterToDate] = useState(moment().format('YYYY-MM-DD'));
    const [metrix, setMetrix] = useState(null);

    const getAllReferrals = async () => {
        try {
            const referrals = await _get_all_referrals({page, page_size, filterDate, filterToDate})
            setAllUsersFetched(true);
            setUserBox(referrals.data.data);
            setAllReferrals(referrals.data.data);
            setTotal_users(referrals.data.meta.total);
        } catch (err) {
            setAllUsersFetched(true);
            setUserBox([]);
        }
    }
    const getReferralMetrix = async () => {
        setMetrix(null);
        try {
            const referrals = await _get_referral_metrix({filterDate, filterToDate})
            setMetrix(referrals.data.data);
        } catch (err) {
            setMetrix(null);
        }
    }

    useEffect(() => {
        getReferralMetrix()
    }, [filterDate, filterToDate])
    useEffect(() => {
        getAllReferrals()
    }, [page, page_size, filterDate, filterToDate])

    const data = [];
    allReferrals.forEach((user, index) => {
        data.push({
            key: index,
            sn: index + 1,
            refereeName: user?.referral_name,
            verified: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(user?.referred_no) * 1)} />,
            bonus: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(user?.referral_bonus) * 1)} />,
            wise9jaIncome: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(user?.wisenija_income) * 1)} />,
            shopIncome: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(user?.ra_shop_income) * 1)} />,
            action: <a href={`/referrals/${user?.user_id}/${user?.referral_name}`}>View</a>,
        })
    });

    const columns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Referrer Name',
            dataIndex: 'refereeName',
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
        },
        {
            title: 'Bonus (NGN)',
            className: 'column-number',
            dataIndex: 'bonus',
            align: 'center',
        },
        {
            title: 'MI (NGN)',
            className: 'column-number',
            dataIndex: 'wise9jaIncome',
            align: 'center',
        },
        {
            title: 'RI (NGN)',
            className: 'column-number',
            dataIndex: 'shopIncome',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center'
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setUserBox([]);
    }
    const onChangeDate = (e) => setFilterDate(e.target.value);
    const onChangeToDate = (e) => setFilterToDate(e.target.value);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            icon="leaderboard"
                            title="Referrer"
                            count={`${metrix?.total_referee ? metrix?.total_referee : 0}`}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="success"
                            icon="store"
                            title="Referee"
                            count={`${metrix?.total_Referred ? metrix?.total_Referred : 0}`}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="success"
                            icon="store"
                            title="Restaurant"
                            count={`${metrix?.total_so ? metrix?.total_so : 0}`}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="primary"
                            icon="person_add"
                            title="Normal User"
                            count={`${metrix?.total_non_so ? metrix?.total_non_so : 0}`}
                        />
                    </MDBox>
                </Grid>
            </Grid>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Referrals
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div className="flex items-center justify-between mb-3 text-sm">
                                    <Form layout="vertical" style={{maxWidth: 600, minWidth: 300}}>
                                        <Form.Item name="search" className="mb-0">
                                            <Input placeholder="search by first name" className="mb-0" />
                                        </Form.Item>
                                    </Form>
                                    <div className="flex items-end ml-auto gap-10">
                                        <div className="grid-flex">
                                            <p style={{marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)"}}>From</p>
                                            <input id="date" style={{width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)"}} type="date" defaultValue={filterDate} name="date" max={moment().format('YYYY-MM-DD')} onChange={onChangeDate} />
                                        </div>
                                        <div className="grid-flex">
                                            <p style={{marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)"}}>To</p>
                                            <input id="date" style={{width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)"}} max={moment().format('YYYY-MM-DD')} type="date" defaultValue={filterToDate} name="date" onChange={onChangeToDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {allUsersFetched ?
                                        <>
                                            <Table columns={columns} pagination={false} dataSource={data} bordered />
                                            <div style={{marginTop: "2%"}}></div>
                                            <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={page_size} onChange={change_page} />
                                            <div style={{marginTop: "2%"}}></div>
                                        </>
                                        :
                                        <div className="">
                                            <Spin />
                                        </div>
                                    }
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Referrals;
