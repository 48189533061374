/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { CCard, CCardBody } from '@coreui/react';
import { Form, Input, Pagination, Spin, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { _get_all_wallet, _get_wise9ja } from "common/axios_services";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

function Account() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [total_wallettrans, setTotal_wallettrans] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [walletData, setWalletData] = useState([]);

    const jwt = localStorage.konnect_admin_token;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }


    const getWallet = async () => {
        try {
            const fetchAllAcc = await _get_all_wallet({ page, page_size, query })
            setSpinnerLoading(false)
            setWalletData(fetchAllAcc.data.data);
            setTotal_wallettrans(fetchAllAcc.data.meta.total)
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                if (err.response === "Unauthorized") {
                    window.location.reload()
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }
    useEffect(() => {
        getWallet()
    }, [page, page_size, query])

    // Functions to handle
    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    let data = walletData.map((trans, index) => {
        return {
            key: index,
            userRef: trans?.user?.ref_code,
            depositor: <p style={{ textTransform: "capitalize" }}>{trans?.user?.first_name + ' ' + trans?.user?.last_name}</p>,
            block_balance: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.block_balance) * 1)} />,
            voucher_bal: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.voucher_bal) * 1)} />,
            wallet_bal: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.wallet_bal) * 1)} />,
            wisenija_bal: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.wisenija_bal) * 1)} />,
            ledger_balance: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans?.ledger_balance) * 1)} />,
            action: <Link to={`/transactions/${trans?.user_id}`}>View</Link>,
        }
    });

    const columns = [
        {
            title: 'User Ref',
            dataIndex: 'userRef',
        },
        {
            title: 'Depositor',
            dataIndex: 'depositor',
        },
        {
            title: 'Block Balance (NGN)',
            className: 'column-number',
            dataIndex: 'block_balance',
            align: 'right',
        },
        {
            title: 'Voucher Balance (NGN)',
            className: 'column-number',
            dataIndex: 'voucher_bal',
            align: 'right',
        },
        {
            title: 'Wise9ja Balance (NGN)',
            className: 'column-number',
            dataIndex: 'wisenija_bal',
            align: 'right',
        },
        {
            title: 'Wallet Balance (NGN)',
            className: 'column-number',
            dataIndex: 'wallet_bal',
            align: 'right',
        },
        {
            title: 'Ledger Balance (NGN)',
            className: 'column-number',
            dataIndex: 'ledger_balance',
            align: 'right',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        }
    ];

    function dataFilter({ depositor }) {
        setQuery(depositor.toLowerCase())
        setSpinnerLoading(false)
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Wallet Transactions
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
                                    <div className="grid-flex" style={{ fontSize: "14px", marginLeft: "auto" }}>
                                        <label>Depositor's name</label>
                                        <Form layout="vertical" form={form} onValuesChange={dataFilter} initialValues={{ depositor: "" }} style={{ maxWidth: 600, minWidth: 300 }}>
                                            <Form.Item name="depositor">
                                                <Input placeholder="Search by Depositor's Name" />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <CCard>
                                            <CCardBody>
                                                <>
                                                    <div className="table-responsive">
                                                        <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                        <Pagination showSizeChanger current={page} total={total_wallettrans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} transactions`} defaultPageSize={page_size} onChange={change_page} />
                                                    </div>
                                                </>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default Account;