/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Button, Form, Input, Pagination, Select, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { _get_orders } from "common/axios_services";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

function Order() {
    const [form] = Form.useForm()
    const [page, setPage] = useState(1);
    const [orderTotal, setOrdersTotal] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState('');
    const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
    const [filterDate, setFilterDate] = useState("");
    const [filterToDate, setFilterToDate] = useState(moment().format('YYYY-MM-DD'));
    const [query, setQuery] = useState("");

    const jwt = localStorage.konnect_admin_token;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const getOrders = async () => {
        try {
            const fetchAllOrders = await _get_orders({ page, page_size, query, filterDate, filterToDate, selectedDeliveryOption, selectedPaymentOption })
            setSpinnerLoading(false)
            setOrders(fetchAllOrders.data.data);
            setOrdersTotal(fetchAllOrders.data.meta.total)
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                if (err.response === "Unauthorized") {
                    window.location.reload()
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        getOrders()
    }, [page, page_size, query, filterDate, filterToDate, selectedDeliveryOption, selectedPaymentOption])

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    const shortenText = ({ text, maxLength }) => {
        return text.length <= maxLength ? text : text.slice(0, maxLength / 2 - 1) + "..." + text.slice(text.length - maxLength / 2 + 2);
    }

    let data = orders.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            date: order.created_at.split('T')[0],
            ref: shortenText({ text: order.transaction_ref, maxLength: order.transaction_ref.length }),
            quantity: <NumericFormat thousandSeparator={true} displayType={'text'} value={order.total_qty} />,
            weight: <NumericFormat thousandSeparator={true} displayType={'text'} value={order.total_weight} />,
            amount: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order.total_amount) * 1)} />,
            deliveryopt: <p style={{ textTransform: "capitalize" }}>{order.delivery_opt}</p>,
            status: <div style={{ textTransform: "capitalize" }}>{order.status === "success" ? <Tag color="green">{order.status}</Tag> : order.status === "cancel" ? <Tag color="red">{order.status}</Tag> : <Tag color="orange">{order.status}</Tag>}</div>,
            action: <Link to={`/review-order/${order.transaction_ref}`} ><Button type="link">View</Button></Link>,
        }
    });

    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Reference',
            dataIndex: 'ref',
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'quantity',
            align: 'center',
        },
        {
            title: 'Weight (kg)',
            className: 'column-number',
            dataIndex: 'weight',
            align: 'center',
        },
        {
            title: 'Amount Paid (NGN)',
            className: 'column-number',
            dataIndex: 'amount',
            align: 'right',
        },
        {
            title: 'Delivery Option',
            className: 'column-number',
            dataIndex: 'deliveryopt',
            align: 'center',
        },
        {
            title: 'Payment Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ];

    const dataFilter = ({ search }) => {
        setQuery(search.toLowerCase())
    }

    const onChangeDate = (e) => setFilterDate(e.target.value);
    const onChangeToDate = (e) => setFilterToDate(e.target.value);

    const changeDeliveryOption = (value) => {
        setSelectedDeliveryOption(value)
    }

    const changePaymentOption = (value) => {
        setSelectedPaymentOption(value)
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Orders
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <label>Filter Users data</label>
                                        <Form layout="vertical" form={form} initialValues={{ search: "" }} onValuesChange={dataFilter} style={{ maxWidth: 600, minWidth: 300 }}>
                                            <Form.Item name="search">
                                                <Input placeholder="search by first name" />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
                                        <Select placeholder="Delivery option" onChange={changeDeliveryOption} value={selectedDeliveryOption}>
                                            <Select.Option value="">Delivery Options</Select.Option>
                                            <Select.Option value="pickup">Pickup</Select.Option>
                                            <Select.Option value="home">Home</Select.Option>
                                        </Select>
                                        <Select placeholder="Payment option" onChange={changePaymentOption} value={selectedPaymentOption}>
                                            <Select.Option value="">Payment Status</Select.Option>
                                            <Select.Option value="success">Success</Select.Option>
                                            <Select.Option value="pending">Pending</Select.Option>
                                            <Select.Option value="failed">Failed</Select.Option>
                                        </Select>
                                        <div className="grid-flex" style={{ fontSize: "14px" }}>
                                            <p style={{ marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)" }}>From</p>
                                            <input id="date" style={{ width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)" }} type="date" defaultValue={filterDate} name="date" max={moment().format('YYYY-MM-DD')} onChange={onChangeDate} />
                                        </div>
                                        <div className="grid-flex" style={{ fontSize: "14px" }}>
                                            <p style={{ marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)" }}>To</p>
                                            <input id="dates" style={{ width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)" }} max={moment().format('YYYY-MM-DD')} type="date" defaultValue={filterToDate} name="date" onChange={onChangeToDate} />
                                        </div>
                                    </div>
                                </div>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <>
                                            <div className="table-responsive">
                                                <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                <div style={{ marginTop: "2%" }}></div>
                                                <Pagination showSizeChanger current={page} total={orderTotal} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} transactions`} defaultPageSize={page_size} onChange={change_page} />
                                            </div>
                                        </>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default Order;