/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Button, Form, Input, Select, Spin, Table, Upload, notification } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { _add_cms, _add_date, _delete_date, _get_cms, _get_dates, _get_profile } from "common/axios_services";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Cms() {
    const [form] = Form.useForm();
    const [page, setPage] = useState("");
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [imageContent, setImageContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [showDetails, setShowDetails] = useState('view_cms');
    const [cmsfetched, setCmsFetched] = useState([]);
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(true);
    const [selectedDateID, setSelectedDateID] = useState("");
    const [adding, setAdding] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const jwt = localStorage.konnect_admin_token;
    const [cmsType, setCmsType] = useState("image");

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const columns = [
        {
            title: 'S/N',
            dataIndex: 'sn',
        },
        {
            title: 'Page',
            dataIndex: 'page',
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };
    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const getCms = async () => {
        setFetchingData(true);
        try {
            const getCms = await _get_cms({ page, type, name })
            setCmsFetched(getCms.data.data);
            setFetchingData(false);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        getCms()
    }, [page, name, type, updateCount])

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }

    const ImageChange = info => {
        setLoading(true);
        getBase64(info.file.originFileObj, imageUrl => {
            setImageContent(imageUrl);
            setLoading(false);
        });

        const reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);

        reader.addEventListener('load', () => {
            form.setFieldsValue({ image_content: reader.result });
        });
    };

    let data = cmsfetched.map((cms, index) => {
        return {
            sn: index + 1,
            page: cms?.page,
            type: cms?.type,
            name: cms?.name,
            actions: <div><Link to={`/cms/edit/${cms?.name}`}>Edit</Link></div>
        }
    });

    const AddCms = async (values) => {
        setAdding(true)
        try {
            const data = {
                type: values?.type,
                page: values?.page,
                name: values?.name,
                description: values?.description,
                content: values?.type === "image" ? values?.image_content : values?.text_content,
            }
            const addCms = await _add_cms(data)
            setAdding(false)
            setUpdateCount(updateCount + 1)
            setShowDetails('view_cms')
            openNotificationWithIcon('success', addCms?.data?.message)
        } catch (err) {
            setAdding(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const handleFormChange = (changedValues, allValues) => {
        if (allValues.type === "image") {
            setCmsType("image")
        } else if (allValues.type === "text") {
            setCmsType("text")
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white"> Website CMS</MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {showDetails === 'view_cms' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div className="grid-flex" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <p style={{ marginBottom: "0px" }}></p>
                                                        <div>
                                                            <Button style={{ marginRight: '10px' }} type="primary" onClick={() => { setShowDetails('add_cms'); form.resetFields(); setImageContent(null) }} >Add Content</Button>
                                                        </div>
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {fetchingData ?
                                                        <div>
                                                            <Spin indicator={antIcon} />
                                                        </div> :
                                                        <>
                                                            <div className="table-responsive">
                                                                <Table columns={columns} dataSource={data} pagination={false} bordered footer={null} />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                            </div>
                                                        </>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                    </Spin>
                                }
                                {showDetails === 'add_cms' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <p style={{ marginBottom: "0px" }}>Add CMS</p>
                                                    <Button type="primary" onClick={() => { setShowDetails('view_cms'); form.resetFields(); setImageContent(null) }}>View CMS</Button>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {dateErrorMessage ? <p className="error-message">{dateErrorMessage}</p> : ''}
                                                <Form form={form} onFinish={AddCms} onValuesChange={handleFormChange} initialValues={{ type: "image" }} layout="vertical">
                                                    <Form.Item name="page" label="Page" >
                                                        <Select placeholder="Page">
                                                            <Select.Option value="home">Home Page</Select.Option>
                                                            <Select.Option value="wise9ja">wise9ja Page</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="type" label="Type" >
                                                        <Select placeholder="Type">
                                                            <Select.Option value="image">Image</Select.Option>
                                                            <Select.Option value="text">Text</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="name" label="Name" >
                                                        <Input style={{ width: "100%" }} placeholder="Name" />
                                                    </Form.Item>
                                                    {cmsType === "image" ?
                                                        <Form.Item name="image_content" label="Content" >
                                                            <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={ImageChange}>
                                                                {imageContent ? <img src={imageContent} alt="avatar" style={{ width: "130px" }} /> : uploadButton}
                                                            </Upload>
                                                        </Form.Item>
                                                        :
                                                        <Form.Item name="text_content" label="Content" >
                                                            <Input.TextArea rows={4} style={{ width: "100%" }} placeholder="Content" />
                                                        </Form.Item>
                                                    }
                                                    <Form.Item name="description" label="Description" >
                                                        <Input.TextArea rows={4} style={{ width: "100%" }} placeholder="Description" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {adding ?
                                                            <Button disabled loading type="primary">Submitting..</Button> :
                                                            <Button htmlType="submit" type="primary">Submit</Button>
                                                        }
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Cms;
