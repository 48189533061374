/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import { Pagination, Spin, Table, Tag, notification } from "antd";
import { _get_all_payments } from "common/axios_services";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { NumericFormat } from "react-number-format";

function Projects() {
  const [page, setPage] = useState(1);
  const [total_wallettrans, setTotal_wallettrans] = useState(0);
  const [page_size, setPage_size] = useState(10);
  const [allPayments, setAllPayments] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: '',
      placement: 'bottomRight',
      description:
        message,
    });
  };

  const getPayments = async () => {
    setFetchingData(true);
    try {
      const fetchAllPayments = await _get_all_payments({ page, page_size })
      setAllPayments(fetchAllPayments.data.data);
      setFetchingData(false);
      setTotal_wallettrans(fetchAllPayments.data.meta.total)
    } catch (err) {
      setFetchingData(false);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          window.location.reload()
          navigate('/')
          openNotificationWithIcon('error', err.response.data.message);
        } else {
          openNotificationWithIcon('error', err.response.data.message);
        }
      } else {
        openNotificationWithIcon('error', err.message);
      }
    }
  }

  useEffect(() => {
    getPayments()
  }, [page, page_size])

  let data = allPayments.map((trans, index) => {
    return {
      key: index,
      date: trans.created_at.split('T')[0],
      user_name: `${trans.user.last_name + " " + trans.user.first_name}`,
      accType: trans.account_type,
      service: trans.service,
      amount: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans.amount) * 1)} />,
      paymentmethod: trans.payment_method,
      paymenttype: <div style={{ textTransform: "capitalize" }}>{trans.payment_type === "credit" ? <Tag color="green">{trans.payment_type}</Tag> : <Tag color="red">{trans.payment_type}</Tag>}</div>,
      status: <div style={{ textTransform: "capitalize" }}>{trans.status === "success" ? <Tag color="#87d068">{trans.status}</Tag> : trans.status === "cancel" ? <Tag color="#cf1322">{trans.status}</Tag> : <Tag color="#ffa500">{trans.status}</Tag>}</div>,
    }
  });
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Username',
      dataIndex: 'user_name',
    },
    {
      title: 'Account type',
      dataIndex: 'accType',
    },
    {
      title: 'Service',
      className: 'column-number',
      dataIndex: 'service',
      align: 'center',
    },
    {
      title: 'Amount (NGN)',
      className: 'column-number',
      dataIndex: 'amount',
      align: 'right',
    },
    {
      title: 'Payment Method',
      className: 'column-number',
      dataIndex: 'paymentmethod',
      align: 'center',
    },
    {
      title: 'Payment type',
      className: 'column-number',
      dataIndex: 'paymenttype',
      align: 'center',
    },
    {
      title: 'Status',
      className: 'column-number',
      dataIndex: 'status',
      align: 'center',
    }
  ];

  const change_page = (selected_page, pageSize) => {
    setPage(selected_page);
    setPage_size(pageSize);
    setFetchingData(false);
    setAllPayments([]);
  }

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDTypography variant="h6" gutterBottom>
          Transactions
        </MDTypography>
      </MDBox>
      <MDBox px={2}>
        <div className="table-responsive">
          {!fetchingData ?
            <>
              <div className="table-responsive">
                <Table columns={columns} pagination={false} dataSource={data} bordered />
                <div style={{ marginTop: "2%" }}></div>
                <Pagination showSizeChanger current={page} total={total_wallettrans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} transactions`} defaultPageSize={page_size} onChange={change_page} />
                <div style={{ marginTop: "2%" }}></div>
              </div>
            </>
            :
            <div className="">
              <Spin />
            </div>
          }
        </div>
      </MDBox>
    </Card >
  );
}

export default Projects;
