/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBod, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Switch, Table, Upload, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import Jwt_decrypt from "common/Jwt_decrypt";
import axios from "common/axios_call";
import { _get_profile } from "common/axios_services";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Categories() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_categories, setTotal_categories] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [allProducts, setAllProducts] = useState('category');
    const [imageUrl, setImageUrl] = useState(null);
    const [imageToSend, setImageToSend] = useState(null);
    const [loading, setLoading] = useState(false);
    const [categoryBox, setCategoryBox] = useState([]);
    const [categoryErrorMessage, setCategoryErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const [updateCount, setUpdateCount] = useState(0);
    const [addCategories, setAddCategories] = useState(false);
    const [addSubCategories, setAddSubCategories] = useState(false);
    const [deleteCategories, setDeleteCategories] = useState(false);

    const jwt = localStorage.konnect_admin_token;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    const { TextArea } = Input;

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });

    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addCategory")) {
                setAddCategories(true)
            } else {
                setAddCategories(false)
            }
            if (user_profile.data.data.role.role_access.includes("addSubCategory")) {
                setAddSubCategories(true)
            } else {
                setAddSubCategories(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteCategory")) {
                setDeleteCategories(true)
            } else {
                setDeleteCategories(false)
            }
        } else {
            setDeleteCategories(false)
            setAddCategories(false)
            setAddSubCategories(false)
        }
    }
    useEffect(() => {
        axios(`/category?page=${page}&per_page=${page_size}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                setFetchingData(false);
                let categoryData = [];
                res.data.data.forEach(freshData => {
                    let storeData = {
                        categoryData: freshData,
                        subCategories: 0
                    }
                    categoryData.push(storeData);
                })
                setCategoryBox(categoryData);
                setTotal_categories(res.data.meta.total)
            })
            .catch(err => {
                setFetchingData(false);
                openNotificationWithIcon('error', err.response.data.message);
            })
        fetchUser()
    }, [page, page_size, updateCount])

    // const { handleSubmit, control } = useForm({});

    // Functions to handle
    const addCategory = (values) => {
        axios.post('/category', { category_code: values.category_code, category_image: values.category_image, category_name: values.category_name, description: values.description, discount_rate: `${values.discount_rate}`, display_status: values.display_status, }, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            setUpdateCount(updateCount + 1)
        }).catch(err => {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        })
    };

    const addSubCategory = (values) => {
        console.log(values)
        axios.post('/sub-category', {
            subcat_image: values.subcat_image,
            cat_id: values.cat_id,
            subcat_name: values.subcat_name,
            description: values.description,
            subcat_code: values.subcat_code,
            display_status: values.display_status,
            cashback: values.cashback,
            discount_rate: `${values.discount_rate}`,
        }, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            window.location.reload()
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })
    }

    const changeStatus = ({ id, value }) => {
        axios.patch(`/category/display_status/${id}`, { status: value }, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            setUpdateCount(updateCount + 1)
        }).catch(err => {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        })
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>

        </div>
    );
    const deleteCategory = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    }

    const finaliseCategoryDelete = () => {
        setSpinnerLoading(true)
        axios.delete(`/category/${selectedCategoryId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                openNotificationWithIcon('success', res.data.message);
                window.location.reload()
            })
            .catch(err => {
                openNotificationWithIcon('error', err.response.data.message);
                setSpinnerLoading(false);
            });
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }

    const handleChange = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            setImageToSend(reader.result);
            form.setFieldsValue({ category_image: reader.result });
        });
    }
    const selectSubImg = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            setImageToSend(reader.result);
            form.setFieldsValue({ subcat_image: reader.result });
        });
    }

    let data = categoryBox.map((category, index) => {
        return {
            key: index,
            id: category.categoryData.id,
            cat_code: category.categoryData.category_code,
            name: category.categoryData.category_name,
            discountRate: category.categoryData.discount_rate,
            displayStatus: <Form form={form} initialValues={{ display_status: category.categoryData.display_status }}><Form.Item name="display_status" valuePropName="checked">{category.categoryData.display_status ? <Button onClick={() => changeStatus({ value: false, id: category.categoryData.id })}>Deactivate</Button> : <Button onClick={() => changeStatus({ value: true, id: category.categoryData.id })}>Activate</Button>}</Form.Item></Form>,
            actions: <div><Link to={`/categories/edit/${category.categoryData.id}`}>Edit</Link> {deleteCategories && <>/ <form className="inline-form"
                onSubmit={e => { deleteCategory(e); setSelectedCategoryId(category.categoryData.id) }}>
                <input type="hidden" value={category.categoryData.id} name="categoryId" />
                <Button type="link" htmlType="submit" className="inline-button">Delete</Button></form></>}</div>
        }
    });
    const columns = [
        {
            title: 'Category Code',
            dataIndex: 'cat_code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Discount-Rate',
            className: 'column-number',
            dataIndex: 'discountRate',
            align: 'center',
        },
        {
            title: 'Display-Status',
            className: 'column-number',
            dataIndex: 'displayStatus',
            align: 'center',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Categories
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {allProducts === 'category' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div className="grid-flex">
                                                        Categories List
                                                        <div>
                                                            {addSubCategories &&
                                                                <Button style={{ marginRight: '10px' }} type="primary" onClick={() => setAllProducts('sub-category')}>Add Sub-Category</Button>
                                                            }
                                                            {addCategories &&
                                                                <Button type="primary" onClick={() => setAllProducts('add-category')}>Add Category</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {
                                                        fetchingData ?
                                                            <div>
                                                                <Spin indicator={antIcon} />
                                                            </div> :
                                                            <div className="table-responsive">
                                                                <Table columns={columns} dataSource={data} bordered />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_categories} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                                                            </div>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                        <Modal title="Delete Category" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                            <p>Are you sure you want to delete this category?</p>
                                            <div>
                                                <button onClick={() => setIsModalVisible(false)} style={{ border: '1px solid #F0F0F0', borderRadius: 4, padding: '10px 25px', background: 'transparent', marginRight: 10, fontSize: 12, fontWeight: '600' }}
                                                >Cancel</button>
                                                <button onClick={() => finaliseCategoryDelete()} style={{ border: '1px solid #FF4A00', borderRadius: 4, padding: '10px 25px', background: '#FF4A00', fontSize: 12, fontWeight: '600', color: '#fff' }}
                                                >Delete</button>
                                            </div>
                                        </Modal>
                                    </Spin>
                                }
                                {allProducts === 'add-category' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Add Categories
                                                    <div>
                                                        <Button style={{ marginRight: '10px' }} onClick={() => setAllProducts('sub-category')}>Add Sub-Category</Button>
                                                        <Button type="primary" onClick={() => setAllProducts('category')}>View Category</Button>
                                                    </div>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {
                                                    categoryErrorMessage ? <p className="error-message">{categoryErrorMessage}</p> : ''
                                                }
                                                <Form form={form} onFinish={addCategory} initialValues={{ display_status: false }} layout="vertical">
                                                    <Form.Item name="category_image" label="Category Image" >
                                                        <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={handleChange} >
                                                            {imageUrl ? <img src={imageUrl} alt="avatar" className="adminCourseUpload" /> : uploadButton}
                                                        </Upload>
                                                    </Form.Item>
                                                    <Form.Item name="category_name" label="Category Name" >
                                                        <Input placeholder="Category Name" />
                                                    </Form.Item>
                                                    <Form.Item name="category_code" label="Category Code" >
                                                        <Input placeholder="Category Code" />
                                                    </Form.Item>
                                                    <Form.Item name="discount_rate" label="Discount Rate ( % )" >
                                                        <InputNumber style={{ width: "100%" }} min={0} max={100} placeholder="Discount Rate" />
                                                    </Form.Item>
                                                    <Form.Item name="display_status" label="Display Status" >
                                                        <Select>
                                                            <Select.Option value={true}>Activate</Select.Option>
                                                            <Select.Option value={false}>De-activate</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="description" label="Category Description" >
                                                        <TextArea rows={4} placeholder="Category Description" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button htmlType="submit" type="primary">Submit</Button>
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                                {allProducts === 'sub-category' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Add Sub-Categories
                                                    <div>
                                                        <Button style={{ marginRight: '10px' }} type="primary" onClick={() => setAllProducts('category')}>View Category</Button>
                                                        <Button onClick={() => setAllProducts('add-category')}>Add Category</Button>
                                                    </div>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {
                                                    categoryErrorMessage ? <p className="error-message">{categoryErrorMessage}</p> : ''
                                                }
                                                <Form form={form} onFinish={addSubCategory} initialValues={{
                                                    display_status: false,
                                                    cashback: false
                                                }} layout="vertical">
                                                    <Form.Item name="subcat_image" label="Sub-Category Image" >
                                                        <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={selectSubImg} >
                                                            {imageUrl ? <img src={imageUrl} alt="avatar" className="adminCourseUpload" /> : uploadButton}
                                                        </Upload>
                                                    </Form.Item>
                                                    <Form.Item name="cat_id" label="Choose Category" >
                                                        <Select>
                                                            {data.map((title, index) => (
                                                                <Select.Option key={index} value={title.id}>{title.name}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="subcat_name" label="Sub-Category Name" >
                                                        <Input placeholder="Sub-Category Name" />
                                                    </Form.Item>
                                                    <Form.Item name="subcat_code" label="Category Code" >
                                                        <Input placeholder="Category Code" />
                                                    </Form.Item>
                                                    <Form.Item name="discount_rate" label="Discount Rate ( % )" >
                                                        <InputNumber style={{ width: "100%" }} min={0} max={100} placeholder="Discount Rate" />
                                                    </Form.Item>
                                                    <Form.Item name="display_status" label="Display Status" >
                                                        <Select>
                                                            <Select.Option value={true}>Activate</Select.Option>
                                                            <Select.Option value={false}>De-activate</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="cashback" label="Cashback" >
                                                        <Select>
                                                            <Select.Option value={true}>Activate</Select.Option>
                                                            <Select.Option value={false}>De-activate</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="description" label="Sub-Category Description" >
                                                        <TextArea rows={4} placeholder="Category Description" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button htmlType="submit" type="primary">Add Sub-category</Button>
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default Categories;
