import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    confirm_message: ""
};

export const message = createSlice({
    name: "messages",
    initialState,
    reducers: {
        _def_confirm_message: (state, action) => { state.confirm_message = action.payload }
    },
});

export const { _def_confirm_message } = message.actions;

export default message.reducer;