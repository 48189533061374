import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, InputNumber, notification, Select, Spin } from 'antd';
import { _general_settings_by_id, _update_general_settings } from "common/axios_services";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CategoryNav from 'examples/Navbars/CategoryNav';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

const EditGeneralSettings = () => {
    const [form] = Form.useForm();
    const [selectedSettings, setSelectedSettings] = useState([])
    const [editing, setEditing] = useState(false)

    const navigate = useNavigate()
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const params = useParams()

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const getSettings = async () => {
        try {
            const selSettings = await _general_settings_by_id({ id: params.id })
            setSelectedSettings(selSettings.data.data)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        getSettings()
    }, [params.id, navigate])

    const update_settings = async (values) => {
        setEditing(true)
        try {
            const data = {
                title: values?.title,
                unit: values?.unit,
                value: `${values?.value}`,
                app_value: `${values?.app_value}`,
                account_type: `${values?.account_type}`,
                status: 'active',
            }
            const editSettings = await _update_general_settings({ id: selectedSettings?.id, data })
            setEditing(false)
            navigate('/general-settings')
            openNotificationWithIcon('success', editSettings.data.message)
        } catch (err) {
            setEditing(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    return (
        <DashboardLayout>
            <CategoryNav title="Edit General Settings" breadroute="general-settings" />
            <MDBox pt={6} pb={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">Edit Setting</MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3} px={2}>
                                {selectedSettings ?
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">Edit Setting</div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {selectedSettings.value && (
                                                    <Form form={form} onFinish={update_settings} initialValues={{
                                                        title: selectedSettings?.title,
                                                        unit: selectedSettings?.unit,
                                                        value: selectedSettings?.value,
                                                        app_value: selectedSettings?.app_value,
                                                        account_type: selectedSettings?.account_type,
                                                    }} layout="vertical">
                                                        <Form.Item name="title" label="Title" >
                                                            <Input style={{ width: "100%" }} disabled placeholder="Title" />
                                                        </Form.Item>
                                                        <Form.Item name="unit" label="Unit" >
                                                            <Select style={{ width: "100%" }}>
                                                                <Select.Option value="ngn">NGN</Select.Option>
                                                                <Select.Option value="percentage">Percentage</Select.Option>
                                                                <Select.Option value="sec">Seconds</Select.Option>
                                                                <Select.Option value="min">Minutes</Select.Option>
                                                                <Select.Option value="hour">Hour</Select.Option>
                                                                <Select.Option value="days">Days</Select.Option>
                                                                <Select.Option value="weeks">Weeks</Select.Option>
                                                                <Select.Option value="months">Months</Select.Option>
                                                                <Select.Option value="annual">Annual</Select.Option>
                                                                <Select.Option value="times">Times</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="account_type" label="Account Type">
                                                            <Select>
                                                                <Select.Option value="wallet">Wallet</Select.Option>
                                                                <Select.Option value="konnect">Konnect</Select.Option>
                                                                <Select.Option value="wisenija">Wisenija</Select.Option>
                                                                <Select.Option value="voucher">voucher</Select.Option>
                                                                <Select.Option value="card">Card</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="value" label={selectedSettings.service === "Shop" ? "Normal User" : selectedSettings.service === "SOSA-SO-Referral" ? "T1 FV" : "Value"} >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Value" />
                                                        </Form.Item>
                                                        <Form.Item name="app_value" label={selectedSettings.service === "Shop" ? "Food Vendor" : selectedSettings.service === "SOSA-SO-Referral" ? "T2 FV" : "App Value"} >
                                                            <InputNumber style={{ width: "100%" }} placeholder="App Value" />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            {editing ?
                                                                <Button disabled loading type="primary">Submitting</Button> :
                                                                <Button htmlType="submit" type="primary">Submit</Button>
                                                            }
                                                        </Form.Item>
                                                    </Form>
                                                )}
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Settings
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <Spin indicator={antIcon} />
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default EditGeneralSettings