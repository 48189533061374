import { Button, Modal, notification } from "antd";
import { _attach_sa } from "common/axios_services";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setCount } from "splices/updateCountSplice";

const DetachSaComp = ({ info }) => {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [DettachSaModal, setDettachSaModal] = useState(false)
    const [DettachingSa, setDettachingSa] = useState(false)
    const updateCount = useSelector(state => state?.updateCount?.count)

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({ message: title, placement: 'bottomRight', description: message, });
    };


    const dettachSa = async () => {
        setDettachingSa(true)
        try {
            const attcah_so = await _attach_sa({ ref_id: info?.id, user_id: params.id, status: false })
            setDettachingSa(false)
            setDettachSaModal(false)
            dispatch(setCount(updateCount + 1))
            openNotificationWithIcon('success', attcah_so?.data?.title, attcah_so?.data?.message)
        } catch (err) {
            setDettachingSa(false)
            if (err.response) {
                if (err?.response?.data?.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err?.response?.data?.title, err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    return (
        <>
            <Button onClick={() => { setDettachSaModal(true) }}>Dettach SA</Button>
            <Modal centered={true} visible={DettachSaModal} onCancel={() => setDettachSaModal(false)} footer={null}>
                <h5>Dettach SA</h5>
                <p>Are you sure you want to remove <b>{info.fullname}</b> from your SA list.</p>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {DettachingSa ?
                        <Button type="primary" disabled loading>Detaching Sa</Button> :
                        <Button type="primary" onClick={() => dettachSa()}>Detach Sa</Button>
                    }
                    {DettachingSa ?
                        <Button disabled>Cancel</Button> :
                        <Button onClick={() => setDettachSaModal(false)}>Cancel</Button>
                    }
                </div>
            </Modal>
        </>
    )
}

export default DetachSaComp