/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DatePicker, Form, Input, Pagination, Spin, Table, notification } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody } from "@coreui/react";
import { _add_cms, _add_date, _delete_date, _get_cms, _get_dates, _get_inventory_returned, _get_profile } from "common/axios_services";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

function InventoryReturned() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [filterDate, setFilterDate] = useState(moment().format('YYYY-MM-DD'));
    const [filterToDate, setFilterToDate] = useState(moment().format('YYYY-MM-DD'));
    const [total_reports, setTotal_reports] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [reportfetched, setReportFetched] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const jwt = localStorage.konnect_admin_token;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
        },
        {
            title: 'Buyer',
            dataIndex: 'buyer',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];
    const onSearch = (value, _e, info) => setSearch(value)
    const onChangeDate = (e) => setFilterDate(e.target.value);
    const onChangeToDate = (e) => setFilterToDate(e.target.value);
    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };
    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }

    const getReturned = async () => {
        setFetchingData(true);
        try {
            const getRet = await _get_inventory_returned({ page, search, page_size, filterDate, filterToDate, item_id: "", status: "" })
            setReportFetched(getRet.data.data);
            setFetchingData(false);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        getReturned()
    }, [page, page_size, search, filterDate])

    let data = reportfetched.map((report, index) => {
        return {
            sn: index + 1,
            qty: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(report?.qty)} />,
            item_name: report?.item_name,
            // name: report?.name,
            // actions: <div><Link to={`/report/edit/${report?.name}`}>Edit</Link></div>
        }
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">Returned</MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <CCard>
                                            <div className="grid-flex" style={{ marginTop: "5px", background: "#f0f2f5", padding: "1rem", display: "flex", alignItems: "center" }}>
                                                <div className="grid-flex">
                                                    <p style={{ marginBottom: "0px" }}>Date</p>
                                                    <input id="date" style={{ width: "100%", padding: "0px 11px", height: "30px", borderRadius: "6px", border: "1px solid #000" }} type="date" defaultValue={filterDate} name="date" onChange={onChangeDate} />
                                                </div>
                                                <div className="grid-flex" style={{ marginLeft: "20px" }}>
                                                    <p style={{ marginBottom: "0px" }}>To</p>
                                                    <input id="date" style={{ width: "100%", padding: "0px 11px", height: "30px", borderRadius: "6px", border: "1px solid #000" }} type="date" defaultValue={filterToDate} name="date" onChange={onChangeToDate} />
                                                </div>
                                                <div className="grid-flex" style={{ marginLeft: "20px" }}>
                                                    <p style={{ marginBottom: "0px" }}>Search</p>
                                                    <Input.Search placeholder="Search" onSearch={onSearch} allowClear style={{ width: 270 }} />
                                                </div>
                                            </div>
                                            <CCardBody>
                                                {fetchingData ?
                                                    <div>
                                                        <Spin indicator={antIcon} />
                                                    </div> :
                                                    <>
                                                        <div className="table-responsive">
                                                            <Table columns={columns} dataSource={data} pagination={false} bordered footer={null} />
                                                            <div style={{ marginTop: "2%" }}></div>
                                                            <Pagination showSizeChanger current={page} total={total_reports} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Returns`} defaultPageSize={page_size} onChange={change_page} />
                                                        </div>
                                                    </>
                                                }
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default InventoryReturned;
