/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBod, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Switch, Table, Upload, message, notification } from 'antd';
import { City, Country, State } from 'country-state-city';
import React, { useEffect, useState } from 'react';

import Jwt_decrypt from "common/Jwt_decrypt";
import axios from "common/axios_call";
import { _add_state, _delete_state, _edit_state_status, _get_state } from "common/axios_services";
import { Link } from "react-router-dom";
import { _get_profile } from "common/axios_services";

function StatesInfo() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_categories, setTotal_categories] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [updateState, setUpdateState] = useState(0);
    const [allProducts, setAllProducts] = useState('states');
    const [allStates, setAllStates] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addingState, setAddingState] = useState(false);
    const [selectedStateId, setSelectedStateId] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [error_msg, setError_msg] = useState("")
    const [updateCount, setUpdateCount] = useState(1)
    const [addStates, setAddStates] = useState(false)
    const [deleteStates, setDeleteStates] = useState(false)

    const jwt = localStorage.konnect_admin_token;


    // Functions to handle
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const fetchStates = async () => {
        try {
            const uploadedStates = await _get_state({ page, page_size })
            setFetchingData(false);
            setAllStates(uploadedStates.data.data)
            setTotal_categories(uploadedStates.data.meta.total)
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addStates")) {
                setAddStates(true)
            } else {
                setAddStates(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteStates")) {
                setDeleteStates(true)
            } else {
                setDeleteStates(false)
            }
        } else {
            setDeleteStates(false)
            setAddStates(false)
        }
    }
    useEffect(() => {
        fetchStates()
        fetchUser()
    }, [page, page_size, allProducts, updateState, updateCount])
    const changeStatesVals = (values) => {
        if (values.country_code) {
            setCountryCode(values.country_code)
            form.setFieldsValue({
                state: "",
            });
        }
    }

    const addState = async (values) => {
        setAddingState(true)
        try {
            const data = {
                name: values.state.label,
                state_code: values.state.value,
                country_code: countryCode,
                longitude: values.state.title,
                latitude: values.state.key,
            }
            const postState = await _add_state(data)
            setAddingState(false)
            openNotificationWithIcon('success', postState.data.message)
            setAllProducts('states')
        } catch (err) {
            setAddingState(false)
            if (err.response) {
                setError_msg(err.response.data.message)
            } else {
                setError_msg(err.message)
            }
        }
    };

    const deleteCategory = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    }

    const finaliseStateRemoval = async () => {
        setSpinnerLoading(true)
        try {
            const deleteState = await _delete_state({ id: selectedStateId })
            openNotificationWithIcon('success', deleteState.data.message);
            setUpdateState(updateState + 1)
            setSpinnerLoading(false)
            setIsModalVisible(false)
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const changeStatus = async ({ value, id }) => {
        try {
            const editStatus = await _edit_state_status({ id, status: value })
            openNotificationWithIcon('success', editStatus.data.message)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    let data = allStates.map((state, index) => {
        return {
            key: index,
            id: index + 1,
            name: state.name,
            state_code: state.state_code,
            dis_status: <Form form={form} initialValues={{ display_status: state.status }}><Form.Item name="display_status" valuePropName="checked">{state.status ? <Button onClick={() => changeStatus({ value: false, id: state.id })}>Deactivate</Button> : <Button onClick={() => changeStatus({ value: true, id: state.id })}>Activate</Button>}</Form.Item></Form>,
            actions: <div>{deleteStates && <form className="inline-form"
                onSubmit={e => { deleteCategory(e); setSelectedStateId(state.id) }}>
                <input type="hidden" value={state.id} name="categoryId" />
                <Button type="link" htmlType="submit" className="inline-button">Delete</Button></form>}</div>
        }
    });
    const columns = [
        {
            title: 'S/N',
            dataIndex: 'id',
            className: 'column-number',
            align: 'center',
        },
        {
            title: 'State',
            dataIndex: 'name',
        },
        {
            title: 'State Code',
            className: 'column-number',
            dataIndex: 'state_code',
            align: 'center',
        },
        {
            title: 'Display Status',
            className: 'column-number',
            dataIndex: 'dis_status',
            align: 'center',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Delivery settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {allProducts === 'states' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div className="grid-flex">
                                                        List of States
                                                        <div>
                                                            {addStates &&
                                                                <Button type="primary" onClick={() => setAllProducts('add-states')}>Add States</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {
                                                        fetchingData ?
                                                            <div>
                                                                <Spin indicator={antIcon} />
                                                            </div> :
                                                            <>
                                                                <Table columns={columns} dataSource={data} pagination={false} bordered />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_categories} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} state(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                            </>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                        <Modal title="Delete State" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                            <p>Are you sure you want to delete this State?</p>
                                            <div>
                                                <button onClick={() => setIsModalVisible(false)} style={{ border: '1px solid #F0F0F0', borderRadius: 4, padding: '10px 25px', background: 'transparent', marginRight: 10, fontSize: 12, fontWeight: '600' }}
                                                >Cancel</button>
                                                <button onClick={() => finaliseStateRemoval()} style={{ border: '1px solid #FF4A00', borderRadius: 4, padding: '10px 25px', background: '#FF4A00', fontSize: 12, fontWeight: '600', color: '#fff' }}
                                                >Delete</button>
                                            </div>
                                        </Modal>
                                    </Spin>
                                }
                                {allProducts === 'add-states' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Add State
                                                    <div>
                                                        <Button type="primary" onClick={() => setAllProducts('states')}>View States</Button>
                                                    </div>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {error_msg && (<p style={{ color: "red" }}>{error_msg}</p>)}
                                                <Form form={form} onValuesChange={changeStatesVals} onFinish={addState} initialValues={{ display_status: false }} layout="vertical">
                                                    <Form.Item name="country_code" label="Country" >
                                                        <Select placeholder="Select Counrty">
                                                            {Country.getAllCountries().map((country, index) => (
                                                                <Select.Option key={index} value={country.isoCode}>{country.name}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="state" label="State Name" >
                                                        {countryCode ? (
                                                            <Select placeholder="Select State" labelInValue>
                                                                {State.getStatesOfCountry(countryCode).map((state, index) => (
                                                                    <Select.Option key={state.latitude} title={state.longitude} value={state.isoCode}>{state.name}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        ) : (
                                                            <Select placeholder="Select State"></Select>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {addingState ?
                                                            <Button disabled loading type="primary">Submitting....</Button>
                                                            : <Button htmlType="submit" type="primary">Submit</Button>
                                                        }
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default StatesInfo;
