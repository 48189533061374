/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { Done, Edit, Visibility } from "@mui/icons-material";
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Table, Tabs, notification } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { _change_inbound_instore_status, _get_all_inbound, _get_buyer_user_inbound, _get_buyers_inbound, _get_dateby_id, _get_dates, _get_inbound_details, _get_inbound_matrix, _get_items_inbound, _get_orders, _get_profile, _update_inbound_data } from "common/axios_services";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import CategoryNav from 'examples/Navbars/CategoryNav';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactMoment from 'react-moment';
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";

function Inbound() {
    const navigate = useNavigate()
    const params = useParams()
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [fetchingDate, setFetchingDate] = useState(false);
    const [fetchingDateDetais, setFetchingDateDetais] = useState(false);
    const [deliveryDateDetails, setDeliveryDateDetails] = useState(false);
    const [deliveryDates, setDeliveryDates] = useState([]);
    const [inboundData, setInboundData] = useState([]);
    const [fetchingInbound, setFetchingInbound] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [fetchingInboundInfo, setFetchingInboundInfo] = useState(false);
    const [inboundInfo, setInboundInfo] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [inboundTab, setInboundTab] = useState("");
    const [editInbound, setEditInbound] = useState(false);
    const time = moment();
    const [allMatrix, setAllMatrix] = useState(false);
    const [buyerName, setBuyerName] = useState(false);
    const [curTab, setCurTab] = useState("open");

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const fetchDeliveryDates = async () => {
        setFetchingDate(true)
        try {
            const delivery_dates = await _get_dates({ page: 1, page_size: 10 })
            setFetchingDate(false)
            const defaultOption = { value: 'date', key: 'date', date: 'date', id: "date", label: 'None' };
            const optionsWithDefault = [defaultOption, ...delivery_dates.data.data];
            setDeliveryDates(optionsWithDefault)
        } catch (err) {
            setFetchingDate(false)
            setDeliveryDates([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const getInbound = async ({ id }) => {
        setFetchingInbound(true)
        try {
            const delivery_dates = curTab === "buyer" ? await _get_buyers_inbound({ id, page, page_size }) : await _get_items_inbound({ id, page, page_size })
            setFetchingInbound(false)
            setInboundData(delivery_dates.data.data)
        } catch (err) {
            setFetchingInbound(false)
            setInboundData([])
            console.log(err)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getDate = async ({ id }) => {
        setFetchingDateDetais(true)
        try {
            const delivery_date = await _get_dateby_id({ id })
            setFetchingDateDetais(false)
            setDeliveryDateDetails(delivery_date.data.data)
        } catch (err) {
            setFetchingDateDetais(false)
            setDeliveryDateDetails([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const changeInboundStatus = async ({ id }) => {
        try {
            const change_inbound_status = await _change_inbound_instore_status({ id })
            openNotificationWithIcon('success', change_inbound_status.data.message)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const getInboundDetails = async ({ id, value, name }) => {
        setFetchingInboundInfo(true)
        try {
            const inboundInfo = await _get_buyer_user_inbound({ user_id: id, delivery_date_id: params.id })
            setInboundTab(value)
            setDetailsModal(true)
            setInboundInfo(inboundInfo?.data?.data)
            setBuyerName(name)
            setFetchingInboundInfo(false)
        } catch (err) {
            setInboundTab("")
            setDetailsModal(false)
            setInboundInfo(false)
            setFetchingInboundInfo(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchInboundMatrix = async ({ date }) => {
        try {
            const matrix = await _get_inbound_matrix({ date, status: curTab })
            setAllMatrix(matrix.data.data)
        } catch (err) {
            setAllMatrix(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    useEffect(() => {
        fetchDeliveryDates()
        if (params.id && params.id !== "date") {
            getInbound({ id: params.id })
            getDate({ id: params.id })
            fetchInboundMatrix({ date: params.id })
        } else if (params.id && params.id === "date") {
            getInbound({ id: "" })
            fetchInboundMatrix({ date: "" })
            fetchInboundMatrix({ date: "" })
        } else {
            getInbound({ id: "" })
            fetchInboundMatrix({ date: "" })
        }
    }, [page, page_size, updateCount, curTab])

    const selectDeliveryDate = ({ id }) => {
        navigate(`/inbound/${id}`)
        setUpdateCount(updateCount + 1)
    }

    const editInboundDetails = async ({ id }) => {
        setEditInbound(true)
        try {
            const inboundInfo = await _get_inbound_details({ id })
            setInboundInfo(inboundInfo.data.data)
        } catch (err) {
            setInboundInfo(false)
            setEditInbound(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const editInboundInfo = async (values) => {
        try {
            const data = {
                status: "inprogress",
                qty_supplied: `${values.qty_supplied}`,
                comment: `${values.comment}`
            }
            const editInboundData = await _update_inbound_data({ id: inboundInfo.id, data })
            openNotificationWithIcon('success', editInboundData.data.message)
            setEditInbound(false)
            setUpdateCount(updateCount + 1)
            form.resetFields()
        } catch (err) {
            setEditInbound(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const items = [];
    deliveryDates.forEach((date) => {
        items.push({
            key: date.id,
            value: date.date,
            label: <div>{date.label ? date.label : moment.utc(date.date).format("dddd, MMMM Do YYYY")}</div>,
        })
    });
    const openData = inboundData.filter(report => Number(report?.total_buyer) !== 0 && Number(report?.total_qty) !== 0 && Number(report?.total_weight) !== 0).map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            buyer: <NumericFormat thousandSeparator={true} displayType={'text'} value={order?.total_buyer} />,
            items: order?.item_name,
            units: <NumericFormat thousandSeparator={true} displayType={'text'} value={order?.total_qty} />,
            kg: <NumericFormat thousandSeparator={true} displayType={'text'} value={order?.total_weight} />,
            // action: <>
            //     <span style={{ marginRight: "5px", marginBottom: "5px" }}>
            //         <Visibility style={{ cursor: "pointer" }} onClick={() => { getInboundDetails({ id: order.id, value: "open" }) }} />
            //     </span>
            // </>,
        }
    });
    const buyerData = inboundData.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            buyer: order?.buyer_name,
            items: <NumericFormat thousandSeparator={true} displayType={'text'} value={order?.total_items} />,
            units: <NumericFormat thousandSeparator={true} displayType={'text'} value={order?.total_qty} />,
            opt: order?.payment_opt,
            action: <>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    <Visibility style={{ cursor: "pointer" }} onClick={() => { getInboundDetails({ id: order?.user_id, value: "open", name: order?.buyer_name }) }} />
                </span>
            </>,
        }
    });
    const confirmedData = inboundData.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            itemName: order.product_name,
            supplyPrice: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.sellers_price)} />,
            quantityOrdered: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_quantity)} />,
            totalAmount: <NumericFormat thousandSeparator={true} displayType={'text'} value={order.total_amount} />,
            kg: <NumericFormat thousandSeparator={true} displayType={'text'} value={order.total_weight} />,
            action: <>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    <Edit style={{ cursor: "pointer" }} onClick={() => { editInboundDetails({ id: order.id }) }} />
                </span>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    | <Visibility style={{ cursor: "pointer" }} onClick={() => { getInboundDetails({ id: order.id, value: "confirmed", name: order?.buyer_name }) }} />
                </span>
            </>,
        }
    });
    const inprogressData = inboundData.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            itemName: order.product_name,
            supplyPrice: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.sellers_price)} />,
            quantityOrdered: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_quantity)} />,
            totalAmount: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_amount)} />,
            kg: <NumericFormat thousandSeparator={true} displayType={'text'} value={order.total_weight} />,
            action: <>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    <Visibility style={{ cursor: "pointer" }} onClick={() => { getInboundDetails({ id: order.id, value: "inprogress", name: order?.buyer_name }) }} />
                </span>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    | <Done style={{ cursor: "pointer" }} onClick={() => { changeInboundStatus({ id: order.id }) }} />
                </span>
            </>,
        }
    });
    const completedData = inboundData.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            itemName: order.product_name,
            supplyPrice: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.sellers_price)} />,
            quantityOrdered: <NumericFormat thousandSeparator={true} displayType={'text'} value={Number(order.total_quantity)} />,
            kg: <NumericFormat thousandSeparator={true} displayType={'text'} value={order.total_weight} />,
            action: <>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    <Visibility style={{ cursor: "pointer" }} onClick={() => { getInboundDetails({ id: order.id, value: "completed", name: order?.buyer_name }) }} />
                </span>
            </>,
        }
    });
    const completedColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Item',
            className: 'column-number',
            dataIndex: 'itemName',
            align: 'left',
        },
        {
            title: 'SellerPrice',
            className: 'column-number',
            dataIndex: 'supplyPrice',
            align: 'center',
        },
        {
            title: 'Kg',
            className: 'column-number',
            dataIndex: 'kg',
            align: 'center',
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'quantityOrdered',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ];
    const confirmedColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Item',
            className: 'column-number',
            dataIndex: 'itemName',
            align: 'left',
        },
        {
            title: 'Seller Price',
            className: 'column-number',
            dataIndex: 'supplyPrice',
            align: 'center',
        },
        {
            title: 'Kg',
            className: 'column-number',
            dataIndex: 'kg',
            align: 'center',
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'quantityOrdered',
            align: 'center',
        },
        {
            title: 'Total Amount',
            className: 'column-number',
            dataIndex: 'totalAmount',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ];
    const inprogressColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Item',
            className: 'column-number',
            dataIndex: 'itemName',
            align: 'left',
        },
        {
            title: 'Seller Price',
            className: 'column-number',
            dataIndex: 'supplyPrice',
            align: 'center',
        },
        {
            title: 'Kg',
            className: 'column-number',
            dataIndex: 'kg',
            align: 'center',
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'quantityOrdered',
            align: 'center',
        },
        {
            title: 'Total Amount',
            className: 'column-number',
            dataIndex: 'totalAmount',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ];
    const buyersColumn = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Name Of Buyer',
            className: 'column-number',
            dataIndex: 'buyer',
            align: 'left',
        },
        {
            title: 'Item',
            className: 'column-number',
            dataIndex: 'items',
            align: 'center',
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'units',
            align: 'center',
        },
        {
            title: 'Pay Option',
            className: 'column-number',
            dataIndex: 'opt',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ];
    const openColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Items',
            className: 'column-number',
            dataIndex: 'items',
            align: 'left',
        },
        {
            title: 'Buyer',
            className: 'column-number',
            dataIndex: 'buyer',
            align: 'center',
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'units',
            align: 'center',
        },
        {
            title: 'Kg',
            className: 'column-number',
            dataIndex: 'kg',
            align: 'center',
        },
    ];
    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setUserBox([]);
    }

    const changeTab = (key) => {
        if (key === "1") {
            setCurTab("open")
        } else if (key === "2") {
            setCurTab("buyer")
        } else if (key === "3") {
            setCurTab("supplied")
        } else if (key === "4") {
            setCurTab("completed")
        }
    }
    console.log()

    return (
        <DashboardLayout>
            {params.id ?
                <>
                    {deliveryDateDetails &&
                        <CategoryNav title={moment.utc(deliveryDateDetails.date).format("dddd, MMMM Do YYYY")} breadroute="inbound" />
                    }
                </>
                :
                <CategoryNav title="" breadroute="inbound" />
            }
            <div style={{ marginTop: "7px", marginBottom: "16px", }}>
                {fetchingDate ?
                    <></>
                    :
                    <div style={{ display: "flex", justifyContent: "right", gap: "20px", alignItems: "center" }}>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <span>Filter by date:</span>
                            <Select placeholder="Select Date" style={{ width: 220, }} options={items} onChange={(values, selectedOption) => { selectDeliveryDate({ id: selectedOption.key }); }} />
                        </div>
                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div></div>
                                <Button>Export</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {allMatrix ?
                <MDBox py={3}>
                    {/* 4 cat */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="store"
                                    title="Total Buyers"
                                    count={Number(allMatrix.payments)}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="weekend"
                                    title="Total Item"
                                    count={Number(allMatrix.total_items)}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="primary"
                                    icon="person_add"
                                    title="Total Quantity"
                                    count={Number(allMatrix.total_quantity)}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="leaderboard"
                                    title="Total Weight"
                                    count={Number(allMatrix.total_weight)}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox> :
                <MDBox py={3}>
                    {/* 4 cat */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="primary"
                                    icon="person_add"
                                    title="Total Buyers"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="weekend"
                                    title="Total Items"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="store"
                                    title="Total Quantity"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="leaderboard"
                                    title="Total Weight"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Inbound
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <Tabs onChange={changeTab}>
                                        <TabPane tab="Item" key="1">
                                            <div className="table-responsive">
                                                {!fetchingInbound ?
                                                    <>
                                                        <Table columns={openColumns} pagination={false} dataSource={openData} bordered />
                                                    </>

                                                    :
                                                    <div className="">
                                                        <Spin />
                                                    </div>
                                                }
                                            </div>
                                        </TabPane>
                                        <TabPane tab="Buyers" key="2">
                                            <div className="table-responsive">
                                                {!fetchingInbound ?
                                                    <>
                                                        <Table columns={buyersColumn} pagination={false} dataSource={buyerData} bordered />
                                                    </>

                                                    :
                                                    <div className="">
                                                        <Spin />
                                                    </div>
                                                }
                                            </div>
                                        </TabPane>
                                        {/* <TabPane tab="Confirmed" key="2">
                                            <div className="table-responsive">
                                                {!fetchingInbound ?
                                                    <>
                                                        <Table columns={confirmedColumns} pagination={false} dataSource={confirmedData} bordered />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                        <Pagination showSizeChanger current={page} total={total_items} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} item(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                    </>
                                                    :
                                                    <div className="">
                                                        <Spin />
                                                    </div>
                                                }
                                            </div>
                                        </TabPane>
                                        <TabPane tab="Supplied" key="3">
                                            <div className="table-responsive">
                                                {!fetchingInbound ?
                                                    <>
                                                        <Table columns={inprogressColumns} pagination={false} dataSource={inprogressData} bordered />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                        <Pagination showSizeChanger current={page} total={total_items} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} item(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                    </>
                                                    :
                                                    <div className="">
                                                        <Spin />
                                                    </div>
                                                }
                                            </div>
                                        </TabPane>
                                        <TabPane tab="In Store" key="4">
                                            <div className="table-responsive">
                                                {!fetchingInbound ?
                                                    <>
                                                        <Table columns={completedColumns} pagination={false} dataSource={completedData} bordered />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                        <Pagination showSizeChanger current={page} total={total_items} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} item(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                        <div style={{ marginTop: "2%" }}></div>
                                                    </>
                                                    :
                                                    <div className="">
                                                        <Spin />
                                                    </div>
                                                }
                                            </div>
                                        </TabPane> */}
                                    </Tabs>
                                    <div style={{ marginTop: "5%" }}></div>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Modal centered={true} title="" visible={detailsModal} footer={null} onCancel={() => { setDetailsModal(false); setBuyerName(false) }} className="pb-5 pt-5">
                <div style={{ overflowY: "auto", height: "380px" }}>
                    {inboundInfo &&
                        <>
                            <h2>{buyerName}</h2>
                            {inboundInfo.map((data, index) => (
                                <>
                                    <img src={data?.product_image} style={{ width: "100px", height: "100px", }} />
                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                        <p style={{ fontWeight: "semibold" }}>Product Name</p>
                                        <p>{data?.product_name}</p>
                                    </div>
                                    {inboundTab === "open" &&
                                        <>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Unit</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.quantity} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Weight</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.total_weight} />
                                            </div>
                                        </>
                                    }
                                    {inboundTab === "confirmed" &&
                                        <>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Supply Price</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.payments} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Weight</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.total_weight} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Quantity</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.total_quantity} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Paid To Seller</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.sellers_price} />
                                            </div>
                                        </>
                                    }
                                    {inboundTab === "inprogress" &&
                                        <>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Supply Price</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.payments} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Weight</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.total_weight} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Quantity</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.quantity} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Paid To Seller</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.sellers_price} />
                                            </div>
                                        </>
                                    }
                                    {inboundTab === "completed" &&
                                        <>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.payments} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Weight</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.total_weight} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Quantity</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.quantity} />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                                <p style={{ fontWeight: "semibold" }}>Paid To Seller</p>
                                                <NumericFormat thousandSeparator={true} displayType={'text'} value={data?.sellers_price} />
                                            </div>
                                        </>
                                    }
                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                        <p style={{ fontWeight: "semibold" }}>Delivery Date</p>
                                        <ReactMoment format="dddd, MMMM Do YYYY">{data?.delivery_date?.date}</ReactMoment>
                                    </div>
                                    <hr />
                                </>
                            ))}
                        </>
                    }
                </div>
            </Modal>
            <Modal centered={true} title="" visible={editInbound} footer={null} onCancel={() => { setEditInbound(false) }} className="pb-5 pt-5">
                {inboundInfo ?
                    <>
                        <h5>Edit</h5>
                        <Form autoComplete="off" form={form} layout="vertical"
                            initialValues={{
                                id: `${inboundInfo.id}`,
                                product_name: `${inboundInfo.product_name}`,
                                quantity_supplied: "",
                                comment: ""
                            }}
                            onFinish={editInboundInfo}>
                            <Form.Item label="Product Name" name="product_name">
                                <Input disabled placeholder="Product Name" />
                            </Form.Item>
                            <Form.Item label="Quantity Supplied" name="qty_supplied">
                                <InputNumber placeholder="Quantity Supplied" style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item label="Comment" name="comment">
                                <Input placeholder="Comment" />
                            </Form.Item>
                            <Button htmlType='submit'>Update</Button>
                        </Form>
                    </> :
                    <div>
                        <Spin />
                    </div>
                }
            </Modal>
            <Footer />
        </DashboardLayout>
    );
}

export default Inbound;