/* eslint-disable */

import CryptoJS from "crypto-js";

const Jwt_encrypt = ({ token }) => {
    const secretPass = process.env.REACT_APP_JWT_SECRET;
    const data = CryptoJS.AES.encrypt(JSON.stringify(token), secretPass).toString();
    return (data)
}

export default Jwt_encrypt