import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, InputNumber, notification, Select, Spin } from 'antd';
import { _merchant_by_id, _update_merchant_settings } from "common/axios_services";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CategoryNav from 'examples/Navbars/CategoryNav';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

const EditMerchant = () => {
    const [form] = Form.useForm();
    const [selectedSettings, setSelectedSettings] = useState(false)
    const [editing, setEditing] = useState(false)

    const navigate = useNavigate()
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const params = useParams()

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const getSettings = async () => {
        try {
            const selSettings = await _merchant_by_id({ id: params.id })
            setSelectedSettings(selSettings?.data?.data)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        getSettings()
    }, [params.id, navigate])

    const update_settings = async (values) => {
        setEditing(true)
        try {
            const data = {
                category: values?.category,
                name: values?.name,
                institution: values?.institution,
                description: values?.description,
                users_no: `${values?.users_no}`,
                logo: selectedSettings?.users_no,
            }
            const editSettings = await _update_merchant_settings({ id: selectedSettings?.id, data })
            setEditing(false)
            navigate('/merchant')
            openNotificationWithIcon('success', editSettings?.data?.message)
        } catch (err) {
            setEditing(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    return (
        <DashboardLayout>
            <CategoryNav title="Edit Merchant" breadroute="merchant" />
            <MDBox pt={6} pb={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">Edit Merchant</MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3} px={2}>
                                {selectedSettings ?
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">Edit Merchant</div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {selectedSettings && (
                                                    <Form form={form} onFinish={update_settings} initialValues={{
                                                        category: selectedSettings?.category,
                                                        name: selectedSettings?.name,
                                                        institution: selectedSettings?.institution,
                                                        description: selectedSettings?.description,
                                                        users_no: selectedSettings?.users_no,
                                                        logo: "",
                                                    }} layout="vertical">
                                                        <Form.Item name="category" label="Category" >
                                                            <Select placeholder="Category">
                                                                <Select.Option value="general">General</Select.Option>
                                                                <Select.Option value="logistics">Logistics</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="name" label="Name" >
                                                            <Input placeholder="Name" />
                                                        </Form.Item>
                                                        <Form.Item name="institution" label="Institution" >
                                                            <Input style={{ width: "100%" }} placeholder="Institution" />
                                                        </Form.Item>
                                                        <Form.Item name="description" label="Description" >
                                                            <Input style={{ width: "100%" }} placeholder="Description" />
                                                        </Form.Item>
                                                        <Form.Item name="users_no" label="Users Number" >
                                                            <InputNumber style={{ width: "100%" }} placeholder="Users Number" />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            {editing ?
                                                                <Button disabled loading type="primary">Submitting</Button> :
                                                                <Button htmlType="submit" type="primary">Submit</Button>
                                                            }
                                                        </Form.Item>
                                                    </Form>
                                                )}
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Settings
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <Spin indicator={antIcon} />
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default EditMerchant