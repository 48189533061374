import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, Select, Spin, Upload, notification } from 'antd';
import { _cms_by_name, _update_cms } from "common/axios_services";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CategoryNav from 'examples/Navbars/CategoryNav';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

const Cms_Edit = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedCms, setSelectedCms] = useState([])
    const [imageContent, setImageContent] = useState(null);
    const [editing, setEditing] = useState(false)
    const [cmsType, setCmsType] = useState(null)

    const navigate = useNavigate()
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const jwt = localStorage.konnect_admin_token;
    const params = useParams()

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const getCms = async () => {
        try {
            const selCms = await _cms_by_name({ name: decodeURIComponent(params.id) })
            setSelectedCms(selCms.data.data)
            setImageContent(selCms?.data?.data?.content ? selCms?.data?.data?.content : null)
            setCmsType(selCms?.data?.data?.type)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }

    useEffect(() => {
        if (!jwt) {
            openNotificationWithIcon('error', 'Unauthorized')
            return navigate('/')
        }

        getCms()
    }, [params.id, jwt, navigate])

    const ImageChange = info => {
        setLoading(true);
        getBase64(info.file.originFileObj, imageUrl => {
            setImageContent(imageUrl);
            setLoading(false);
        });

        const reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);

        reader.addEventListener('load', () => {
            form.setFieldsValue({ image_content: reader.result });
        });
    };

    const update_cms = async (values) => {
        setEditing(true)
        try {
            const data = {
                page: values?.page,
                type: values?.type,
                description: values?.description,
                content: values?.type === "image" ? values?.image_content : values?.text_content,
                name: values?.name,
            }
            const editDates = await _update_cms({ id: selectedCms?.id, data })
            setEditing(false)
            navigate('/cms')
            openNotificationWithIcon('success', editDates.data.message)
        } catch (err) {
            setEditing(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        <DashboardLayout>
            <CategoryNav title="Edit Cms Content" breadroute="cms" />
            <MDBox pt={6} pb={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">Edit CMS</MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3} px={2}>
                                {selectedCms ?
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">Edit CMS</div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {selectedCms.page && (
                                                    <Form form={form} onFinish={update_cms} initialValues={{
                                                        page: selectedCms.page,
                                                        type: selectedCms.type,
                                                        description: selectedCms.description,
                                                        text_content: selectedCms.type === "text" ? selectedCms.content : null,
                                                        image_content: selectedCms.type === "image" ? selectedCms.content : null,
                                                        name: selectedCms.name,
                                                    }} layout="vertical">
                                                        <Form.Item name="page" label="Page" >
                                                            <Select placeholder="Page" disabled>
                                                                <Select.Option value="home">Home Page</Select.Option>
                                                                <Select.Option value="wise9ja">wise9ja Page</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="type" label="Type" >
                                                            <Select placeholder="Type" disabled>
                                                                <Select.Option value="image">Image</Select.Option>
                                                                <Select.Option value="text">Text</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item name="name" label="Name" >
                                                            <Input style={{ width: "100%" }} disabled placeholder="Name" />
                                                        </Form.Item>
                                                        {cmsType === "image" ?
                                                            <Form.Item name="image_content" label="Content" >
                                                                <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={ImageChange}>
                                                                    {imageContent ? <img src={imageContent} alt="avatar" style={{ width: "130px" }} /> : uploadButton}
                                                                </Upload>
                                                            </Form.Item>
                                                            :
                                                            <Form.Item name="text_content" label="Content" >
                                                                <Input.TextArea rows={4} style={{ width: "100%" }} placeholder="Content" />
                                                            </Form.Item>
                                                        }
                                                        <Form.Item name="description" label="Description" >
                                                            <Input.TextArea rows={4} style={{ width: "100%" }} placeholder="Description" />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            {editing ?
                                                                <Button disabled loading type="primary">Submitting</Button> :
                                                                <Button htmlType="submit" type="primary">Submit</Button>
                                                            }
                                                        </Form.Item>
                                                    </Form>
                                                )}
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit CMS
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <Spin indicator={antIcon} />
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    )
}

export default Cms_Edit