/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Collapse, Image, Input, Spin, Table, Tabs } from 'antd';
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import { useEffect, useState } from "react";


function DriverDetails() {
    const { TabPane } = Tabs;

    const { Panel } = Collapse;

    const [userData, setUserData] = useState({});
    const [loadingUser, setLoadingUser] = useState(true);
    const [userInfo, setUserInfo] = useState(false);

    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });

    useEffect(() => {
        let userId = window.location.href.split('/').length - 1;
        setLoadingUser(true)
        axiosCall(`/driver-profile/${window.location.href.split('/')[userId]}`, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            setLoadingUser(false)
            setUserInfo(res.data.data);
        }).catch(err => {
            setLoadingUser(false)
            console.log(err);
        })
    }, [])


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    User
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div className="">
                                    {userInfo ?
                                        <Tabs type="card">
                                            <TabPane tab="Basic settings" key="1">
                                                <form>
                                                    <div className="form-group">
                                                        <label htmlFor="profileImage">Profile Image</label><br />
                                                        <Image width={150} id="profileImage" src={userInfo.user.image} preview={{ src: userInfo.user.image }} />
                                                    </div>
                                                    <div className="form-flex-2">
                                                        <div className="form-group space">
                                                            <label htmlFor="firstName">First name</label>
                                                            <Input disabled={true} id="firstName" value={userInfo.user.first_name}
                                                                type="text" style={{ height: '2.5rem' }} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="lastName">Last name</label>
                                                            <Input disabled={true} id="lastName" value={userInfo.user.last_name}
                                                                type="text" style={{ height: '2.5rem' }} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="phoneNumber">Phone number</label>
                                                        <Input disabled={true} id="phoneNumber" value={userInfo.user.phone_number}
                                                            type="text" style={{ height: '2.5rem' }} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="emailAddress">Email address</label>
                                                        <Input disabled={true} id="emailAddress" value={userInfo.user.email}
                                                            type="text" style={{ height: '2.5rem' }} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="emailAddress">Home address</label>
                                                        <Input disabled={true} id="emailAddress" value={userInfo.user.home_address}
                                                            type="text" style={{ height: '2.5rem' }} />
                                                    </div>
                                                    <div style={{ marginTop: '5%' }}></div>
                                                </form>
                                            </TabPane>
                                            <TabPane tab="Security settings" key="2">
                                                <div className="accountProtection">
                                                    <form>
                                                        <div className="form-group">
                                                            <label htmlFor="">Reference code:</label>
                                                            <Input disabled={true} id="refCode" value={userInfo.user.ref_code}
                                                                type="text" style={{ height: '2.5rem' }} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Sex:</label>
                                                            <Input disabled={true} id="sex" value={userInfo.user.gender}
                                                                type="text" style={{ height: '2.5rem' }} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Birth month:</label>
                                                            <Input disabled={true} id="birthMonth" value={userInfo.user.dob}
                                                                type="text" style={{ height: '2.5rem' }} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Memorable word:</label>
                                                            <Input disabled={true} id="memorableWord" value={userInfo.user.memorable_word}
                                                                type="text" style={{ height: '2.5rem' }} />
                                                        </div>
                                                    </form>
                                                    <div style={{ marginTop: "5%" }} />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                        : <Spin></Spin>
                                    }
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default DriverDetails;
