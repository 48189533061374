/* eslint-disable */
import { Button, Form, Input, InputNumber, Modal, Select, notification } from 'antd';
import Jwt_decrypt from 'common/Jwt_decrypt';
import axiosCall from 'common/axios_call';
import React, { useState } from 'react';

const SettingModal = ({ setting }) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const jwt = localStorage.konnect_admin_token;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ message: '', placement: 'bottomRight', description: message, });
    };

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const onFinish = (values) => {
        if (!jwt) {
            navigate('/')
            openNotificationWithIcon('error', 'Unauthorized')
        }

        const token = Jwt_decrypt({ token: jwt })
        setConfirmLoading(true);
        axiosCall.patch(`/rate-settings/${setting?.id}/update-header`, { service: values.service, title: values.title, value: `${values.value}`, level_b_value: `${values.level_b_value}`, description: values.description, unit: `${values.unit}`, min_cap: `${values.min_cap}`, max_cap: `${values.max_cap}`, lb_app_value: `${values?.lb_app_value}`, la_app_value: `${values?.la_app_value}`, account_type: `${values?.account_type}`, }, { headers: { "Authorization": `Bearer ${token}` } }).then(res => {
            setConfirmLoading(false);
            if (res.data.status === "success") {
                openNotificationWithIcon('success', res.data.message);
                setOpen(false);
                window.location.reload()
            } else {
                openNotificationWithIcon('error', res.data.message);
            }
        }).catch(err => {
            setConfirmLoading(false);
            openNotificationWithIcon('error', err.response.data.message);
            console.log(err);
        })

    };
    return (
        <>
            <Button type="link" onClick={showModal}>Update</Button>
            <Modal centered open={open} footer={null} onCancel={handleCancel} >
                <div style={{ overflowY: "auto", height: "380px" }}>
                    <h4>{setting?.title}</h4>
                    <Form layout="vertical" form={form} initialValues={{
                        service: setting?.service,
                        title: setting?.title,
                        description: setting?.description,
                        unit: setting?.unit,
                        value: setting?.value,
                        level_b_value: setting?.level_b_value,
                        la_app_value: setting?.la_app_value,
                        lb_app_value: setting?.lb_app_value,
                        min_cap: setting?.min_cap,
                        max_cap: setting?.max_cap,
                        account_type: setting?.account_type,
                        id: setting?.id,
                    }} style={{ maxWidth: 600 }} onFinish={onFinish}
                    >
                        <Form.Item name="service" label="Service">
                            <Input placeholder={setting?.service} />
                        </Form.Item>
                        <Form.Item name="title" label="Title">
                            <Input placeholder={setting?.title} />
                        </Form.Item>
                        <Form.Item name="description" label="Description">
                            <Input placeholder={setting?.description} />
                        </Form.Item>
                        <Form.Item name="unit" label="Unit">
                            <Select>
                                <Select.Option value="percentage">Percentage</Select.Option>
                                <Select.Option value="amount">Amount</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="account_type" label="Account Type">
                            <Select>
                                <Select.Option value="wallet">Wallet</Select.Option>
                                <Select.Option value="konnect">Konnect</Select.Option>
                                <Select.Option value="wisenija">Wisenija</Select.Option>
                                <Select.Option value="voucher">voucher</Select.Option>
                                <Select.Option value="card">Card</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="value" label="L1 Value">
                            <InputNumber min={0} style={{ width: "100%" }} placeholder={setting?.value} />
                        </Form.Item>
                        <Form.Item name="level_b_value" label="L2 Value">
                            <InputNumber min={0} style={{ width: "100%" }} placeholder={setting?.level_b_value} />
                        </Form.Item>
                        <Form.Item name="la_app_value" label="L1 App">
                            <InputNumber min={0} style={{ width: "100%" }} placeholder={setting?.la_app_value} />
                        </Form.Item>
                        <Form.Item name="lb_app_value" label="L2 App">
                            <InputNumber min={0} style={{ width: "100%" }} placeholder={setting?.lb_app_value} />
                        </Form.Item>
                        <Form.Item name="min_cap" label="Min_cap">
                            <InputNumber min={0} style={{ width: "100%" }} placeholder={setting?.min_cap} />
                        </Form.Item>
                        <Form.Item name="max_cap" label="Max_cap">
                            <InputNumber min={0} style={{ width: "100%" }} placeholder={setting?.max_cap} />
                        </Form.Item>
                        <Form.Item>
                            {confirmLoading ? (
                                <Button type="primary" loading>Loading</Button>
                            ) :
                                <Button type="primary" htmlType="submit">Submit</Button>
                            }
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default SettingModal