/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Close, Done, HowToReg, Visibility } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import { Button, Col, Form, Input, InputNumber, Pagination, Row, Select, Spin, Steps, Table, Tag, Upload, notification } from "antd";
import { _add_merchants_user, _approve_merchant, _change_merchant_status, _get_merchant_user, _get_merchants_settings, _get_merchants_users, _get_profile, _get_roles_byusertype, _update_merchant } from "common/axios_services";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Merchants() {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [userBox, setUserBox] = useState([]);
    const [page, setPage] = useState(1);
    const [total_users, setTotal_users] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [arrayToDisplay, setArrayToDisplay] = useState([]);
    const [displaySwitch, setDisplaySwitch] = useState("view");
    const [editStep, setEditStep] = useState(0);
    const [editPersonalInfo, setEditPersonalInfo] = useState({});
    const [merchantsList, setMerchantsList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [idImageUrl, setIdImageUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [updateCount, setUpdateCount] = useState(0);
    const [editLoading, setEditLoading] = useState(false);
    const [merchantProfile, setMerchantProfile] = useState(false);
    const [editingUser, setEditingUser] = useState(false);
    const [createUser, setCreateUser] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    const getProfile = async () => {
        try {
            const user_profile = await _get_profile()
            if (user_profile.data.data.role.role_access.includes("addMerchantUser")) {
                setCreateUser(true)
            } else {
                setCreateUser(false)
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    localStorage.removeItem('konnect_admin_token')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)

                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const fetchMerchantUsers = async () => {
        try {
            const fetch_merchant_users = await _get_merchants_users({ page, page_size, search: "", category: "", merchant_id: "", from: "", to: "" })
            setAllUsersFetched(true);
            setUserBox(fetch_merchant_users.data.data);
            setArrayToDisplay(fetch_merchant_users.data.data);
            setTotal_users(fetch_merchant_users.data.meta.total)
        } catch (err) {
            setAllUsersFetched(false);
            setUserBox([]);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const fetchMerchants = async () => {
        try {
            const fetch_merchant = await _get_merchants_settings({ page: "1", page_size: "10", search: "", category: "" })
            setMerchantsList(fetch_merchant.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const fetchMerchantsRoles = async () => {
        try {
            const fetch_role = await _get_roles_byusertype({ type: "merchant" })
            setRoles(fetch_role.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const changeStatus = async ({ value, id }) => {
        try {
            const change_merchant_status = await _change_merchant_status({ value, id })
            setUpdateCount(updateCount + 1)
            openNotificationWithIcon('success', change_merchant_status.data.message);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }
    const approveMerchant = async ({ value, id }) => {
        try {
            const approve_merchant = await _approve_merchant({ value, id })
            setUpdateCount(updateCount + 1)
            openNotificationWithIcon('success', approve_merchant.data.message);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const fetchMerchantUser = async ({ id }) => {
        try {
            const merchant = await _get_merchant_user({ id })
            setMerchantProfile(merchant.data.data)
            setEditLoading(false)
        } catch (err) {
            setMerchantProfile(false)
            setEditLoading(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        fetchMerchantUsers()
        fetchMerchants()
        fetchMerchantsRoles()
        getProfile()
    }, [page, page_size, updateCount])

    const data = [];
    arrayToDisplay.forEach((user, index) => {
        data.push({
            key: index,
            sn: index + 1,
            userName: user.username,
            email: user.email,
            phoneNumber: user.phone_number,
            merchantName: user.merchant_name,
            roleName: user.user.role.role_name,
            status: <div style={{ textTransform: "capitalize" }}>{user.status === "active" ? <Tag color="green">{user.status}</Tag> : user.status === "suspensed" ? <Tag color="red">{user.status}</Tag> : <Tag color="orange">{user.status}</Tag>}</div>,
            action: <>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    <EditIcon style={{ cursor: "pointer" }} onClick={() => { setDisplaySwitch("edit"); setEditLoading(true); fetchMerchantUser({ id: user.id }) }} />
                </span>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    | <Visibility style={{ cursor: "pointer" }} onClick={() => { navigate(`/users/${user.user.id}/${user.id}/merchants`) }} />
                </span>
                {user.status === "pending" &&
                    <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                        | <HowToReg style={{ cursor: "pointer" }} onClick={() => { approveMerchant({ value: "active", id: user.id }); setUpdateCount(updateCount + 1) }} />
                    </span>
                }
                {user.status === "active" &&
                    <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                        | <Close style={{ cursor: "pointer" }} onClick={() => { changeStatus({ id: user.id, value: "suspend" }); setUpdateCount(updateCount + 1) }} />
                    </span>
                }
                {user.status === "suspend" &&
                    <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                        | <Done style={{ cursor: "pointer" }} onClick={() => { changeStatus({ id: user.id, value: "active" }); setUpdateCount(updateCount + 1) }} />
                    </span>
                }
            </>
        })
    });

    const columns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Username',
            className: 'column-number',
            dataIndex: 'userName',
        },
        {
            title: 'Email',
            className: 'column-number',
            dataIndex: 'email',
        },
        {
            title: 'Phone number',
            className: 'column-number',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Role Name',
            className: 'column-number',
            dataIndex: 'roleName',
        },
        {
            title: 'Merchant',
            className: 'column-number',
            dataIndex: 'merchantName',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center'
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setUserBox([]);
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }

    const handleChange = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setIdImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            form.setFieldsValue({ id_card_image: reader.result });
        });
    }

    const profileChange = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setProfileImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            form.setFieldsValue({ image: reader.result });
        });
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    function dataFilter({ search }) {
        let userValue = userBox.filter(value => {
            if (value.user.first_name.toLowerCase().includes(search.toLowerCase())) {
                return value;
            }
        })
        setArrayToDisplay(userValue);
    }

    const finishEditPersonal = (values) => {
        setEditStep(1)
        setEditPersonalInfo(values)
    }
    const finishEditBusiness = async (values) => {
        const data = {
            role_id: values.role_id,
            merchant_id: values.merchant_id,
            id_card_no: `${values.id_card_no}`,
            id_card_image: values.id_card_image,
            about: values.about,
            possition: values.possition,
            location: values.location,
            first_name: editPersonalInfo.first_name,
            last_name: editPersonalInfo.last_name,
            email: editPersonalInfo.email,
            image: editPersonalInfo.image,
            phone_number: `${editPersonalInfo.phone_number}`,
            gender: editPersonalInfo.gender,
            home_address: editPersonalInfo.home_address,
            state_of_residence: editPersonalInfo.state_of_residence,
            country: editPersonalInfo.country,
            lga: editPersonalInfo.lga,
            nin: `${editPersonalInfo.nin}`
        }
        setEditingUser(true)
        try {
            const update_merchant = await _update_merchant({ data, id: merchantProfile.id })
            setEditingUser(false)
            setUpdateCount(updateCount + 1)
            form.resetFields()
            openNotificationWithIcon('success', update_merchant.data.data.message);
        } catch (err) {
            setEditingUser(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Merchants Users
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {displaySwitch === "view" ?
                                        <div>
                                            <label>Filter Merchants data</label>
                                            <Form layout="vertical" form={form} initialValues={{
                                                search: ""
                                            }} onValuesChange={dataFilter} style={{ maxWidth: 600, minWidth: 400 }}
                                            >
                                                <Form.Item name="search">
                                                    <Input placeholder="search by first name" />
                                                </Form.Item>
                                            </Form>
                                        </div> :
                                        <div></div>
                                    }
                                    {createUser &&
                                        <Button onClick={() => { navigate('/merchants/create-user') }}>Create User</Button>
                                    }
                                </div>
                                {displaySwitch === "view" &&
                                    <div className="table-responsive">
                                        {allUsersFetched ?
                                            <>
                                                <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                <div style={{ marginTop: "2%" }}></div>
                                                <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                                                <div style={{ marginTop: "2%" }}></div>
                                            </>
                                            :
                                            <div className="">
                                                <Spin />
                                            </div>
                                        }
                                    </div>
                                }
                                {displaySwitch === "edit" &&
                                    <>
                                        {editLoading ?
                                            <div className="">
                                                <Spin />
                                            </div>
                                            :
                                            <>
                                                {merchantProfile &&
                                                    <>
                                                        <Steps direction="horizontal" className='my-2' size="small" current={editStep} items={[
                                                            {
                                                                title: 'Personal',
                                                            },
                                                            {
                                                                title: 'Business',
                                                            },
                                                        ]} />
                                                        {editStep === 0 &&
                                                            <Form layout="vertical" form={form} onFinish={finishEditPersonal} initialValues={{
                                                                first_name: merchantProfile.user.first_name,
                                                                last_name: merchantProfile.user.last_name,
                                                                email: merchantProfile.email,
                                                                image: merchantProfile.user.image,
                                                                phone_number: merchantProfile.phone_number,
                                                                gender: merchantProfile.user.gender,
                                                                home_address: merchantProfile.user.home_address,
                                                                state_of_residence: merchantProfile.user.state_of_residence,
                                                                country: merchantProfile.user.country,
                                                                lga: merchantProfile.user.lga,
                                                                nin: merchantProfile.nin
                                                            }}>
                                                                <Form.Item name="image" label="Profile Image" >
                                                                    <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={profileChange} >
                                                                        {merchantProfile.user.image ? <img src={merchantProfile.user.image} alt="avatar" style={{ width: "130px" }} /> : uploadButton}
                                                                    </Upload>
                                                                </Form.Item>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="first_name" label="First Name">
                                                                            <Input placeholder="First Name" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="last_name" label="Last Name">
                                                                            <Input placeholder="Last Name" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="email" label="Email">
                                                                            <Input placeholder="Email" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="phone_number" label="Phone Number">
                                                                            <InputNumber style={{ width: "100%" }} placeholder="Phone Number" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="gender" label="Gender">
                                                                            <Select placeholder="Select Gender">
                                                                                <Select.Option value="male">Male</Select.Option>
                                                                                <Select.Option value="female">Female</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="home_address" label="Home Address">
                                                                            <Input placeholder="Home Address" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="country" label="Country">
                                                                            <Input placeholder="Country" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="state_of_residence" label="State Of Residence">
                                                                            <Input placeholder="State Of Residence" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="lga" label="LGA">
                                                                            <Input style={{ width: "100%" }} placeholder="LGA" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="nin" label="NIN">
                                                                            <InputNumber style={{ width: "100%" }} placeholder="Nin" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div></div>
                                                                    <Form.Item>
                                                                        {editingUser ? (
                                                                            <Button type="primary" loading>Loading</Button>
                                                                        ) :
                                                                            <Button type="primary" htmlType="submit">Proceed</Button>
                                                                        }
                                                                    </Form.Item>
                                                                </div>
                                                            </Form>
                                                        }
                                                        {editStep === 1 &&
                                                            <Form layout="vertical" form={form} onFinish={finishEditBusiness} initialValues={{
                                                                role_id: merchantProfile.role_id,
                                                                merchant_id: merchantProfile.merchant_id,
                                                                id_card_no: merchantProfile.id_card_no,
                                                                id_card_image: merchantProfile.id_card_image,
                                                                about: merchantProfile.about,
                                                                possition: merchantProfile.possition,
                                                                location: merchantProfile.location,
                                                            }}>
                                                                <Form.Item name="id_card_image" label="ID Card Image" >
                                                                    <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={handleChange} >
                                                                        {merchantProfile.id_card_image ? <img src={merchantProfile.id_card_image} alt="avatar" style={{ width: "130px" }} /> : uploadButton}
                                                                    </Upload>
                                                                </Form.Item>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="id_card_no" label="Id Card Number">
                                                                            <InputNumber style={{ width: "100%" }} placeholder="Id Card Number" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="location" label="Office Address">
                                                                            <Input placeholder="Office Address" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="merchant_id" label="Merchant">
                                                                            <Select placeholder="Select Merchant">
                                                                                {merchantsList.map((merchant, index) => (
                                                                                    <Select.Option key={index} value={merchant.merchant_id}>{merchant.name}</Select.Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="role_id" label="Role">
                                                                            <Select placeholder="Select Role">
                                                                                {roles.map((role, index) => (
                                                                                    <Select.Option key={index} value={role.id}>{role.role_name}</Select.Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="possition" label="Position">
                                                                            <Input placeholder="Position" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item name="about" label="About">
                                                                            <Input style={{ width: "100%" }} placeholder="About" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Button onClick={() => setEditStep(0)}>Back</Button>
                                                                    <Form.Item>
                                                                        {editingUser ? (
                                                                            <Button type="primary" loading>Loading</Button>
                                                                        ) :
                                                                            <Button type="primary" htmlType="submit">Proceed</Button>
                                                                        }
                                                                    </Form.Item>
                                                                </div>
                                                            </Form>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Merchants;
