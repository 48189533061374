/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { CheckCircleFilled, } from '@ant-design/icons';
import { CCardBody, } from '@coreui/react';
import { Button, Collapse, Divider, Select, Spin, Timeline, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { _get_each_order, _get_orders, _get_wise9ja } from "common/axios_services";
import moment from 'moment';
import { NumericFormat } from "react-number-format";
import { Link, useParams } from "react-router-dom";

function EachOrder() {
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [order, setOrder] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [flexDirection, setFlexDirection] = useState('row');
    const [display, setDisplay] = useState('grid');
    const [displayNav, setDisplayNav] = useState('block');
    const param = useParams()
    const { Panel } = Collapse;

    const jwt = localStorage.konnect_admin_token;
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };
    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }
    const getOrder = async () => {
        try {
            const fetchEachOrder = await _get_each_order({ id: param.id })
            setSpinnerLoading(false)
            setOrder(fetchEachOrder.data.data);
        } catch (err) {
            setSpinnerLoading(false)
            setFetchingData(false);
            if (err.response) {
                if (err.response === "Unauthorized") {
                    window.location.reload()
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }
    useEffect(() => {
        getOrder()
    }, [param.id])

    const handlePrint = async () => {
        await setDisplay('flex')
        await setFlexDirection('column')
        await setDisplayNav('none')
        window.print();
        await setDisplayNav('block')
    };

    const ComponentToPrint = () => {
        return (
            <div style={{ background: "white" }}>
                <Card>
                    <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                        {order &&
                            <MDTypography variant="h6" color="white">
                                {order?.username} Order
                            </MDTypography>
                        }
                    </MDBox>
                    <MDBox py={3} px={2}>
                        <Spin tip="Loading..." spinning={spinnerLoading}>
                            <React.Fragment>
                                <CCardBody>
                                    <div>
                                        {order &&
                                            (
                                                <div className="cart">
                                                    <div>
                                                        <div className="new_checkout_data contain">
                                                            <div className="mt-2 cart-design-body " style={{ display: display, flexDirection: flexDirection, }}>
                                                                <div>
                                                                    <Collapse defaultActiveKey={['1', '2', '3']} expandIconPosition="start">
                                                                        <Panel header="DELIVERY ADDRESS" key="2" extra={
                                                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                                                                                <CheckCircleFilled />
                                                                            </div>
                                                                        }>
                                                                            {order.delivery_location ?
                                                                                <div className='d-flex flex-column'>
                                                                                    <span style={{ marginLeft: "auto", marginBottom: "10px" }} className="col-12">
                                                                                        <span style={{ textTransform: "capitalize" }}>{order.delivery_opt} Delivery</span><br />
                                                                                        <span style={{ textTransform: "capitalize" }}>{order.delivery_location.Address}</span>
                                                                                        <span style={{ textTransform: "capitalize" }}>{order.delivery_location.city_name}, {order.delivery_location.state} State</span>
                                                                                    </span>
                                                                                </div>
                                                                                :
                                                                                <div className='d-flex flex-column'>
                                                                                    <span style={{ marginLeft: "auto", marginBottom: "10px" }} className="col-12">
                                                                                        <span>No delivery address selected</span>
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </Panel>
                                                                        <Panel header="DELIVERY PROCESS" key="3" extra={<div style={{ display: "flex", gap: "10px", alignItems: "center" }} ><CheckCircleFilled /></div>}>
                                                                            <div className='d-flex flex-column'>
                                                                                <Timeline>
                                                                                    <Timeline.Item color="gray">Order Requested - {moment(order?.created_date).format('ddd, MMMM Do YYYY')}</Timeline.Item>
                                                                                    <Timeline.Item color="yellow">Order In Progress - {moment(order?.created_date).format('ddd, MMMM Do YYYY')}</Timeline.Item>
                                                                                    <Timeline.Item color="green">Delivery Date - {order?.delivery_date}</Timeline.Item>
                                                                                </Timeline>
                                                                            </div>
                                                                        </Panel>
                                                                        <Panel header="ORDER DETAILS" key="1" extra={<div style={{ display: "flex", gap: "10px", alignItems: "center" }} > <CheckCircleFilled /> </div>}>
                                                                            <div>
                                                                                <div>
                                                                                    {order.items.length ?
                                                                                        <div className="">
                                                                                            <div className="cart-design-tab new_cart">
                                                                                                {order.items.map((product, index) => (
                                                                                                    <div key={index}>
                                                                                                        <div className={`${order.items.length - index === 1 ? 'no-border' : ''} cart-body-detail`}>
                                                                                                            <div className="inner-flex new_cart">
                                                                                                                <div className="cart_product_image">
                                                                                                                    <img src={product.product_image} alt="product" />
                                                                                                                </div>
                                                                                                                <div className="cart_product_text">
                                                                                                                    <div className="inner-flex new_cart">
                                                                                                                        <div>
                                                                                                                            <h4>{product.product_name}</h4>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="mobile">
                                                                                                                        <div className="mobile-flex-cover">
                                                                                                                            <div className="mobile-flex">
                                                                                                                                <Select defaultValue={product.quantity}
                                                                                                                                    disabled
                                                                                                                                    className="select-quantity" style={{ width: 80 }}>
                                                                                                                                    <Select.Option value={product.quantity}>{product.quantity}</Select.Option>
                                                                                                                                </Select>
                                                                                                                                <p>
                                                                                                                                    <span className="small_currency">NGN</span>
                                                                                                                                    <NumericFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="small-flex">
                                                                                                                <Select defaultValue={product.quantity}
                                                                                                                    disabled
                                                                                                                    className="select-quantity" style={{ width: 80, margin: '0% auto' }}>
                                                                                                                    <Select.Option value={product.quantity}>{product.quantity}</Select.Option>
                                                                                                                </Select>
                                                                                                            </div>
                                                                                                            <div className="small-flex">
                                                                                                                <p>
                                                                                                                    <span className="small_currency">NGN</span>
                                                                                                                    <NumericFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="empty-cart">
                                                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                                                                description={
                                                                                                    <span>
                                                                                                        You have no products in cart yet? <br />
                                                                                                        <Link to="/shop">Go to shop</Link>
                                                                                                    </span>
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Panel>
                                                                    </Collapse>
                                                                </div>
                                                                <div>
                                                                    <div className="cart-brief">
                                                                        <div className="cart-brief-data">
                                                                            <div>
                                                                                <ul className="first-list">
                                                                                    <li>
                                                                                        <span className="first">Order size </span>
                                                                                        <span className="second">{Number(order.total_weight)} kg</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="first">Order quantity </span>
                                                                                        <span className="second">{Number(order.total_qty)} Item(s)</span>
                                                                                    </li>
                                                                                    <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                                    <li>
                                                                                        <span className="first">Subtotal </span>
                                                                                        <span className="second">
                                                                                            <span className="small_currency">{order.currency} </span>
                                                                                            <NumericFormat prefix="" value={Number(order.total_amount) + Number(order.discount)} className="foo"
                                                                                                displayType={'text'} thousandSeparator={true} />
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="first">Delivery Fee </span>
                                                                                        <span className="second">
                                                                                            <span className="small_currency">{order.currency} </span>
                                                                                            <NumericFormat prefix="" value={order.delivery_fee} className="foo"
                                                                                                displayType={'text'} thousandSeparator={true} />
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="first">Coupon</span>
                                                                                        <span className="second">
                                                                                            <span className="small_currency">{order.currency} </span>
                                                                                            <NumericFormat prefix="" value={order.coupon_amount} className="foo"
                                                                                                displayType={'text'} thousandSeparator={true} />
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="first">Discount</span>
                                                                                        <span className="second">
                                                                                            <span className="small_currency">{order.currency} </span>
                                                                                            <NumericFormat prefix="" value={order.discount} className="foo"
                                                                                                displayType={'text'} thousandSeparator={true} />
                                                                                        </span>
                                                                                    </li>
                                                                                    <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                                    <li>
                                                                                        <span className="first">Total </span>
                                                                                        <span className="second">
                                                                                            <span className="small_currency">{order.currency} </span>
                                                                                            <NumericFormat prefix="" value={order.total_amount} className="foo"
                                                                                                displayType={'text'} thousandSeparator={true} />
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <Link to="/orders">
                                                                                    <Button type="primary" className="mt-3" style={{ width: "100%", height: "3rem" }}>Back to dashboard</Button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </CCardBody>
                            </React.Fragment>
                        </Spin >
                    </MDBox >
                </Card >
            </div>
        );
    };

    return (
        <DashboardLayout>
            <div style={{ display: displayNav }}>
                <DashboardNavbar />
            </div>
            {order &&
                <div style={{ display: "flex" }}>
                    <Button onClick={handlePrint} style={{ marginLeft: "auto" }}>Print</Button>
                </div>
            }
            <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <ComponentToPrint />
                    </Grid >
                </Grid >
            </MDBox >
            <Footer />
        </DashboardLayout >
    );

};

export default EachOrder;
