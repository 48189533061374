/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Form Submission Component
import { useFormik } from "formik";

// Images
import { notification } from "antd";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Jwt_encrypt from "common/Jwt_encrypt";
import axios from "common/axios_call";

function Basic() {
  let params = new URLSearchParams(window?.location?.search);
  const navigate = useNavigate()

  const [rememberMe, setRememberMe] = useState(false);
  const [error_msg, setError_msg] = useState(false);
  const [logingin, setLogingin] = useState(false);
  const [logout,] = useState(params.get('logout') ? params.get('logout') : '');
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: "",
      description: message,
      placement: "bottom-right"
    });
  };

  // Formik authentication
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      app: "web",
      role_id: 4,
      device_id: "",
      device_type: ""
    },
    onSubmit: (values) => {
      setLogingin(true)
      axios.post('/auth/login', values).then(res => {
        setLogingin(false)
        localStorage.setItem('konnect_admin_token', Jwt_encrypt({ token: res.data.data.token }))
        window.location.href = '/dashboard'
      }).catch(err => {
        if (err.response) {
          setError_msg(err.response.data.message)
        } else {
          setError_msg(err.message)
        }
        setLogingin(false)
      })
    },
  })

  useEffect(() => {
    if (logout) {
      openNotificationWithIcon('success', "Logged out successfully")
      localStorage.removeItem('konnect_admin_token')
    }
  }, [logout])


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadowx="info" mx={2} mt={-3} p={2} mb={1} textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>Sign in</MDTypography>
        </MDBox>
        <MDBox pt={1} pb={3} px={3}>
          <MDTypography style={{ color: "red", fontSize: "16px" }} mb={2}>{error_msg}</MDTypography>
          <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" name="email" onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" name="password" onChange={formik.handleChange} value={formik.values.password} onBlur={formik.handleBlur} fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography variant="button" fontWeight="regular" color="text" onClick={handleSetRememberMe} sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}> &nbsp;&nbsp;Remember me</MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {logingin ?
                <MDButton loading variants="gradient" color="info" fullWidth>Signing In...</MDButton> :
                <MDButton type='submit' name='submit' variants="gradient" color="info" fullWidth>Sign in</MDButton>
              }
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
