/* eslint-disable */

import { configureStore } from '@reduxjs/toolkit'
import featureSplice from 'splices/featureSplice'
import message from 'splices/message'
import profileSlice from 'splices/profileSplice'
import updateCountSplice from 'splices/updateCountSplice'

export default configureStore({
    reducer: {
        userProfile: profileSlice,
        // userProfile: featureSplice,
        updateCount: updateCountSplice,
        messages: message,
    },
})  