/* eslint-disable */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBod, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Switch, Table, Upload, message, notification } from 'antd';
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import CategoryNav from 'examples/Navbars/CategoryNav';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const EditSubCategory = () => {
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState(null);
    const [imageToSend, setImageToSend] = useState(null);
    const [loading, setLoading] = useState(false);
    const [subCategoryBox, setSubCategoryBox] = useState({});
    const [categoryErrorMessage, setCategoryErrorMessage] = useState('');
    const navigate = useNavigate()
    const jwt = localStorage.konnect_admin_token;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const { TextArea } = Input;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ message: '', placement: 'bottomRight', description: message, });
    };

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });
    let cat_id = window.location.href.split('/')[window.location.href.split('/').length - 1];
    useEffect(() => {
        axiosCall(`/sub-category/${cat_id}`, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            setSubCategoryBox(res.data.data)
            setImageUrl(res.data.data.subcat_image)
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })
    }, [])

    const updateSubCategory = (values) => {
        axiosCall.patch(`/sub-category/${cat_id}`, { cat_id: subCategoryBox.cat_id, subcat_name: values.subcat_name, subcat_image: values.subcat_image, subcat_code: values.subcat_code, display_status: values.display_status, description: values.description, cashback: `${values.cashback}`, discount_rate: `${values.discount_rate}`, }, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            navigate('/sub-categories')
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }
    const handleChange = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            setImageToSend(reader.result);
            form.setFieldsValue({ subcat_image: reader.result });
        });
    }
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <DashboardLayout>
            <CategoryNav title="Edit Sub-category" breadroute="editsubcategory" />
            <MDBox pt={6} pb={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Sub-Category
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} pb={3} px={2}>
                                {(subCategoryBox.subcat_name) ?
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Sub-Category
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {
                                                    categoryErrorMessage.length ? <p className="error-message">{categoryErrorMessage}</p> : ''
                                                }
                                                <Form form={form} onFinish={updateSubCategory} initialValues={{ subcat_image: subCategoryBox.subcat_image, cat_id: subCategoryBox.cat_id, subcat_name: subCategoryBox.subcat_name, subcat_code: subCategoryBox.subcat_code, display_status: subCategoryBox.display_status, discount_rate: subCategoryBox.discount_rate, description: subCategoryBox.description, cashback: subCategoryBox.cashback, }} layout="vertical">
                                                    <Form.Item name="subcat_image" label="sub-category Image" >
                                                        <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={handleChange} >
                                                            {imageUrl ? <img src={imageUrl} alt="avatar" className="adminCourseUpload" /> : uploadButton}
                                                        </Upload>
                                                    </Form.Item>
                                                    <Form.Item name="subcat_name" label="Sub-category Name" >
                                                        <Input placeholder="Sub-Category Name" />
                                                    </Form.Item>
                                                    <Form.Item name="subcat_code" label="Sub-category Code" >
                                                        <Input placeholder="Sub-Category Code" />
                                                    </Form.Item>
                                                    <Form.Item name="discount_rate" label="Discount Rate ( % )" >
                                                        <InputNumber style={{ width: "100%" }} min={0} max={100} placeholder="Discount Rate" />
                                                    </Form.Item>
                                                    <Form.Item name="cashback" label="Cashback ( % )" >
                                                        <InputNumber style={{ width: "100%" }} min={0} max={100} placeholder="Cashback" />
                                                    </Form.Item>
                                                    <Form.Item name="display_status" label="Display Status" >
                                                        <Select>
                                                            <Select.Option value={true}>Activate</Select.Option>
                                                            <Select.Option value={false}>De-activate</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="description" label="Category Description" >
                                                        <TextArea rows={4} placeholder="Category Description" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button htmlType="submit" type="primary">Submit</Button>
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Edit Sub-Category
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                <Spin indicator={antIcon} />
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout >
    )
}

export default EditSubCategory;