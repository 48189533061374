import {Card, Typography} from 'antd';
import React from 'react';

const {Title, Text} = Typography;

const FoodCard = ({food}) => {
	const {name, description, price, qty_available, discount, images} = food;

	// Helper function to truncate description text
	const truncateText = (text, maxLength = 20) => {
		if (text.length > maxLength) {
			return `${text.substring(0, maxLength)}...`;
		}
		return text;
	};

	const menuImage = images[0]?.url ? images[0]?.url : 'https://dummyimage.com/240/000000/FFFFFF'

	return (
		<Card className="food-card" cover={<img alt={name} src={menuImage} />} hoverable>
			<div className="food-info" style={{paddingBottom: '10px'}}>
				<Title level={5}>{name}</Title>
				<Text type="secondary">{truncateText(description)}</Text>
				<div className="flex justify-between items-center">
					{discount > 0 && (
						<div>
							<Text delete>NGN {price}</Text>
						</div>
					)}
					<Text strong style={{marginLeft: discount > 0 ? '10px' : '0'}}>
						NGN {price - (price * discount) / 100}
					</Text>
				</div>
			</div>
		</Card>
	);
};

export default FoodCard;