/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {Form, Input, Pagination, Select, Spin, Table, notification} from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Material Dashboard 2 React example components
import {LoadingOutlined} from '@ant-design/icons';
import {CCardBody} from "@coreui/react";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, {useEffect, useState} from "react";
import {_get_restaurants, _get_restaurant_metrix} from "common/axios_services";
import moment from "moment";


const TodaysSales = () => {
	const [form] = Form.useForm()
	const [page, setPage] = useState(1);
	const [total_restaurant, setTotal_restaurant] = useState(0);
	const [page_size, setPage_size] = useState(5);
	const [spinnerLoading, setSpinnerLoading] = useState(false);
	const [restaurantList, setRestaurantList] = useState([]);
	const [fetchingData, setFetchingData] = useState(true);
	const [filterDate, setFilterDate] = useState("");
	const [filterToDate, setFilterToDate] = useState(moment().format('YYYY-MM-DD'));
	const [metrix, setMetrix] = useState({});
	const [isOpenValue, setIsOpenValue] = useState(true)
	const [query, setQuery] = useState("")

	const openNotificationWithIcon = (type, message) => {
		notification[type]({placement: 'bottomRight', description: message, });
	};
	const onChangeDate = (e) => setFilterDate(e.target.value);
	const onChangeToDate = (e) => setFilterToDate(e.target.value);

	const handleIsOpenChange = (value) => {
		setIsOpenValue(value);
	};

	function dataFilter({search}) {
		setQuery(search.toLowerCase())
	}

	const FetchRestaurant = async () => {
		try {
			setFetchingData(true);
			const fetch_restaurants = await _get_restaurants({page, page_size, search: query, from: filterDate, to: filterToDate, is_open: true})
			setRestaurantList(fetch_restaurants.data.data);
			setTotal_restaurant(fetch_restaurants.data.meta.total)
		} catch (err) {
			if (err.response) {
				openNotificationWithIcon('error', err.response.data.message);
			} else {
				openNotificationWithIcon('error', err.message);
			}
		} finally {
			setFetchingData(false);
		}
	}

	const GetRestaurantMetrix = async () => {
		try {
			const get_restaurant_metrix = await _get_restaurant_metrix({filterDate, filterToDate})
			setMetrix(get_restaurant_metrix.data.data);
		} catch (err) {
			setMetrix(null);
			if (err.response) {
				if (err.response.data.message === "Unauthorized") {
					navigate('/')
					openNotificationWithIcon('error', err.response.data.message)
				} else {
					openNotificationWithIcon('error', err.response.data.message)
				}
			} else {
				openNotificationWithIcon('error', err.message)
			}
		}
	}

	useEffect(() => {
		GetRestaurantMetrix()
	}, [filterDate, filterToDate])

	useEffect(() => {
		FetchRestaurant()
	}, [page, page_size, filterDate, filterToDate])

	const columns = [
		{
			title: '#',
			dataIndex: 'sn',
		},
		{
			title: 'Buyer',
			dataIndex: 'buyer',
		},
		{
			title: 'Distance',
			dataIndex: 'distance',
		},
		{
			title: 'Sales NGN',
			dataIndex: 'sales_ngn',
		},
		{
			title: 'Status',
			dataIndex: 'Status',
			align: 'center',
		},
		{
			title: 'On Time',
			dataIndex: 'on_time',
		},
		{
			title: 'View',
			className: 'column-number',
			dataIndex: 'view',
			align: 'center',
		}
	];

	const change_page = (selected_page, pageSize) => {
		setPage(selected_page);
		setPage_size(pageSize);
	}

	const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />;

	let data = [];


	return (
		<DashboardLayout>
			<DashboardNavbar />
			<Grid container spacing={3}>
				<Grid item xs={12} md={6} lg={3}>
					<MDBox mb={1.5}>
						<ComplexStatisticsCard
							color="success"
							icon="order"
							title="Order"
							count={`${metrix?.order ? metrix?.order : 0}`}
						/>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MDBox mb={1.5}>
						<ComplexStatisticsCard
							icon="naira"
							title="Order NGN"
							count={`${metrix?.order_ngn ? metrix?.order_ngn : 0}`}
						/>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MDBox mb={1.5}>
						<ComplexStatisticsCard
							color="success"
							icon="delivered"
							title="Delivered"
							count={`${metrix?.delivered ? metrix?.delivered : 0}`}
						/>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MDBox mb={1.5}>
						<ComplexStatisticsCard
							color="primary"
							icon="pending"
							title="Pending"
							count={`${metrix?.pending ? metrix?.pending : 0}`}
						/>
					</MDBox>
				</Grid>
			</Grid>

			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
								<MDTypography variant="h6" color="white">
									Today's Sales
								</MDTypography>
							</MDBox>
							<MDBox py={3} px={2}>
								<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
									<div>
										<label>Filter sales data</label>
										<Form layout="vertical" form={form} initialValues={{search: ""}} onValuesChange={dataFilter} style={{maxWidth: 600, minWidth: 300}}>
											<Form.Item name="search">
												<Input placeholder="search by restaurant keyword" />
											</Form.Item>
										</Form>
									</div>
									<div style={{display: "flex", alignItems: "flex-end", gap: "10px"}}>
										<Select placeholder="Is Open" onChange={handleIsOpenChange} value={isOpenValue} >
											<Select.Option value={true}>True</Select.Option>
											<Select.Option value={false}>False</Select.Option>
										</Select>
										<div className="grid-flex" style={{fontSize: "14px"}}>
											<p style={{marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)"}}>From</p>
											<input id="date" style={{width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)"}} type="date" defaultValue={filterDate} name="date" max={moment().format('YYYY-MM-DD')} onChange={onChangeDate} />
										</div>
										<div className="grid-flex" style={{fontSize: "14px"}}>
											<p style={{marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)"}}>To</p>
											<input id="date" style={{width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)"}} max={moment().format('YYYY-MM-DD')} type="date" defaultValue={filterToDate} name="date" onChange={onChangeToDate} />
										</div>
									</div>
								</div>
								<Spin tip="Loading..." spinning={spinnerLoading}>
									<React.Fragment>
										<CCardBody>
											{fetchingData ?
												<div>
													<Spin indicator={antIcon} />
												</div> :
												<>
													<div className="table-responsive">
														<Table columns={columns} dataSource={data} pagination={null} bordered footer={null} />
														<div style={{marginTop: "2%"}}></div>
														<Pagination showSizeChanger current={page} total={total_restaurant} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
													</div>
												</>
											}
										</CCardBody>
									</React.Fragment>
								</Spin>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<Footer />
		</DashboardLayout >
	);
}

export default TodaysSales;
