/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    count: 0
};

export const updateCountSplice = createSlice({
    name: "count",
    initialState,
    reducers: {
        setCount: (state, action) => { state.count = action.payload },
    },
});

export const { setCount } = updateCountSplice.actions;

export default updateCountSplice.reducer;