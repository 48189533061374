/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CCard, CCardBod, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Input, InputNumber, Modal, Pagination, Select, Spin, Switch, Table, Upload, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import Jwt_decrypt from "common/Jwt_decrypt";
import axios from "common/axios_call";
import { _get_profile } from "common/axios_services";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

function Products() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_categories, setTotal_categories] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [allProducts, setAllProducts] = useState('products');
    const [imageUrl, setImageUrl] = useState(null);
    const [imageToSend, setImageToSend] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ProductsBox, setProductsBox] = useState([]);
    const [subcat, setSubcat] = useState([]);
    const [categoryErrorMessage, setCategoryErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const [updateCount, setUpdateCount] = useState(0);
    const [addProducts, setAddProducts] = useState(false);
    const [deleteProducts, setDeleteProducts] = useState(false);

    const jwt = localStorage.konnect_admin_token;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    const { TextArea } = Input;

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/')
    }

    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });
    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addProduct")) {
                setAddProducts(true)
            } else {
                setAddProducts(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteProduct")) {
                setDeleteProducts(true)
            } else {
                setDeleteProducts(false)
            }
        } else {
            setDeleteProducts(false)
            setAddProducts(false)
        }
    }
    useEffect(() => {
        axios(`/products?page=${page}&per_page=${page_size}&search=`, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                setFetchingData(false);
                let product = [];
                res.data.data.forEach(freshData => {
                    let storeData = {
                        product: freshData,
                        subCategories: 0
                    }
                    product.push(storeData);
                })
                setProductsBox(product);
                setTotal_categories(res.data.meta.total)
            })
            .catch(err => {
                setFetchingData(false);
                if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                        navigate('/')
                        openNotificationWithIcon('error', err.response.data.message);
                    }
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.message);
                }
            })
        axios(`/sub-category?page=${1}&per_page=${100}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                setFetchingData(false);
                let subcat = [];
                res.data.data.forEach(freshData => {
                    let storeData = {
                        subcat: freshData,
                        subCategories: 0
                    }
                    subcat.push(storeData);
                })
                setSubcat(subcat);
            })
            .catch(err => {
                setFetchingData(false);
                if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                        navigate('/')
                        openNotificationWithIcon('error', err.response.data.message);
                    }
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.message);
                }
            })
        fetchUser()
    }, [page, page_size, updateCount])

    // Functions to handle
    const addProduct = (values) => {
        axios.post('/products', values, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            setAllProducts("products")
            setUpdateCount(updateCount + 1)
        }).catch(err => {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        })
    };

    const changeStatus = ({ id, value }) => {
        axios.patch(`/products/display_status/${id}`, { status: value }, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            openNotificationWithIcon('success', res.data.message)
            setUpdateCount(updateCount + 1)
        }).catch(err => {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        })
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>

        </div>
    );
    const deleteCategory = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const finaliseProductDelete = () => {
        setSpinnerLoading(true)
        axios.delete(`/products/${selectedCategoryId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                setSpinnerLoading(false)
                handleCancel()
                openNotificationWithIcon('success', res.data.message);
                navigate('/products')
            })
            .catch(err => {
                openNotificationWithIcon('error', err.response.data.message);
                setSpinnerLoading(false);
            });
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }

    const handleChange = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            setImageToSend(reader.result);
            form.setFieldsValue({ product_image: reader.result });
        });
    }


    let data = ProductsBox.map((category, index) => {
        return {
            key: index,
            id: category.product.id,
            product_code: category.product.product_code,
            name: category.product.product_name,
            // discountRate: category.product.discount_rate,
            marketPrice: <NumericFormat thousandSeparator={true} displayType={'text'} value={category.product.market_price} />,
            konnectPrice: <NumericFormat thousandSeparator={true} displayType={'text'} value={category.product.konnect_price} />,
            sellersPrice: <NumericFormat thousandSeparator={true} displayType={'text'} value={category.product.sellers_price} />,
            // oldPrice: <NumericFormat thousandSeparator={true} displayType={'text'} value={category.product.old_price} />,
            weight: category.product.weight,
            displayStatus: <Form form={form} initialValues={{ display_status: category.product.display_status }}><Form.Item name="display_status" valuePropName="checked">{category.product.display_status ? <Button onClick={() => changeStatus({ value: false, id: category.product.id })}>Deactivate</Button> : <Button onClick={() => changeStatus({ value: true, id: category.product.id })}>Activate</Button>}</Form.Item></Form>,
            actions: <div><Link to={`/products/edit/${category.product.id}`}>Edit</Link>{deleteProducts && <> / <form className="inline-form"
                onSubmit={e => { deleteCategory(e); setSelectedCategoryId(category.product.id) }}>
                <input type="hidden" value={category.product.id} name="categoryId" />
                <Button type="link" htmlType="submit" className="inline-button">Delete</Button></form></>}</div>
        }
    });
    let sub_category = subcat.map((sub, index) => {
        return {
            key: index,
            id: sub.subcat.id,
            name: sub.subcat.subcat_name
        }
    })

    const columns = [
        {
            title: 'Code',
            dataIndex: 'product_code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Sellers-Price (NGN)',
            className: 'column-number',
            dataIndex: 'sellersPrice',
            align: 'center',
        },
        {
            title: 'Konnect-Price (NGN)',
            className: 'column-number',
            dataIndex: 'konnectPrice',
            align: 'center',
        },
        {
            title: 'Market-Price (NGN)',
            className: 'column-number',
            dataIndex: 'marketPrice',
            align: 'center',
        },
        {
            title: 'Weight',
            className: 'column-number',
            dataIndex: 'weight',
            align: 'center',
        },
        {
            title: 'Display-Status',
            className: 'column-number',
            dataIndex: 'displayStatus',
            align: 'center',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Products
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {allProducts === 'products' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div className="grid-flex">
                                                        Products List
                                                        <div>
                                                            {addProducts &&
                                                                <Button type="primary" onClick={() => setAllProducts('add-product')}>Add Product</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {
                                                        fetchingData ?
                                                            <div>
                                                                <Spin indicator={antIcon} />
                                                            </div> :
                                                            <div className="table-responsive">
                                                                <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_categories} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                                                            </div>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                        <Modal title="Delete Product" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                            <p>Are you sure you want to delete this product?</p>
                                            <div>
                                                <button onClick={() => setIsModalVisible(false)} style={{ border: '1px solid #F0F0F0', borderRadius: 4, padding: '10px 25px', background: 'transparent', marginRight: 10, fontSize: 12, fontWeight: '600' }}
                                                >Cancel</button>
                                                <button onClick={() => finaliseProductDelete()} style={{ border: '1px solid #FF4A00', borderRadius: 4, padding: '10px 25px', background: '#FF4A00', fontSize: 12, fontWeight: '600', color: '#fff' }}
                                                >Delete</button>
                                            </div>
                                        </Modal>
                                    </Spin>
                                }
                                {allProducts === 'add-product' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Add Product
                                                    <div>
                                                        <Button type="primary" onClick={() => setAllProducts('products')}>View Products</Button>
                                                    </div>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {
                                                    categoryErrorMessage ? <p className="error-message">{categoryErrorMessage}</p> : ''
                                                }
                                                <Form form={form} onFinish={addProduct} initialValues={{ available_status: false }} layout="vertical">
                                                    <Form.Item name="product_image" label="Product Image" >
                                                        <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={handleChange} >
                                                            {imageUrl ? <img src={imageUrl} style={{ width: "120px", height: "120px" }} alt="avatar" className="adminCourseUpload" /> : uploadButton}
                                                        </Upload>
                                                    </Form.Item>
                                                    <Form.Item name="subcat_id" label="Choose Sub-Category" >
                                                        <Select>
                                                            {sub_category.map((title, index) => (
                                                                <Select.Option key={index} value={title.id}>{title.name}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="product_name" label="Product Name" >
                                                        <Input placeholder="Product Name" />
                                                    </Form.Item>
                                                    <Form.Item name="product_code" label="Product Code" >
                                                        <Input placeholder="Product Code" />
                                                    </Form.Item>
                                                    <Form.Item name="market_price" label="Market Price" >
                                                        <Input placeholder="Market Price" />
                                                    </Form.Item>
                                                    <Form.Item name="konnect_price" label="Konnect Price" >
                                                        <Input placeholder="Konnect Price" />
                                                    </Form.Item>
                                                    <Form.Item name="sellers_price" label="Sellers Price" >
                                                        <Input placeholder="Sellers Price" />
                                                    </Form.Item>
                                                    <Form.Item name="old_price" label="old Price" >
                                                        <Input placeholder="Old Price" />
                                                    </Form.Item>
                                                    <Form.Item name="weight" label="Weight" >
                                                        <Input placeholder="Weight" />
                                                    </Form.Item>
                                                    <Form.Item name="discount_rate" label="Discount Rate ( % )" >
                                                        <Input placeholder="Discount Rate" />
                                                    </Form.Item>
                                                    <Form.Item name="available_status" label="Available Status" >
                                                        <Select>
                                                            <Select.Option value={true}>Available</Select.Option>
                                                            <Select.Option value={false}>Not Available</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="description" label="Category Description" >
                                                        <TextArea rows={4} placeholder="Category Description" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button htmlType="submit" type="primary">Submit</Button>
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default Products;
