/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { Modal } from "antd";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { _confirm_email } from "common/axios_services";
import { useDispatch, useSelector } from "react-redux";
import { _def_confirm_message } from "splices/message";

function ConfirmEmail() {
    // Package Definitions
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();

    // Variable Definitions
    const message = useSelector(state => state.messages.confirm_message)

    // Function
    const handleOk = () => {
        navigate('/authentication/signin')
    }

    const success = () => {
        if (message) {
            Modal.success({
                title: "verify email",
                content: <p>{message}</p>,
                centered: true,
                okText: "Proceed To Signin",
                cancelText: "Retry",
                onOk() {
                    handleOk()
                },
            });
        }
    };
    const error = () => {
        if (message) {
            Modal.error({
                title: "verify email",
                content: <p>{message}</p>,
                centered: true,
                okText: "Proceed To Signin",
                cancelText: "Retry",
                onOk() {
                    handleOk()
                },
                onCancel() {
                    window.location.reload()
                },
            });
        }
    };

    const confirmEmail = async () => {
        try {
            const confirm_email = await _confirm_email({ id: params.id })
            dispatch(_def_confirm_message(confirm_email.data.message))
            success()
        } catch (err) {
            if (err.response) {
                dispatch(_def_confirm_message(err.response.data.message))
                error()
            } else {
                dispatch(_def_confirm_message(err.message))
                error()
            }
        }
    }

    useEffect(() => {
        confirmEmail()
    }, [message])

    return (
        <BasicLayout image={bgImage}>
            {/* <Card>
                <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadowx="info" mx={2} mt={-3} p={2} mb={1} textAlign="center">
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>Confirm Email</MDTypography>
                </MDBox>
                <MDBox pt={1} pb={3} px={3}>
                    <MDTypography style={{ color: "red", fontSize: "16px" }} mb={2}>{error_msg}</MDTypography>
                    <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                        <MDBox mb={2}>
                            <MDInput type="text" label="OTP" name="otp" onChange={formik.handleChange} value={formik.values.otp} onBlur={formik.handleBlur} fullWidth />
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            {confirm ?
                                <MDButton loading variants="gradient" color="info" fullWidth>Confirming Email...</MDButton> :
                                <MDButton type='submit' name='submit' variants="gradient" color="info" fullWidth>Confirm</MDButton>
                            }
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card> */}
        </BasicLayout>
    );
}

export default ConfirmEmail;
