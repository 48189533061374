/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CategoryNav from 'examples/Navbars/CategoryNav';


import { Done, Edit, Visibility } from "@mui/icons-material";
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Pagination, Radio, Row, Select, Spin, Table, Tabs, Tag, notification } from "antd";
import { _change_inbound_instore_status, _check_allocation, _confirm_allocation, _get_all_inbound, _get_allocation_driver_matrix, _get_allocation_matrix, _get_allocation_orders, _get_available_drivers, _get_dateby_id, _get_dates, _get_delivery_drivers, _get_inbound_details, _get_inboundby_dateid, _get_lga, _get_orderby_dateid, _get_orders, _get_outbound_details, _get_profile, _update_inbound_data } from "common/axios_services";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import ReactMoment from 'react-moment';
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";

function Allocations() {
    const navigate = useNavigate()
    const params = useParams()
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_items, setTotal_items] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [fetchingOrder, setFetchingOrder] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [inboundInfo, setInboundInfo] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [editInbound, setEditInbound] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [fetchingDrivers, setFetchingDrivers] = useState(false);
    const [lgas, setLgas] = useState([]);
    const [fetchingLgas, setFetchingLgas] = useState(false);
    const [driverID, setDriverID] = useState(false);
    const [orderId, setOrderId] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorData, setErrorData] = useState(false);
    const [fetchingOutboundInfo, setFetchingOutboundInfo] = useState(false);
    const [outboundInfo, setOutboundInfo] = useState(false);
    const [matrix, setAllMatrix] = useState(false);
    const [driverModalInfo, setDriverModalInfo] = useState(false);
    const [ViewDriverModal, setViewDriverModal] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };
    const getDrivers = async () => {
        setFetchingDrivers(true)
        try {
            const driver = await _get_delivery_drivers({ search: "", delivery_date_id: "", from: "", to: "" })
            setFetchingDrivers(false)
            if (driver?.data.data === null) {
                setDrivers([])
            } else {
                setDrivers(driver?.data.data)
            }
        } catch (err) {
            setFetchingDrivers(false)
            setDrivers([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getOrder = async ({ day, lga }) => {
        setFetchingOrder(true)
        try {
            const order = await _get_allocation_orders({ day, lga, page, page_size })
            setFetchingOrder(false)
            setOrderData(order?.data.data)
            setTotal_items(order?.data.meta.total);
        } catch (err) {
            setTotal_items(0);
            setFetchingOrder(false)
            setOrderData([])
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchAllLga = async () => {
        setFetchingLgas(true)
        try {
            const av_lga = await _get_lga({ page: 1, page_size: 10 })
            setFetchingLgas(false)
            const defaultOption = { name: 'lga', value: 'lga', key: 'lga', id: "lga", label: 'All LGA' };
            const optionsWithDefault = [defaultOption, ...av_lga.data.data];
            setLgas(optionsWithDefault)
        } catch (err) {
            setFetchingLgas(false)
            setLgas(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchAllocationMatrix = async ({ lga }) => {
        try {
            const matrix = await _get_allocation_matrix({ date: moment().format('YYYY-MM-DD'), lga })
            setAllMatrix(matrix.data.data)
        } catch (err) {
            setAllMatrix(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    useEffect(() => {
        getDrivers()
        fetchAllLga()
        if (params.lga && params.lga !== "lga") {
            getOrder({ day: moment().format('YYYY-MM-DD'), lga: params.lga })
            fetchAllocationMatrix({ lga: params.lga, })
        } else {
            getOrder({ day: moment().format('YYYY-MM-DD'), lga: "" })
            fetchAllocationMatrix({ lga: "", })
        }
    }, [page, page_size, updateCount])

    const selectLga = ({ id }) => {
        navigate(`/allocations/${id}`)
        setUpdateCount(updateCount + 1)
    }
    const editInboundInfo = async (values) => {
        try {
            const data = {
                status: "inprogress",
                qty_supplied: `${values.qty_supplied}`,
                comment: `${values.comment}`
            }
            const editInboundData = await _update_inbound_data({ id: inboundInfo.id, data })
            openNotificationWithIcon('success', editInboundData.data.message)
            setEditInbound(false)
            setUpdateCount(updateCount + 1)
            form.resetFields()
        } catch (err) {
            setEditInbound(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const allocateDriver = async ({ id, status }) => {
        if (driverID) {
            try {
                const allocate = await _check_allocation({ driver_id: driverID, id, checked: status })
                setOrderId(id)
                if (allocate?.data?.data?.balance_status === "not_balance") {
                    setErrorModal(true)
                    setErrorData(allocate?.data)
                } else {
                    openNotificationWithIcon('success', allocate?.data?.message)
                }
                // else {
                //     confirmAllocation({ id })
                // }
                setUpdateCount(updateCount + 1)
            } catch (err) {
                if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                        localStorage.removeItem('konnect_token')
                        navigate(`/authentication/signin`)
                    }
                    setUpdateCount(updateCount + 1)
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.message)
                }
            }
        } else {
            openNotificationWithIcon('error', "Pls select a driver")
        }
    }
    const confirmAllocation = async ({ id }) => {
        try {
            if (id) {
                const confirm = await _confirm_allocation({ driver_id: driverID, id })
                openNotificationWithIcon('success', confirm.data.message)
            } else {
                const confirm = await _confirm_allocation({ driver_id: driverID, id: orderId })
                openNotificationWithIcon('success', confirm.data.message)
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getOutboundDetails = async ({ id }) => {
        setFetchingOutboundInfo(true)
        try {
            const outboundInfo = await _get_outbound_details({ id })
            setDetailsModal(true)
            setOutboundInfo(outboundInfo.data.data)
            setFetchingOutboundInfo(false)
        } catch (err) {
            setDetailsModal(false)
            setOutboundInfo(false)
            setFetchingOutboundInfo(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/authentication/signin`)
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const viewDriver = (driver) => {
        setViewDriverModal(true)
        setDriverModalInfo(driver)
    }

    const items = [];
    const avail_lgas = []
    const seen = new Set();

    lgas.forEach((lga) => {
        if (!seen.has(lga.name)) {
            seen.add(lga.name);
            avail_lgas.push({
                key: lga.name,
                value: lga.name,
                label: <div>{lga.label ? lga.label : lga.name}</div>,
            });
        }
    });
    const parcelsData = orderData.map((order, index) => {
        return {
            key: index,
            check: <div>{driverID === order?.driver_delivery_id && order?.delivery_status === "allocated" ? <Checkbox checked={true} onClick={() => allocateDriver({ id: order?.id, status: false })}></Checkbox> : <Checkbox onClick={() => allocateDriver({ id: order?.id, status: true })}></Checkbox>}</div>,
            items: order?.items.length,
            parcel_no: order?.order_no,
            buyer_name: <p style={{ textTransform: "capitalize" }}>{order?.username}</p>,
            location: order?.lga,
            quantity: <NumericFormat thousandSeparator={true} displayType={'text'} value={order?.total_qty} />,
            delivery_quantity: <NumericFormat thousandSeparator={true} displayType={'text'} value={order?.delivery_qty} />,
            kg: <NumericFormat thousandSeparator={true} displayType={'text'} value={order?.total_weight} />,
            status: <div style={{ textTransform: "capitalize" }}>{order?.delivery_status === "delivered" ? <Tag color="#87d068">{order?.delivery_status}</Tag> : order?.delivery_status === "allocated" ? <Tag color="#0000FF">{order?.delivery_status}</Tag> : <Tag color="#ffa500">{order?.delivery_status}</Tag>}</div>,
            action: <>
                <span style={{ marginRight: "5px", marginBottom: "5px" }}>
                    <Visibility style={{ cursor: "pointer" }} onClick={() => { getOutboundDetails({ id: order?.id }) }} />
                </span>
            </>,
        }
    });
    const driversData = drivers.map((driver, index) => {
        return {
            key: index,
            sn: index + 1,
            check: <div>{driverID === driver?.id ? <Radio checked={true}></Radio> : <Radio onClick={() => { setDriverID(driver?.id); setDriverModalInfo(driver) }}></Radio>}</div>,
            driverName: driver?.driver_name,
            van_type: driver?.van_type,
            capacity: <NumericFormat thousandSeparator={true} displayType={'text'} value={driver?.van_capacity} />,
            action: <Button onClick={() => viewDriver(driver)} type="link">View</Button>,
        }
    });
    const driversColumns = [
        {
            title: '',
            dataIndex: 'check',
        },
        {
            title: 'Driver Name',
            className: 'column-number',
            dataIndex: 'driverName',
            align: 'center',
        },
        {
            title: 'Van Type',
            className: 'column-number',
            dataIndex: 'van_type',
            align: 'center',
        },
        {
            title: 'Capacity (Kg)',
            className: 'column-number',
            dataIndex: 'capacity',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ];
    const parcelsColumns = [
        {
            title: '',
            dataIndex: 'check',
        },
        {
            title: 'Buyer',
            className: 'column-number',
            dataIndex: 'buyer_name',
            align: 'center',
        },
        {
            title: 'Item',
            className: 'column-number',
            dataIndex: 'items',
            align: 'left',
        },
        {
            title: 'Weight(kg)',
            className: 'column-number',
            dataIndex: 'kg',
            align: 'center',
        },
        {
            title: 'Quantity',
            className: 'column-number',
            dataIndex: 'quantity',
            align: 'center',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ];
    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setUserBox([]);
    }
    return (
        <DashboardLayout>
            <CategoryNav title="" breadroute="allocations" />
            {fetchingLgas ?
                <></> :
                <div style={{ display: "flex", justifyContent: "right", gap: "20px", padding: "10px", borderRadius: "", flexWrap: "wrap", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <span>LGA's:</span>
                        {params.lga && params.lga !== "lga" ?
                            <Select placeholder="Select LGA" defaultValue={params.lga} style={{ width: 220, }} options={avail_lgas} onChange={(values, selectedOption) => { selectLga({ id: selectedOption.key }); }} />
                            :
                            <Select placeholder="Select LGA" style={{ width: 220, }} options={avail_lgas} onChange={(values, selectedOption) => { selectLga({ id: selectedOption.key }); }} />
                        }
                    </div>
                    <Button>Export</Button>
                </div>
            }
            {matrix ?
                <MDBox py={3}>
                    {/* 4 cat */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="weekend"
                                    title="Total Driver"
                                    count={<NumericFormat thousandSeparator={true} displayType={'text'} value={+matrix?.total_drivers} />}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="store"
                                    title="Total Buyer"
                                    count={<NumericFormat thousandSeparator={true} displayType={'text'} value={+matrix?.total_buyer} />}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="leaderboard"
                                    title="Total Order"
                                    count={<NumericFormat thousandSeparator={true} displayType={'text'} value={+matrix?.total_orders} />}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="primary"
                                    icon="person_add"
                                    title="Selected Order"
                                    count={<NumericFormat thousandSeparator={true} displayType={'text'} value={+matrix?.total_selected_orders} />}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox> :
                <MDBox py={3}>
                    {/* 4 cat */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="weekend"
                                    title="Total Driver"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="leaderboard"
                                    title="Total Buyer"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="store"
                                    title="Total Order"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="primary"
                                    icon="person_add"
                                    title="Selected Order"
                                    count={0}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <MDBox MDBox pt={6} pb={3} >
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Allocations
                                </MDTypography>
                            </MDBox>
                            {(driverID && driverModalInfo) &&
                                <h4 style={{ paddingLeft: "20px", marginTop: "20px" }}>{driverModalInfo?.driver_name}'s Metrix</h4>
                            }
                            <MDBox pt={4} px={2}>
                                {/* 4 cat */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="dark"
                                                icon="weekend"
                                                title="Total buyers"
                                                count={driverModalInfo?.total_buyer ? <NumericFormat thousandSeparator={true} displayType={'text'} value={+driverModalInfo?.total_buyer} /> : 0}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="success"
                                                icon="store"
                                                title="Total order"
                                                count={driverModalInfo?.total_order ? <NumericFormat thousandSeparator={true} displayType={'text'} value={+driverModalInfo?.total_order} /> : 0}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                icon="leaderboard"
                                                title="Total Weight (kg)"
                                                count={driverModalInfo?.total_weight ? <NumericFormat thousandSeparator={true} displayType={'text'} value={+driverModalInfo?.total_weight} /> : 0}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="primary"
                                                icon="person_add"
                                                title="Total item"
                                                count={driverModalInfo?.total_items ? <NumericFormat thousandSeparator={true} displayType={'text'} value={+driverModalInfo?.total_items} /> : 0}
                                            />
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} md={6}>
                                        <div className="table-responsive">
                                            {!fetchingDrivers ?
                                                <>
                                                    <Table columns={driversColumns} pagination={false} dataSource={driversData} bordered />
                                                    <div style={{ marginTop: "2%" }}></div>
                                                </>
                                                :
                                                <div className="">
                                                    <Spin />
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={24} md={18}>
                                        <div className="table-responsive">
                                            {!fetchingOrder ?
                                                <>
                                                    <Table columns={parcelsColumns} pagination={false} dataSource={parcelsData} bordered />
                                                    <div style={{ marginTop: "2%" }}></div>
                                                    <Pagination showSizeChanger current={page} total={total_items} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} item(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                    <div style={{ marginTop: "2%" }}></div>
                                                </>
                                                :
                                                <div className="">
                                                    <Spin />
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ marginTop: "5%" }}></div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox >
            <Modal centered={true} title="" visible={errorModal} footer={null} onCancel={() => { setErrorModal(false) }} className="pb-5 pt-5">
                {errorData &&
                    <>
                        <h5 style={{ paddingTop: "20px", paddingBottom: "10px" }}>{errorData.message}</h5>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Total Items</p>
                            <NumericFormat thousandSeparator={true} displayType={'text'} value={errorData.data.total_items} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Total Items Found</p>
                            <NumericFormat thousandSeparator={true} displayType={'text'} value={errorData.data.total_items_found} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Total Items Not Found</p>
                            <NumericFormat thousandSeparator={true} displayType={'text'} value={errorData.data.total_items_not_found} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <p style={{ fontWeight: "semibold" }}>Total Qty Fund</p>
                            <NumericFormat thousandSeparator={true} displayType={'text'} value={errorData.data.total_qty_fund} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            <Button style={{ background: "green", color: "white" }} onClick={() => confirmAllocation({ id: false })} >Proceed</Button>
                            <Button onClick={() => { setErrorData(false); setErrorModal(false) }}>Cancel</Button>
                        </div>
                    </>
                }
            </Modal>
            <Modal centered={true} title="" visible={detailsModal} footer={null} onCancel={() => { setDetailsModal(false); setOutboundInfo(false) }} className="pb-5 pt-5">
                {outboundInfo ?
                    <>
                        <div style={{ display: "flex", flexDirection: "column", }}>
                            <h5 style={{ marginTop: "30px", marginBottom: "5px", textTransform: "capitalize" }}>{outboundInfo.username}</h5>
                            <p>{outboundInfo.order_no}</p>
                            {outboundInfo.items.map((item, index) => (
                                <div key={index} style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px" }}>
                                    <img src={item.product_image} style={{ width: "100px", heigh: "100px" }} />
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Product name</span>
                                        <span>{item.product_name}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Product weight (kg)</span>
                                        <span>{item.total_weight}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Quantity Ordered</span>
                                        <span>{item.quantity}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Quantity Delivered</span>
                                        <span>{item.delivery_qty}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Delivery Status</span>
                                        <span><div style={{ textTransform: "capiatalize" }}>{item.delivery_status === "delivered" ? <Tag color="#87d068">{item.delivery_status}</Tag> : item.delivery_status === "allocated" ? <Tag color="#0000FF">{item.delivery_status}</Tag> : <Tag color="#ffa500">{item.delivery_status}</Tag>}</div>,</span>
                                    </div>
                                    <hr style={{ margin: "0.5rem" }} />
                                </div>
                            ))}
                            <span>
                                <Button onClick={() => { setDetailsModal(false); setInboundInfo(false) }}>Close</Button>
                            </span>
                        </div>
                    </>
                    :
                    <Spin></Spin>
                }
            </Modal>
            <Modal centered={true} title="" visible={editInbound} footer={null} onCancel={() => { setEditInbound(false) }} className="pb-5 pt-5">
                {inboundInfo ?
                    <>
                        <h5>Edit</h5>
                        <Form autoComplete="off" form={form} layout="vertical"
                            initialValues={{
                                id: `${inboundInfo.id}`,
                                product_name: `${inboundInfo.product_name}`,
                                quantity_supplied: "",
                                comment: ""
                            }}
                            onFinish={editInboundInfo}>
                            <Form.Item label="Product Name" name="product_name">
                                <Input disabled placeholder="Product Name" />
                            </Form.Item>
                            <Form.Item label="Quantity Supplied" name="qty_supplied">
                                <InputNumber placeholder="Quantity Supplied" style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item label="Comment" name="comment">
                                <Input placeholder="Comment" />
                            </Form.Item>
                            <Button htmlType='submit'>Update</Button>
                        </Form>
                    </> :
                    <div>
                        <Spin />
                    </div>
                }
            </Modal>
            <Modal centered={true} open={ViewDriverModal} onCancel={() => setViewDriverModal(false)} footer={null}>
                {driverModalInfo ?
                    <div>
                        <div style={{ marginTop: "5%" }}></div>
                        <h3>{driverModalInfo?.driver_name}</h3>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p>Driver Email:</p>
                            <p>{driverModalInfo?.driver_email}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p>Driver Phone:</p>
                            <p>{driverModalInfo?.driver_contact}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p>Plate Number:</p>
                            <p>{driverModalInfo?.plate_number}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p>Van Type:</p>
                            <p>{driverModalInfo?.van_type}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p>Vehicle Capacity (KG):</p>
                            <p><NumericFormat thousandSeparator={true} displayType={'text'} value={driverModalInfo?.van_capacity} /></p>
                        </div>
                    </div>
                    : <Spin />
                }
            </Modal>
            <Footer />
        </DashboardLayout >
    );
}

export default Allocations; 